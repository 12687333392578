import { useQuery } from '@tanstack/react-query'
import { enUS } from '@common/constants/messages'
import { Project } from '../../models/table'
import { useLogout } from '@modules/auth'
import { getEmail, getProjectID } from '@common/utils/storage'
import { useClosableSnackbar } from '@common/hooks/useClosableSnackbar'
import { authApi } from '../base'
import { urls } from '../urls'
import { REASON_ENUM } from '../logout'

export function useProject() {
  const email = getEmail()
  const logout = useLogout(REASON_ENUM.PROJECT_NOT_AUTHORIZED)
  const wrappedEnqueue = useClosableSnackbar()

  const logOut = () => {
    logout()
    wrappedEnqueue('info', enUS.USER_PROJECT_NOT_AVAILABLE)
  }

  return useQuery({
    staleTime: Infinity,
    queryKey: ['project', email],
    queryFn: async (): Promise<Project[]> => {
      const resp = await authApi.post(urls.projects, { email })

      return resp.data.projects.length ? resp.data.projects : logOut()
    },
  })
}

const defaultProjectData = {
  frameRate: 0,
  sectionLength: 0,
  projectID: 0,
  projectName: '',
  projectDescription: '',
}

export function useProjectData(): Project {
  const { data: projectData } = useProject()
  const projectId = getProjectID()

  if (!projectId || !projectData) {
    return defaultProjectData
  }

  return (
    projectData.find((d) => d.projectID === Number(projectId)) ||
    defaultProjectData
  )
}
