import { Button, Stack, styled } from '@mui/material'

export const StyledBtnContainer = styled(Stack)(() => ({
  position: 'fixed',
  bottom: 0,
  paddingBottom: 8,
  backgroundColor: '#7A86A1',
  width: '240px',
  borderRadius: '4px',
}))

export const StyledApplyBtn = styled(Button)(() => ({
  backgroundColor: 'darkslategrey',
  '&:hover': { backgroundColor: '#253e3e' },
}))

export const StyledResetBtn = styled(Button)(() => ({
  backgroundColor: '#5f79c0',
  borderColor: '#3f4c6b',
  '&:hover': { borderColor: '#3f4c6b', backgroundColor: '#4a67b8' },
}))
