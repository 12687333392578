import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { urls } from '../urls'

export interface Dashboard {
  id: number
  name: string
  url: string
}

export const useDashboardsQuery = () => {
  const { projectID } = useReportContext()

  return useQuery({
    queryKey: [urls.dashboards, projectID],
    staleTime: Infinity,
    enabled: !!projectID,
    queryFn: (): Promise<Dashboard[]> =>
      axios
        .get<Dashboard[]>(urls.dashboards, {
          params: { projectID },
        })
        .then(({ data }) => data),
  })
}
