import { useDashboardsQuery } from '@api/dashboards/dashboards'
import { useProjectData } from '@api/table/projects'
import { PROJECT_IDS } from '@common/constants/projects'
import { setDashboards } from '@common/utils/storage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem } from '@mui/material'
import { shouldSkipElement } from 'pages/Report/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import NestedReportNavigationMenu from './NestedReportNavigationMenu'
import './styles.scss'

const DRIVE_TRIALS_MENU_ITEMS = ['completeness', 'quality', 'characterization']
const KPIS_MENU_ITEMS = ['lanes', 'signs', 'lights']

export type MenuItemType = {
  name: string
  menuItems: ReadonlyArray<string>
  routeBase: string
  anchorEl: HTMLElement | undefined
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  close: () => void
  projects?: ReadonlyArray<PROJECT_IDS>
}

function ReportNavigation() {
  const navigate = useNavigate()
  const { data } = useDashboardsQuery()
  const { setPage } = useReportContext()
  const { pathname } = useLocation()
  const [driveAnchorEl, setDriveAnchorEl] = useState<HTMLElement>()
  const [kpiAnchorEl, setKpiAnchorEl] = useState<HTMLElement>()
  const [dashboardAnchorEl, setDashboardAnchorEl] = useState<HTMLElement>()
  const [sprAnchorEl, setSprAnchorEl] = useState<HTMLElement>()
  const [nlAnchorEl, setNlAnchorEl] = useState<HTMLElement>()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { projectID } = useProjectData()

  const dashboardMenuItems = useMemo(() => {
    return data?.map((x) => x.name) || []
  }, [data])

  const menus: ReadonlyArray<MenuItemType> = [
    {
      name: 'Drive Trials',
      menuItems: DRIVE_TRIALS_MENU_ITEMS,
      routeBase: '/reporting/drive-trials/',
      anchorEl: driveAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setDriveAnchorEl(event.currentTarget),
      close: () => setDriveAnchorEl(undefined),
    },
    {
      name: 'KPIs',
      menuItems: KPIS_MENU_ITEMS,
      routeBase: '/reporting/kpis/',
      anchorEl: kpiAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setKpiAnchorEl(event.currentTarget),
      close: () => setKpiAnchorEl(undefined),
    },
    {
      name: 'Dashboards',
      menuItems: dashboardMenuItems,
      routeBase: '/reporting/dashboards/',
      anchorEl: dashboardAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setDashboardAnchorEl(event.currentTarget),
      close: () => setDashboardAnchorEl(undefined),
    },
    {
      name: 'SPR Gallery',
      menuItems: ['SPR Gallery'],
      projects: [PROJECT_IDS.VOLVO],
      routeBase: '/reporting/spr-gallery',
      anchorEl: sprAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setSprAnchorEl(event.currentTarget),
      close: () => setSprAnchorEl(undefined),
    },
    {
      name: 'NL Query',
      menuItems: ['NL Query'],
      projects: [PROJECT_IDS.VOLVO],
      routeBase: '/reporting/nl-gallery',
      anchorEl: nlAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setNlAnchorEl(event.currentTarget),
      close: () => setNlAnchorEl(undefined),
    },
  ]

  const handleMenuItemClick = useCallback(
    (menu: (typeof menus)[0]) => {
      menu.close()
      setPage(1)
      if (menu.name === 'Dashboards') {
        navigate(`${menu.routeBase}${dashboardMenuItems[0]}`)
      }

      if (menu.name === 'SPR Gallery') {
        navigate(`${menu.routeBase}`)
      }

      if (menu.name === 'NL Query') {
        navigate(`${menu.routeBase}`)
      }
    },
    [dashboardMenuItems, navigate, setPage]
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (windowWidth < 1200) {
    return (
      <NestedReportNavigationMenu menus={menus} setPage={setPage} data={data} />
    )
  }

  return (
    <div style={{ display: 'flex', gap: '1.2em', height: '100%' }}>
      {menus.map((menu) => {
        return menu.projects && !menu.projects.includes(projectID) ? null : (
          <div key={menu.name} style={{ position: 'relative' }}>
            {menu.menuItems.length <= 1 ? (
              <Button
                onClick={() => handleMenuItemClick(menu)}
                data-testid={
                  menu.name +
                  (pathname.includes(menu.routeBase) ? '-active' : '')
                }
                sx={{
                  minWidth: 0,
                  textTransform: 'capitalize',
                  p: 0,
                  color: menu.anchorEl
                    ? '#fff'
                    : pathname.includes(menu.routeBase)
                      ? '#e5e5e5'
                      : '#bbbbbb',
                  '&:hover': {
                    color: '#fff',
                  },
                }}
              >
                {menu.name}
              </Button>
            ) : (
              <>
                <Button
                  data-testid={
                    menu.name +
                    (pathname.includes(menu.routeBase) ? '-active' : '')
                  }
                  sx={{
                    minWidth: 0,
                    textTransform: 'capitalize',
                    p: 0,
                    color: menu.anchorEl
                      ? '#fff'
                      : pathname.includes(menu.routeBase)
                        ? '#e5e5e5'
                        : '#bbbbbb',
                    '&:hover': {
                      color: '#fff',
                    },
                  }}
                  onClick={menu.handleClick}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  {menu.name}
                  <ExpandMoreIcon fontSize='small' />
                </Button>
                <Menu
                  anchorEl={menu.anchorEl}
                  open={Boolean(menu.anchorEl)}
                  onClose={menu.close}
                >
                  {menu.menuItems?.map((item) => {
                    if (shouldSkipElement(projectID, item)) {
                      return null
                    }

                    return (
                      <MenuItem
                        data-testid={`${menu.name}-${item}`}
                        sx={{ textTransform: 'capitalize' }}
                        key={item}
                        onClick={() => {
                          menu.close()
                          setPage(1)
                          navigate(`${menu.routeBase}${item}`)

                          if (menu.name === 'Dashboards') {
                            const chosenDashboard = data?.find(
                              (x) => x.name === item
                            )
                            setDashboards(chosenDashboard!)
                          }
                        }}
                      >
                        {item}
                      </MenuItem>
                    )
                  })}
                </Menu>
              </>
            )}
            {pathname.includes(menu.routeBase) && (
              <div className='highlight-active-route' />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ReportNavigation
