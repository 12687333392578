import { TimedData } from '@modules/videoViewport'
import { ObjectInfo } from 'components/ObjectsTable/types'
import { ChartData } from 'components/VideoTimeline/TimelineChartElements'
import {
  SortHighlights,
  TimelineDataGroup,
  TimelineItemData,
} from 'components/VideoTimeline/types'
import { IdType, Timeline } from 'otto-vis-timeline'
import {
  ReactElement,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react'
import { DataSet } from 'vis-data'

export interface ITimelineContext {
  startMarker?: number | null
  inputInFocus?: boolean
  shouldMoveMarker: boolean
  setStartMarker?: React.Dispatch<React.SetStateAction<number | null>>
  endMarker?: number | null
  setEndMarker?: React.Dispatch<React.SetStateAction<number | null>>
  groups: DataSet<TimelineDataGroup>
  items: DataSet<TimelineItemData>
  initialItems: DataSet<TimelineItemData>
  originalItems: DataSet<TimelineItemData>
  tags: Record<IdType, TimedData<boolean>>
  hidden: number[]
  setHidden: React.Dispatch<React.SetStateAction<number[]>>
  timeline?: Timeline
  selectedGroup?: number

  openSettingsDialog: boolean
  setOpenSettingsDialog: React.Dispatch<React.SetStateAction<boolean>>

  setTimeline: (timelineObj: Timeline) => void
  setInputInFocus: React.Dispatch<React.SetStateAction<boolean>>
  setShouldMoveMarker: React.Dispatch<React.SetStateAction<boolean>>
  setTags: React.Dispatch<
    React.SetStateAction<Record<IdType, TimedData<boolean>>>
  >
  setSelectedGroup: (id: number | undefined) => void
  timelineSettings: TimelineSettings
  setTimelineSettings: React.Dispatch<React.SetStateAction<TimelineSettings>>

  objectsData: TimedData<{
    data: ObjectInfo[]
  }>
  setObjectsData: React.Dispatch<
    React.SetStateAction<
      TimedData<{
        data: ObjectInfo[]
      }>
    >
  >

  trackParameters: TrackParameters | undefined
  setTrackParameters: React.Dispatch<
    React.SetStateAction<TrackParameters | undefined>
  >
}

interface IProps {
  children: ReactElement | ReactElement[]
}

export type TrackHeight = 'small' | 'large'
export enum TrackDisplayMode {
  Value = 'value',
  Bars = 'bars',
  Lines = 'lines',
}
export const trackHeightMap = {
  small: 24,
  large: 64,
}

export interface TrackSettings {
  height?: TrackHeight
  displayMode?: TrackDisplayMode
  chartData?: (ChartData | null)[]
  items?: TimelineItemData[]
}

export interface TrackParameters {
  [groupId: number]: TrackSettings
}

export interface TimelineSettings {
  highlightLength: number
  sortBy: SortHighlights
}

export interface HighlightMode {
  id: number
  items: DataSet<TimelineItemData, 'id'>
  sortBy?: SortHighlights
  minLength?: number
}

const TimelineContext = createContext<ITimelineContext>({} as ITimelineContext)

export const useTimelineContext = () => useContext(TimelineContext)

export const TimelineContextProviderControlled = ({ children }: IProps) => {
  const groups = useRef<DataSet<TimelineDataGroup>>(
    new DataSet<TimelineDataGroup>()
  )
  const items = useRef<DataSet<TimelineItemData>>(
    new DataSet<TimelineItemData>()
  )

  const initialItems = useRef<DataSet<TimelineItemData>>(
    new DataSet<TimelineItemData>()
  )

  const originalItems = useRef<DataSet<TimelineItemData>>(
    new DataSet<TimelineItemData>()
  )

  const [timeline, setTimeline] = useState<Timeline | undefined>(undefined)
  const [inputInFocus, setInputInFocus] = useState<boolean>(false)
  const [shouldMoveMarker, setShouldMoveMarker] = useState<boolean>(false)
  const [tags, setTags] = useState<Record<IdType, TimedData<boolean>>>({})
  const [startMarker, setStartMarker] = useState<number | null>(null)
  const [endMarker, setEndMarker] = useState<number | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(
    undefined
  )
  const [hidden, setHidden] = useState<number[]>([])

  const [openSettingsDialog, setOpenSettingsDialog] = useState(false)
  const [timelineSettings, setTimelineSettings] = useState<TimelineSettings>({
    highlightLength: 5,
    sortBy: 'gain',
  })
  const [objectsData, setObjectsData] = useState<
    TimedData<{ data: ObjectInfo[] }>
  >({})
  const [trackParameters, setTrackParameters] = useState<TrackParameters>()

  return (
    <TimelineContext.Provider
      value={{
        openSettingsDialog,
        setOpenSettingsDialog,
        timeline,
        items: items.current,
        initialItems: initialItems.current,
        originalItems: originalItems.current,
        tags,
        hidden,
        setHidden,
        startMarker,
        endMarker,
        setStartMarker,
        setEndMarker,
        inputInFocus,
        setInputInFocus,
        shouldMoveMarker,
        setShouldMoveMarker,
        groups: groups.current,
        selectedGroup,
        setTimeline,
        setTags,
        setSelectedGroup: (id: number | undefined) => {
          setSelectedGroup(id)
        },
        timelineSettings,
        setTimelineSettings,
        objectsData,
        setObjectsData,
        setTrackParameters,
        trackParameters,
      }}
    >
      {children}
    </TimelineContext.Provider>
  )
}
