export const layerColorsData = [
  {
    name: 'ActiveEgoLineColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'F0E442',
  },
  {
    name: 'ActiveEgoLineClass',
    set: 1,
    source: 'Ottometric',
    layerColor: 'F0E442',
  },
  {
    name: 'ActiveAdjacentLaneColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'B48445',
  },
  {
    name: 'ActiveAdjacentLaneClass',
    set: 1,
    source: 'Ottometric',
    layerColor: 'B48445',
  },
  {
    name: 'ActivePedColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'F78B02',
  },
  {
    name: 'ActiveCarColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'D73027',
  },
  {
    name: 'ActiveBusColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'FFFFBF',
  },
  {
    name: 'ActiveCycleColor',
    set: 1,
    source: 'Ottometric',
    layerColor: '009E37',
  },
  {
    name: 'ActiveTruckColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'FDAE61',
  },
  {
    name: 'ActiveEgoLineColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '424EF0',
  },
  {
    name: 'ActiveEgoLineClass',
    set: 1,
    source: 'Mobileye',
    layerColor: '424EF0',
  },
  {
    name: 'ActiveAdjacentLaneColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '4575B4',
  },
  {
    name: 'ActiveAdjacentLaneClass',
    set: 1,
    source: 'Mobileye',
    layerColor: '4575B4',
  },
  {
    name: 'ActivePedColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '026EF7',
  },
  {
    name: 'ActiveCarColor',
    set: 1,
    source: 'Mobileye',
    layerColor: 'EDFBFC',
  },
  {
    name: 'ActiveBusColor',
    set: 1,
    source: 'Mobileye',
    layerColor: 'BFBFFF',
  },
  {
    name: 'ActiveCycleColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '9E0067',
  },
  {
    name: 'ActiveTruckColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '61B0FD',
  },
  {
    name: 'ActiveSignColor',
    set: 1,
    source: 'Ottometric',
    layerColor: '96D3E8',
  },
  {
    name: 'ActiveSignColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '514AFF',
  },
  {
    name: 'ActiveMotorcycleColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '3BC4B4',
  },
  {
    name: 'ActiveMotorcycleColor',
    set: 1,
    source: 'Ottometric',
    layerColor: 'C43B4B',
  },
  {
    name: 'ActiveTrafficLightColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '8D30CF',
  },
  {
    name: 'ActiveTrafficLightColor',
    set: 1,
    source: 'Ottometric',
    layerColor: '808000',
  },
  {
    name: 'ActiveTtdColor',
    set: 1,
    source: 'Ottometric',
    layerColor: '000',
  },
  {
    name: 'ActiveTtdColor',
    set: 1,
    source: 'Mobileye',
    layerColor: '777',
  },
]

export const layersColorsDataDic = {
  car: 'ActiveCarColor',
  truck: 'ActiveTruckColor',
  bus: 'ActiveBusColor',
  motorcycle: 'ActiveMotorcycleColor',
  pedestrian: 'ActivePedColor',
  bicycle: 'ActiveCycleColor',
  TTD: 'ActiveTtdColor',
}
