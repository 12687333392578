import { LoginRequest } from '@api/index'
import { enUS } from '@common/constants/messages'
import Password from '@common/components/Inputs/Password/Password'
import { Loader } from '@common/components/Loader/Loader'
import { FormControl, Stack, TextField } from '@mui/material'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useLoginContext, LoginBtn, useOttoLogin } from '@modules/auth'

const initialFormData: LoginRequest = {
  email: ' ',
  password: '',
  emailValidationError: '',
  passwordValidationError: '',
}

export const LoginForm = () => {
  const [formData, setFormData] = useState(initialFormData)
  const [showHint] = useState(false)
  const { isAuthenticating } = useLoginContext()
  const ottoLogin = useOttoLogin()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }))
  }

  const emailValidation = () => {
    if (!formData.email) {
      setFormData((prev) => ({
        ...prev,
        emailValidationError: enUS.LOGIN_REQUIRED_EMAIL,
      }))
      return false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(formData.email)) {
      setFormData((prev) => ({
        ...prev,
        emailValidationError: enUS.LOGIN_INVALID_EMAIL_MESSAGE,
      }))
      return false
    }

    setFormData((prev) => ({
      ...prev,
      emailValidationError: '',
    }))
    return true
  }

  const passwordValidation = () => {
    if (!formData.password) {
      setFormData((prev) => ({
        ...prev,
        passwordValidationError: enUS.PASSWORD_REQUIRED_MESSAGE,
      }))
      return false
    }

    setFormData((prev) => ({
      ...prev,
      passwordValidationError: '',
    }))
    return true
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const emailValidated = emailValidation()
    const passwordValidated = passwordValidation()

    if (emailValidated && passwordValidated) {
      ottoLogin(formData).then(() => {
        if (!isAuthenticating) {
          setFormData((prev) => ({
            ...prev,
            emailValidationError: enUS.LOGIN_WRONG_EMAIL,
            passwordValidationError: enUS.LOGIN_WRONG_PASSWORD,
          }))
        }
      })
    }
  }

  return (
    <form action='' method='POST' noValidate={true} onSubmit={handleSubmit}>
      <Stack direction='column' spacing={1}>
        <FormControl variant='filled' fullWidth>
          <TextField
            id='outlined-basic'
            required
            label='Email'
            variant='outlined'
            disabled={isAuthenticating}
            autoComplete=''
            name='email'
            value={formData.email}
            onChange={handleChange}
            error={formData.emailValidationError !== ''}
            helperText={formData.emailValidationError}
            inputProps={{ 'data-testid': 'email-input-field' }}
            sx={{
              input: {
                color: 'whitesmoke !important',
              },
              marginBottom: '20px',
            }}
          />
        </FormControl>
        <Password
          isAuthenticating={isAuthenticating}
          onChange={handleChange}
          value={formData.password}
          showHint={showHint}
          passwordValidationError={formData.passwordValidationError!}
        />
        <LoginBtn
          data-testid='otto-login-btn'
          disabled={isAuthenticating}
          type='submit'
          variant='contained'
          color='info'
          fullWidth
        >
          <span className='button-text-align'>
            {!isAuthenticating ? 'Login' : <Loader scale={0.5} />}
          </span>
        </LoginBtn>
      </Stack>
    </form>
  )
}
