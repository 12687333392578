export function getStorageKey(email: string) {
  return email ? `searchHistory::${email}` : null
}

export function getSearchHistory(email: string): string[] {
  const key = getStorageKey(email)
  const json = key ? localStorage.getItem(key) : null
  return json ? JSON.parse(json) : []
}

const MINIMUM_LETTERS = 2

export function setSearchHistory(email: string, newValue: string) {
  const key = getStorageKey(email)

  if (!key || newValue?.length <= MINIMUM_LETTERS) {
    return
  }

  const history = getSearchHistory(email)
  const set = new Set(history)

  if (set.has(newValue)) {
    return
  }

  const updatedHistory = [newValue, ...set]

  localStorage.setItem(key, JSON.stringify(updatedHistory))
}

export function clearSearchHistory(email: string) {
  const key = getStorageKey(email)

  if (!key) {
    return
  }

  localStorage.removeItem(key)
}
