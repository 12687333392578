import RateReviewIcon from '@mui/icons-material/RateReview'
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded'
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded'
import { Button, Stack } from '@mui/material'
import { useTimelineContext } from '@pages/Details/providers/TimelineContextProvider'
import { useTags } from 'components/VideoTimeline/TimelineTags/useTags'
import { IdType } from 'otto-vis-timeline'
import { useCallback, useEffect } from 'react'
import { StyledTooltip } from '../../StyledTooltip/StyledTooltip'

const iconSX = {
  color: '#f4f4f4',
}

const iconPulseGreen = { color: 'green', animation: 'pulse 0.5s infinite' }

const buttonSX = {
  minWidth: '32px',
  padding: '4px',
}

export const TimelineMarkControls = () => {
  const timelineContext = useTimelineContext()
  const { addTag, markIn, markOut } = useTags(
    timelineContext.selectedGroup as IdType
  )

  const keyDownEvent = useCallback(
    (e: KeyboardEvent) => {
      const modalClass = 'MuiDialog-root dialog-container MuiModal-root'
      if (
        document.getElementsByClassName(modalClass).length === 0 &&
        e.key.toLowerCase() === 'm'
      ) {
        addTag()
      }
    },
    [addTag]
  )

  useEffect(() => {
    window.addEventListener('keyup', keyDownEvent)

    return () => {
      window.removeEventListener('keyup', keyDownEvent)
    }
  }, [keyDownEvent])

  const tagsMarkingCompliant = (
    <>
      <StyledTooltip title='Add Tag' placement='top'>
        <span>
          <Button data-testid='addTag' sx={buttonSX} onClick={addTag}>
            <RateReviewIcon sx={iconSX} fontSize='small' />
          </Button>
        </span>
      </StyledTooltip>
      <>
        <StyledTooltip title='Mark In' placement='top'>
          <span>
            <Button data-testid='markIn' sx={buttonSX} onClick={markIn}>
              <SkipPreviousRoundedIcon sx={iconSX} fontSize='medium' />
            </Button>
          </span>
        </StyledTooltip>
        <StyledTooltip title='Mark Out' placement='top'>
          <span>
            <Button data-testid='markOut' sx={buttonSX} onClick={markOut}>
              <SkipNextRoundedIcon
                sx={timelineContext.startMarker ? iconPulseGreen : iconSX}
                fontSize='medium'
              />
            </Button>
          </span>
        </StyledTooltip>
      </>
    </>
  )

  return (
    <Stack direction='row' alignItems='center'>
      {tagsMarkingCompliant}
    </Stack>
  )
}
