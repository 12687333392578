import { createSelector } from '@reduxjs/toolkit'
import { SliceState } from './viewportDataSlice'
import { RootState } from '../../store'

export const selectViewports = (state: RootState) => state.viewportDataSlice

export const selectViewportState = (id: number) =>
  createSelector(selectViewports, (state: SliceState) => state[id])

export const selectViewportsSynced = () =>
  createSelector(selectViewports, (state: SliceState) => state.synced)
