import { Synchronizer } from '@common/services/synchronizer/synchronizer'
import { useImperativeHandle } from 'react'
import { getViewportLocalStorageState } from 'store/details/viewportStateUtils'
import { useDriveTrialContext } from '../providers/DriveTrialDataProvider'

const pauseVideoViewports = (synchronizer: Synchronizer | undefined) => {
  const viewports = getViewportLocalStorageState()
  const DTIDs: number[] = []
  Object.entries(viewports!).forEach(([DTID, value]) => {
    if (value.content === 1) {
      DTIDs.push(+DTID)
    }
  })
  DTIDs.forEach((viewport) =>
    synchronizer?.updateStatus(viewport, false, () => {})
  )
}

export const usePauseVideoViewports = (
  synchronizer: Synchronizer | undefined
) => {
  const { synchronizerRef } = useDriveTrialContext()

  useImperativeHandle(
    synchronizerRef,
    () => ({
      pauseVideos: () => pauseVideoViewports(synchronizer),
    }),
    [synchronizer]
  )
}
