import { createSelector } from '@reduxjs/toolkit'
import { SliceState } from './viewportSlice'
import { RootState } from '../../store'

export const selectViewports = (state: RootState) => state.viewportSlice

export const selectViewportLayout = (id: number) =>
  createSelector(selectViewports, (state: SliceState) => state[id])

export const selectViewportLayoutName = () =>
  createSelector(selectViewports, (state: SliceState) => state.layoutName)
