import { useCallback, useEffect, useRef, useState } from 'react'

// TODO: remove use of any
export function useDebounce<T extends (...args: any[]) => void>(
  callback: T,
  delay: number
): (arg: number) => void {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const accumulatedValue = useRef(0)

  const debouncedFunction = useCallback(
    (arg: number) => {
      accumulatedValue.current += arg

      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      const newTimeoutId = setTimeout(() => {
        callback(accumulatedValue.current)
        accumulatedValue.current = 0
      }, delay)

      setTimeoutId(newTimeoutId)
    },
    [callback, delay] // Removed timeoutId from dependencies
  )

  useEffect(() => {
    // Cleanup timeout on unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  return debouncedFunction
}
