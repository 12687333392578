import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'
import { toMilliseconds } from '@common/utils/time'
import AlignHorizontalCenterOutlinedIcon from '@mui/icons-material/AlignHorizontalCenterOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { IconButton } from '@mui/material'
import dayjs from 'dayjs'
import { useTimelineContext } from 'pages/Details/providers/TimelineContextProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useContext } from 'react'
import { animation } from '../VideoTimeline/utils'
import { iconStyles } from './commonStyles'

interface IProps {
  zoomStep: number
  viewportId: number
}

export const TimelineTools = ({ zoomStep, viewportId }: IProps) => {
  const { timeline, setOpenSettingsDialog } = useTimelineContext()

  const { timingObj } = useContext(MediaSyncContext)

  return (
    <>
      <StyledTooltip
        title={
          <span>
            Zoom in <br />
            (CTRL + wheel up)
          </span>
        }
      >
        <IconButton
          data-testid={`zoomInTimeline-${viewportId}`}
          onClick={() => {
            if (timeline) {
              timeline.zoomIn(zoomStep, animation)
            }
          }}
        >
          <ZoomInIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip
        title={
          <span>
            Zoom out <br />
            (CTRL + wheel down)
          </span>
        }
      >
        <IconButton
          data-testid={`zoomOutTimeline-${viewportId}`}
          onClick={() => {
            if (timeline) {
              timeline.zoomOut(zoomStep, animation)
            }
          }}
        >
          <ZoomOutIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Center current time'>
        <IconButton
          data-testid={`centerCurentTime-${viewportId}`}
          onClick={() => {
            if (timeline) {
              timeline.moveTo(
                dayjs(toMilliseconds(timingObj?.pos)).toDate(),
                animation
              )
            }
          }}
        >
          <AlignHorizontalCenterOutlinedIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Settings'>
        <IconButton
          data-testid={`settings-${viewportId}`}
          onClick={() => setOpenSettingsDialog(true)}
        >
          <SettingsIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>
    </>
  )
}
