import { LaneChoice, TopDownData } from '@api/objects'
import { PopupMenu } from '@common/components/PopupMenu/PopupMenu'
import CheckIcon from '@mui/icons-material/Check'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectViewportState } from '../../../../store/details/viewportData/selectors'
import { topDownDataChange } from '../../../../store/details/viewportData/viewportDataSlice'
import { MenuProps } from '../../types'

export const dropdownOptions = ['both', 'left', 'right']

interface LinesDropDownProps extends MenuProps {
  id: number
}

export const LinesDropDownMenu = forwardRef(function LinesDropDownMenu(
  props: LinesDropDownProps,
  ref
) {
  const dispatch = useDispatch()
  const data = useSelector(selectViewportState(props.id)) as TopDownData

  return (
    <PopupMenu
      closeHandler={props.closeHandler}
      backgroundColor='#3f4c6b'
      arrowPosition={props.arrowPosition}
      ref={ref}
    >
      {dropdownOptions.map((item) => {
        const dataTestIdLine = `line-selector-${item}-${props.id}`
        const dataTestIdIcon = `line-check-icon-${item}-${props.id}`
        return (
          <MenuItem
            sx={{ display: 'flex' }}
            data-testid={dataTestIdLine}
            key={item}
            onClick={() => {
              dispatch(
                topDownDataChange({
                  id: props.id,
                  data: {
                    lane: item as LaneChoice,
                    source: data.source,
                  },
                })
              )
              props.closeHandler()
            }}
          >
            <ListItemIcon sx={{ color: 'whitesmoke' }}>
              {item === data.lane && (
                <CheckIcon data-testid={dataTestIdIcon} fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>{item}</ListItemText>
          </MenuItem>
        )
      })}
    </PopupMenu>
  )
})
