export type ColumnKey =
  | 'id'
  | 'absLateralVelocity'
  | 'absLongVelocity'
  | 'relativeLateralVelocity'
  | 'relativeLongVelocity'
  | 'latDistance'
  | 'longDistance'
  | 'class'

export const columnNames: Record<ColumnKey, string> = {
  id: 'ID',
  absLateralVelocity: 'Abs lat v',
  absLongVelocity: 'Abs lon v',
  relativeLateralVelocity: 'Rel lat v',
  relativeLongVelocity: 'Rel lon v',
  latDistance: 'Lat dist',
  longDistance: 'Long dist',
  class: 'Class',
}

export const keysOrder: ColumnKey[] = [
  'id',
  'absLateralVelocity',
  'absLongVelocity',
  'relativeLateralVelocity',
  'relativeLongVelocity',
  'latDistance',
  'longDistance',
  'class',
]

export interface ColorData {
  name: string
  source: string
  layerColor: string
}

export interface ObjectInfo {
  frame: number
  class: string
  camera: number
  tx: number
  ty: number
  bx: number
  by: number
  currentActive: number
  oaoid: number
  runCount: number
  tag: number | null
  existanceProbability: number
  absLateralVelocity: number
  absLongVelocity: number
  relativeLateralVelocity: number
  relativeLongVelocity: number
  longDistance: number
  latDistance: number
  id: number
  sourceType: 'me' | 'otto'
}

export interface ObjectTableData {
  data: ObjectInfo[]
}
