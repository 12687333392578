import { Button, styled } from '@mui/material'

export const LoginBtn = styled(Button)(() => ({
  marginTop: '0px !important',
  height: '40px',
  backgroundColor: '#3C90FF',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#3366cc',
  },
  '&:active': {
    backgroundColor: '#00468B',
  },
}))
