export const INIT_DATA_TABLE_COLUMN_VISIBILITY = {
  '100001': false,
  '100003': false,
}

export const INIT_KPI_LANES_COLUMN_VISIBILITY = {
  '100001': false,
  '100003': false,
}

export const INIT_KPI_SIGNS_COLUMN_VISIBILITY = {
  '100001': false,
  '100003': false,
  '100010': false,
  '100011': false,
  '100012': false,
  '100013': false,
  '100014': false,
  '100015': false,
  '100016': false,
  '100017': false,
  '100020': false,
  '100021': false,
  '100022': false,
  '100023': false,
  '100024': false,
  '100025': false,
  '100026': false,
  '100027': false,
  '100030': false,
  '100031': false,
  '100032': false,
  '100033': false,
  '100034': false,
  '100035': false,
  '100036': false,
  '100037': false,
  '100040': false,
  '100041': false,
  '100042': false,
  '100043': false,
  '100044': false,
  '100045': false,
  '100046': false,
  '100047': false,
  '100050': false,
  '100051': false,
  '100052': false,
  '100053': false,
  '100054': false,
  '100055': false,
  '100056': false,
  '100057': false,
  '100060': false,
  '100061': false,
  '100062': false,
  '100063': false,
  '100064': false,
  '100065': false,
  '100066': false,
  '100067': false,
  '100070': false,
  '100071': false,
  '100072': false,
  '100073': false,
  '100074': false,
  '100075': false,
  '100076': false,
  '100077': false,
  '100080': false,
  '100081': false,
  '100082': false,
  '100083': false,
  '100084': false,
  '100085': false,
  '100086': false,
  '100087': false,
  '100090': false,
  '100091': false,
  '100092': false,
  '100093': false,
  '100094': false,
  '100095': false,
  '100096': false,
  '100097': false,
  '1000100': false,
  '1000101': false,
  '1000102': false,
  '1000103': false,
  '1000104': false,
  '1000105': false,
  '1000106': false,
  '1000107': false,
  '1000110': false,
  '1000111': false,
  '1000112': false,
  '1000113': false,
  '1000114': false,
  '1000115': false,
  '1000116': false,
  '1000117': false,
  '1000120': false,
  '1000121': false,
  '1000122': false,
  '1000123': false,
  '1000124': false,
  '1000125': false,
  '1000126': false,
  '1000127': false,
  '1000130': false,
  '1000131': false,
  '1000132': false,
  '1000133': false,
  '1000134': false,
  '1000135': false,
  '1000136': false,
  '1000137': false,
  '1000140': false,
  '1000141': false,
  '1000142': false,
  '1000143': false,
  '1000144': false,
  '1000145': false,
  '1000146': false,
  '1000147': false,
  '1000150': false,
  '1000151': false,
  '1000152': false,
  '1000153': false,
  '1000154': false,
  '1000155': false,
  '1000156': false,
  '1000157': false,
  '1000160': false,
  '1000161': false,
  '1000162': false,
  '1000163': false,
  '1000164': false,
  '1000165': false,
  '1000166': false,
  '1000167': false,
  '1000170': false,
  '1000171': false,
  '1000172': false,
  '1000173': false,
  '1000174': false,
  '1000175': false,
  '1000176': false,
  '1000177': false,
  '1000180': false,
  '1000181': false,
  '1000182': false,
  '1000183': false,
  '1000184': false,
  '1000185': false,
  '1000186': false,
  '1000187': false,
  '1000190': false,
  '1000191': false,
  '1000192': false,
  '1000193': false,
  '1000194': false,
  '1000195': false,
  '1000196': false,
  '1000197': false,
  '1000200': false,
  '1000201': false,
  '1000202': false,
  '1000203': false,
  '1000204': false,
  '1000205': false,
  '1000206': false,
  '1000207': false,
  '1000210': false,
  '1000211': false,
  '1000212': false,
  '1000213': false,
  '1000214': false,
  '1000215': false,
  '1000216': false,
  '1000217': false,
  '1000220': false,
  '1000221': false,
  '1000222': false,
  '1000223': false,
  '1000224': false,
  '1000225': false,
  '1000226': false,
  '1000227': false,
  '1000230': false,
  '1000231': false,
  '1000232': false,
  '1000233': false,
  '1000234': false,
  '1000235': false,
  '1000236': false,
  '1000237': false,
  '1000240': false,
  '1000241': false,
  '1000242': false,
  '1000243': false,
  '1000244': false,
  '1000245': false,
  '1000246': false,
  '1000247': false,
  '1000250': false,
  '1000251': false,
  '1000252': false,
  '1000253': false,
  '1000254': false,
  '1000255': false,
  '1000256': false,
  '1000257': false,
  '1000260': false,
  '1000261': false,
  '1000262': false,
  '1000263': false,
  '1000264': false,
  '1000265': false,
  '1000266': false,
  '1000267': false,
  '1000270': false,
  '1000271': false,
  '1000272': false,
  '1000273': false,
  '1000274': false,
  '1000275': false,
  '1000276': false,
  '1000277': false,
  '1000280': false,
  '1000281': false,
  '1000282': false,
  '1000283': false,
  '1000284': false,
  '1000285': false,
  '1000286': false,
  '1000287': false,
  '1000290': false,
  '1000291': false,
  '1000292': false,
  '1000293': false,
  '1000294': false,
  '1000295': false,
  '1000296': false,
  '1000297': false,
  '1000300': false,
  '1000301': false,
  '1000302': false,
  '1000303': false,
  '1000304': false,
  '1000305': false,
  '1000306': false,
  '1000307': false,
  '1000310': false,
  '1000311': false,
  '1000312': false,
  '1000313': false,
  '1000314': false,
  '1000315': false,
  '1000316': false,
  '1000317': false,
  '1000320': false,
  '1000321': false,
  '1000322': false,
  '1000323': false,
  '1000324': false,
  '1000325': false,
  '1000326': false,
  '1000327': false,
}

export const INIT_KPI_LIGHTS_COLUMN_VISIBILITY = {
  '100010': false,
  '100011': false,
  '100012': false,
  '100013': false,
  '100014': false,
  '100015': false,
  '100020': false,
  '100021': false,
  '100022': false,
  '100023': false,
  '100024': false,
  '100025': false,
  '100030': false,
  '100031': false,
  '100032': false,
  '100033': false,
  '100034': false,
  '100035': false,
  '100040': false,
  '100041': false,
  '100042': false,
  '100043': false,
  '100044': false,
  '100045': false,
  '100060': false,
  '100061': false,
  '100062': false,
  '100063': false,
  '100064': false,
  '100065': false,
  '100070': false,
  '100071': false,
  '100072': false,
  '100073': false,
  '100074': false,
  '100075': false,
  '100080': false,
  '100081': false,
  '100082': false,
  '100083': false,
  '100084': false,
  '100085': false,
  '100090': false,
  '100091': false,
  '100092': false,
  '100093': false,
  '100094': false,
  '100095': false,
  '1000100': false,
  '1000101': false,
  '1000102': false,
  '1000103': false,
  '1000104': false,
  '1000105': false,
  '1000110': false,
  '1000111': false,
  '1000112': false,
  '1000113': false,
  '1000114': false,
  '1000115': false,
  '1000120': false,
  '1000121': false,
  '1000122': false,
  '1000123': false,
  '1000124': false,
  '1000125': false,
  '1000130': false,
  '1000131': false,
  '1000132': false,
  '1000133': false,
  '1000134': false,
  '1000135': false,
  '1000140': false,
  '1000141': false,
  '1000142': false,
  '1000143': false,
  '1000144': false,
  '1000145': false,
  '1000150': false,
  '1000151': false,
  '1000152': false,
  '1000153': false,
  '1000154': false,
  '1000155': false,
  '1000160': false,
  '1000161': false,
  '1000162': false,
  '1000163': false,
  '1000164': false,
  '1000165': false,
  '1000170': false,
  '1000171': false,
  '1000172': false,
  '1000173': false,
  '1000174': false,
  '1000175': false,
  '1000180': false,
  '1000181': false,
  '1000182': false,
  '1000183': false,
  '1000184': false,
  '1000185': false,
  '1000190': false,
  '1000191': false,
  '1000192': false,
  '1000193': false,
  '1000194': false,
  '1000195': false,
  '1000200': false,
  '1000201': false,
  '1000202': false,
  '1000203': false,
  '1000204': false,
  '1000205': false,
  '1000210': false,
  '1000211': false,
  '1000212': false,
  '1000213': false,
  '1000214': false,
  '1000215': false,
  '1000220': false,
  '1000221': false,
  '1000222': false,
  '1000223': false,
  '1000224': false,
  '1000225': false,
  '1000230': false,
  '1000231': false,
  '1000232': false,
  '1000233': false,
  '1000234': false,
  '1000235': false,
  '1000240': false,
  '1000241': false,
  '1000242': false,
  '1000243': false,
  '1000244': false,
  '1000245': false,
  '1000250': false,
  '1000251': false,
  '1000252': false,
  '1000253': false,
  '1000254': false,
  '1000255': false,
  '1000260': false,
  '1000261': false,
  '1000262': false,
  '1000263': false,
  '1000264': false,
  '1000265': false,
  '1000270': false,
  '1000271': false,
  '1000272': false,
  '1000273': false,
  '1000274': false,
  '1000275': false,
  '1000280': false,
  '1000281': false,
  '1000282': false,
  '1000283': false,
  '1000284': false,
  '1000285': false,
  '1000290': false,
  '1000291': false,
  '1000292': false,
  '1000293': false,
  '1000294': false,
  '1000295': false,
  '1000300': false,
  '1000301': false,
  '1000302': false,
  '1000303': false,
  '1000304': false,
  '1000305': false,
  '1000310': false,
  '1000311': false,
  '1000320': false,
  '1000321': false,
  '1000330': false,
  '1000331': false,
  '1000340': false,
  '1000341': false,
}
