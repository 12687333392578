export const toggleClasses = (
  element: HTMLElement,
  toRemove: string[],
  toAdd: string[]
) => {
  toRemove.forEach((className) => element.classList.remove(className))
  toAdd.forEach((className) => element.classList.add(className))
}

export const lightenColor = (rgb: string, percent: number): string => {
  const rgbValues: number[] = rgb.match(/\d+/g)?.map(Number) ?? []

  if (rgbValues.length !== 3) {
    throw new Error('Invalid RGB color format')
  }

  const lighten = (value: number): number =>
    Math.min(Math.round(value + (255 - value) * (percent / 100)), 255)

  const [r, g, b] = rgbValues.map(lighten)

  return `rgb(${r}, ${g}, ${b})`
}

export const applyStyle = () => {
  const items = document.querySelectorAll('[class*="highlight"]')

  for (let i = 0; i < items.length; i++) {
    const classNames = items[i].className.split(/\s+/)
    let widthValue = '0%'
    classNames.forEach((className) => {
      const match = className.match(/(?:^|\s)highlight-(\d+(?:\.\d+)?)(?=\s|$)/)
      if (match) {
        widthValue = `${match[1]}%`
      }
    })

    const targetDiv = Array.from(
      items[i].querySelectorAll('div.vis-item-content')
    ).find((innerDiv) => innerDiv)

    const color = targetDiv?.parentElement?.parentElement?.style.backgroundColor

    if (targetDiv && targetDiv instanceof HTMLElement && color) {
      targetDiv.style.display = 'flex'
      targetDiv.style.justifyContent = 'center'
      const childDivs = targetDiv.querySelectorAll('div')
      childDivs.forEach(function (div) {
        div.style.width = widthValue
        div.style.height = '100%'
        if (widthValue !== '100%') {
          div.style.boxShadow = `0px 0px 1px 3000px ${lightenColor(color, 40)}`
        }
      })
    }
  }
}

export const getDarkerShade = (rgb: string, percentage: number) => {
  const [r, g, b]: number[] = rgb.match(/\d+/g)?.map(Number) ?? []

  const factor = 1 - percentage / 100
  const newR = Math.round(r * factor)
  const newG = Math.round(g * factor)
  const newB = Math.round(b * factor)

  return `rgb(${newR}, ${newG}, ${newB})`
}
