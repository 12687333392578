// TODO: this should be moved to its module where it is used

import { gridOptions } from '@common/components/GridSelectors/GridSelectors'
import { ViewportContent } from '@pages/Details/types/viewportContent'

export const getNumberOfViewportsOnScreen = (): number => {
  const detailsLayout = localStorage.getItem('detailsLayout') || ''
  // TODO: remove use of any
  let parsedDetailsLayout: any
  if (detailsLayout.trim()) {
    try {
      parsedDetailsLayout = JSON.parse(detailsLayout)
    } catch (error) {
      console.error('Error parsing detailsLayout:', error)
      parsedDetailsLayout = ''
    }
  } else {
    parsedDetailsLayout = ''
  }
  const layoutOption = gridOptions.find(
    (option) => option.name === parsedDetailsLayout
  )
  if (layoutOption) {
    return layoutOption.value.reduce((a, b) => a + b, 0)
  }

  return 0
}

export const isTimelineDisabled = () => {
  const numberOfViewportsOnScreen = getNumberOfViewportsOnScreen()
  const data = JSON.parse(localStorage.getItem('viewports') || '{}')

  // TODO: remove use of any
  const containsTimeline = (obj: Record<string, any>) => {
    const keys = Object.keys(obj).slice(0, numberOfViewportsOnScreen)
    return keys.some((key) => obj[key].content === ViewportContent.TIMELINE)
  }

  return containsTimeline(data)
}
