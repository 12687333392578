interface GridIconProps {
  color: string
}

export function View21Standard({ color = '#ffffff' }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='8'
          width='15'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='8'
          width='32'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_7'
          height='8'
          width='15'
          y='0'
          x='17'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View21Review({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='13'
          width='21'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='3'
          width='32'
          y='15'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_7'
          height='13'
          width='9'
          y='0'
          x='23'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View41({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='8'
          width='6.5'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='8'
          width='32'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_8'
          height='8'
          width='6.5'
          y='0'
          x='25.5'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_9'
          height='8'
          width='6.5'
          y='0'
          x='17.02576'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_10'
          height='8'
          width='6.5'
          y='0'
          x='8.47604'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View218020({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='13'
          width='15'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='3'
          width='32'
          y='15'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_7'
          height='13'
          width='15'
          y='0'
          x='17'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View43({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='8'
          width='6'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='8'
          width='9'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_9'
          height='8'
          width='6'
          y='0'
          x='17.40344'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_10'
          height='8'
          width='6'
          y='0'
          x='8.72205'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_11'
          height='8'
          width='9'
          y='10'
          x='11.46494'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_16'
          height='8'
          width='6'
          y='0'
          x='26'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_18'
          height='8'
          width='9'
          y='10'
          x='23'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View32({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='8'
          width='9'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='8'
          width='15'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_9'
          height='8'
          width='9'
          y='0'
          x='23'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_10'
          height='8'
          width='9'
          y='0'
          x='11.48957'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_11'
          height='8'
          width='15'
          y='10'
          x='17'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View42({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_3'
          height='8'
          width='6'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='8'
          width='15'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_9'
          height='8'
          width='6'
          y='0'
          x='17.40344'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_10'
          height='8'
          width='6'
          y='0'
          x='8.72205'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_11'
          height='8'
          width='15'
          y='10'
          x='17'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_16'
          height='8'
          width='6'
          y='0'
          x='26'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View22({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_21'
          height='8'
          width='15'
          y='0'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_23'
          height='8'
          width='15'
          y='10'
          x='17'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_24'
          height='8'
          width='15'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_27'
          height='8'
          width='15'
          y='0'
          x='17'
          fill={color}
        />
      </g>
    </svg>
  )
}

export function View33({ color }: GridIconProps) {
  return (
    <svg width='32' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_6'
          height='8'
          width='9'
          y='10'
          x='0'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_11'
          height='8'
          width='9'
          y='10'
          x='11.46494'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_18'
          height='8'
          width='9'
          y='10'
          x='23'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_22'
          height='8'
          width='9'
          y='0.0369'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_23'
          height='8'
          width='9'
          y='0.0369'
          x='11.46494'
          fill={color}
        />
        <rect
          stroke='#000'
          strokeWidth='0'
          rx='1'
          id='svg_24'
          height='8'
          width='9'
          y='0.0369'
          x='23'
          fill={color}
        />
      </g>
    </svg>
  )
}
