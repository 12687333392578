import { SprItem } from '@api/sprGallery/spr-gallery'
import { Typography } from '@mui/material'
import { Filter } from '@mui/icons-material'
import { ImageWithPlaceholder } from '@common/components/ImageWithPlaceholder'

type ImageItemProps = {
  item: SprItem
  handleClick: (item: SprItem) => void
}

export const ImageItem = ({ item, handleClick }: ImageItemProps) => {
  return (
    <div className='image-container' onClick={() => handleClick(item)}>
      <ImageWithPlaceholder
        className='image'
        src={item.url}
        alt={item.dtid.toString()}
        placeholder={'/gallery-placeholder.png'}
      />
      <div className='image-overlay'>
        {item.isDelegate && item.totalConstituents !== undefined ? (
          <div
            title={`${item.totalConstituents} constutuents for this delegate`}
            className='image-overlay-content'
          >
            <Filter sx={{ fontSize: '14px' }} />
            <Typography fontSize={'14px'} variant='body1'>
              {item.totalConstituents}
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  )
}
