import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useHandleSuggestionClick = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(
    (value: string) => {
      setSearchParams((sp) => {
        sp.set('nlQuery', value)
        return sp
      })
    },
    [setSearchParams]
  )
}
