import { urls } from '@api/index'
import { enUS } from '@common/constants/messages'
import { JiraTeam, jiraTeamIds } from 'components/VideoTimeline/types'
import { enqueueSnackbar } from 'notistack'
import { createUrl } from '../../components/VideoTimeline/utils'

export const findKeyByValue = (value: string) => {
  return Object.keys(jiraTeamIds).find(
    (key) => jiraTeamIds[key as JiraTeam] === value
  )
}

interface ExtendedMediaTrackConstraints extends MediaTrackConstraints {
  cursor?: 'always' | 'motion' | 'never'
}

const _uploadScreenshot = async (blob: Blob, jiraID: string) => {
  const formData = new FormData()
  formData.append('file', blob, 'screenshot.png')

  try {
    const response = await fetch(
      process.env.REACT_APP_URL_BACKEND_WEB +
        createUrl(urls.captureScreenshot, jiraID),
      {
        method: 'POST',
        body: formData,
      }
    )
    await response.json().then(() =>
      enqueueSnackbar({
        message: enUS.UPLOAD_SUCCESSFULL,
        variant: 'info',
        autoHideDuration: 3000,
      })
    )
  } catch (error) {
    console.error('Upload failed:', error)
  }
}

export const captureScreenshot = async (jiraID: string) => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: { cursor: 'always' } as ExtendedMediaTrackConstraints,
    })

    enqueueSnackbar({
      message: enUS.CHOOSE_WINDOW,
      variant: 'info',
      autoHideDuration: 4000,
    })

    await new Promise((resolve) => setTimeout(resolve, 5000))

    const video = document.createElement('video')
    video.srcObject = stream

    await new Promise<void>((resolve) => {
      video.onloadedmetadata = () => {
        video.play()
        resolve()
      }
    })

    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    const context = canvas.getContext('2d')
    context!.drawImage(video, 0, 0, canvas.width, canvas.height)

    stream.getTracks().forEach((track) => track.stop())

    canvas.toBlob(async (blob) => {
      if (blob) {
        await _uploadScreenshot(blob, jiraID)
      }
    }, 'image/png')
  } catch (error) {
    console.error('Screenshot capture failed:', error)
  }
}
