import { PROJECT_IDS } from '@common/constants/projects'
import { Select } from '@common/components/Inputs/Select/Select'
import { SelectChangeEvent } from '@mui/material'
import { useReportContext } from '@pages/Report/providers/ReportContextProvider'
import { useEffect, useState } from 'react'
import { DashboardTable } from '@pages/Dashboard/containers/DashboardTable'

import {
  Cell,
  DashboardTableSource,
  DummyData,
  DummyDataVolvo,
  RoadType,
} from '@pages/Dashboard/mocks/DummyData'

export const DashboardCharts = () => {
  const { projectID } = useReportContext()
  const [dashboardTableSource, setDashboardTableSource] = useState<string>(
    DashboardTableSource.RAW_HOURS
  )
  const [dummyData, setDummyData] = useState<Cell[]>(DummyData)

  const [, setChartDimensions] = useState({
    width: (window.innerWidth / 3) * 0.9,
    height: (window.innerHeight / 2) * 0.8,
  })

  const updateDimensions = () => {
    setChartDimensions({
      width: (window.innerWidth / 3) * 0.9,
      height: (window.innerHeight / 2) * 0.8,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    setDummyData(
      PROJECT_IDS.VOLVO === parseInt(projectID) ? DummyDataVolvo : DummyData
    )
  }, [projectID])

  const selectTableSourceOptions = Object.entries(DashboardTableSource).map(
    ([_, value]) => ({
      text: value,
      value: value,
    })
  )

  const handleTableSourceSelectChange = (event: SelectChangeEvent) => {
    setDashboardTableSource(event.target.value)
  }

  return (
    <div className='dashboard-charts'>
      <div className='dashboard-charts-select'>
        <Select
          title='Table Source'
          options={selectTableSourceOptions}
          variant='outlined'
          size='small'
          value={dashboardTableSource}
          minWidth={142}
          onChange={handleTableSourceSelectChange}
        />
      </div>
      <div className='dashboard-table-component'>
        <DashboardTable
          roadType={RoadType.CITY}
          tableSource={dashboardTableSource}
          dummyData={dummyData}
        />
        <DashboardTable
          roadType={RoadType.HIGHWAY}
          tableSource={dashboardTableSource}
          dummyData={dummyData}
        />
        <DashboardTable
          roadType={RoadType.RURAL}
          tableSource={dashboardTableSource}
          dummyData={dummyData}
        />
      </div>
    </div>
  )
}
