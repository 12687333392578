import { Zoom } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

interface IProps {
  title: string | React.ReactElement
  children: React.ReactElement
  placement?: Placement
  showArrow?: boolean
  followCursor?: boolean
  enterDelay?: number
}

export const StyledTooltip = ({
  title,
  placement = 'right',
  children,
  showArrow = true,
  followCursor = false,
  enterDelay = 500,
}: IProps) => {
  return (
    <Tooltip
      enterDelay={enterDelay}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 200 }}
      title={title}
      placement={placement}
      arrow={showArrow}
      followCursor={followCursor}
    >
      {children}
    </Tooltip>
  )
}
