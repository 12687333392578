import { ArrowDropDown } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useTimelineContext } from '@pages/Details/providers/TimelineContextProvider'
import { useState } from 'react'
import { applyNonLinearTransformation } from '../TimelineHighlightMode/timelineHighlight'
import { SortHighlights } from '../types'

export function TimelineSettings() {
  const {
    openSettingsDialog,
    setOpenSettingsDialog,
    items,
    timelineSettings,
    setTimelineSettings,
  } = useTimelineContext()
  const {
    highlightLength: timelineSettingsHighlightLength,
    sortBy: timelineSettingsSortBy,
  } = timelineSettings
  const { highlightMode, setHighlightMode } = useDriveTrialContext()
  const [highlightLength, setHighlightLength] = useState(
    timelineSettingsHighlightLength ?? 5
  )
  const [sortBy, setSortBy] = useState<SortHighlights>(
    timelineSettingsSortBy ?? 'time'
  )
  const isSortByGainAvailable = items
    .map((x) => x)
    .filter(
      (y) =>
        y.group === highlightMode.id &&
        !y.id.toString().includes('placeholder') &&
        !y.id.toString().includes('tag') &&
        !y.id.toString().includes('exclusion')
    )
    .every((z) => Object.hasOwn(z, 'gain'))

  const handleLengthChange = (event: SelectChangeEvent) =>
    setHighlightLength(+event.target.value)

  const handleSortChange = (event: SelectChangeEvent) =>
    setSortBy(event.target.value as SortHighlights)

  const handleClose = () => setOpenSettingsDialog(false)

  const handleConfirm = () => {
    if (highlightMode.id !== -1) {
      setHighlightMode({
        ...highlightMode,
        id: highlightMode.id,
        items: applyNonLinearTransformation(
          items,
          highlightMode.id,
          highlightLength,
          sortBy
        ),
        sortBy: sortBy,
        minLength: highlightLength,
      })
    }
    setTimelineSettings({ highlightLength, sortBy })

    handleClose()
  }

  return (
    <Dialog
      open={openSettingsDialog}
      onClose={handleClose}
      PaperProps={{
        sx: { backgroundColor: (theme) => theme.palette.primary.main },
      }}
    >
      <DialogTitle sx={{ color: 'white' }}>Timeline Settings</DialogTitle>
      <DialogContent
        sx={{
          width: '300px',
          maxWidth: '100%',
        }}
      >
        <Box my={4}>
          <FormControl fullWidth>
            <InputLabel id='highlight-length-label'>
              Highlight length (s)
            </InputLabel>
            <Select
              labelId='highlight-length-label'
              value={highlightLength.toString()}
              label='Highlight length (s)'
              onChange={handleLengthChange}
              IconComponent={(props) => (
                <ArrowDropDown
                  data-testid='highlightsArrow-length'
                  {...props}
                />
              )}
            >
              <MenuItem value={'3'}>3</MenuItem>
              <MenuItem value={'5'}>5</MenuItem>
              <MenuItem value={'10'}>10</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mb={4}>
          <FormControl fullWidth>
            <InputLabel id='highlight-sort-by-label'>Sort by</InputLabel>
            <Select
              labelId='highlight-sort-by-label'
              value={sortBy}
              label='Sort by'
              onChange={handleSortChange}
              IconComponent={(props) => (
                <ArrowDropDown
                  data-testid='highlightsArrow-sort-by'
                  {...props}
                />
              )}
            >
              <MenuItem value={'gain'} disabled={!isSortByGainAvailable}>
                Severity
              </MenuItem>
              <MenuItem value={'time'}>Time</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          sx={{ backgroundColor: '#102d92' }}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
