export const DEFAULT_SELECTED_COLUMNS = [
  { kpi: 'GEO', items: ['Altitude', 'Country'] },
  { kpi: 'Gps Speed', items: ['Kph'] },
  { kpi: 'Road Type', items: ['City', 'Highway', 'Rural'] },
  { kpi: 'Time Of Day', items: ['Day', 'Night', 'Twilight'] },
  { kpi: 'Weather', items: ['Cloudy', 'Fog', 'Raining', 'Snowing', 'Sunny'] },
]

export const SPR_DAYS_OPTIONS = Array.from({ length: 11 }, (_, i) => i + 1)

export const SPR_ITEM_LIMIT = 12
export const NL_ITEM_LIMIT = 12
