import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { enUS } from '@common/constants/messages'
import { Routes } from '@common/constants/paths'
import {
  GoogleLogin,
  LoginContext,
  LoginForm,
  MicrosoftLogin,
  StyledDivider,
  useAuthContext,
} from '@modules/auth'
import './style.scss'

function LoginComponent() {
  const { isAuthenticated } = useAuthContext()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const schemas = process.env.REACT_APP_LOGIN_SCHEMAS?.split('|') || []
  const hasLoginForm = schemas.includes('login_password')

  // prevent hitting the login page when authenticated
  if (isAuthenticated) {
    const jiraURL = window.localStorage.getItem('jiraURL')

    if (jiraURL) {
      window.location.replace(jiraURL)
    } else {
      return (
        <Navigate
          to={`${Routes.REPORTING}/${Routes.DASHBOARD}/Dashboard`}
          replace={true}
        />
      )
    }
  }

  return (
    <LoginContext.Provider
      value={{
        isAuthenticating,
        setIsAuthenticating,
      }}
    >
      <div className='splash-screen-container'>
        <div className='login-wrapper'>
          <div className='login-form-container'>
            <div className='login-form-header'>
              <div className='login-logo'></div>
              <h2 className='login-headline'>{enUS.LOGIN_HEADLINE}</h2>
              <h4 className='login-subtitle'>{enUS.LOGIN_SUBTITLE}</h4>
            </div>

            {hasLoginForm && <LoginForm />}
            {hasLoginForm && schemas.length > 1 && (
              <StyledDivider textcolor='white' linecolor='#a9c7ff'>
                OR
              </StyledDivider>
            )}
            <div className='login-social-container'>
              {schemas.includes('google') && <GoogleLogin />}
              {schemas.includes('microsoft') && <MicrosoftLogin />}
            </div>
          </div>
        </div>
      </div>
    </LoginContext.Provider>
  )
}

export default LoginComponent
