import { PROJECT_IDS } from '@common/constants/projects'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { SignDataResponse } from '../components/TopDown3D_V1_Copy/types'
import { useProjectData } from './table/projects'
import { urls } from './urls'

const PLY_JSON_FILE = 'tags_100_signs.json'

export const usePlyJsonQuery = () => {
  const { projectID } = useProjectData()

  return useQuery({
    enabled: PROJECT_IDS.VOLVO !== projectID,
    queryKey: ['plyJson'],
    staleTime: Infinity,
    queryFn: (): Promise<SignDataResponse> =>
      axios
        .get(urls.files.replace('url', btoa(PLY_JSON_FILE)), {
          headers: { 'Return-Type': 'application/json' },
        })
        .then(({ data }) => data),
  })
}
