import { useState } from 'react'
import { Button } from '@mui/material'

import {
  View218020,
  View21Review,
  View21Standard,
  View22,
  View32,
  View33,
  View41,
  View42,
  View43,
} from '@common/assets/Svgs/GridIcons'
import { MenuRef } from 'components/Menus/types'
import { getDefaultGridOption } from 'pages/Details/utils/getDefaultGridOption'
import { getClientID } from '@common/utils/storage'
import { PopupMenu } from '../PopupMenu/PopupMenu'

interface ViewportDimensions {
  width?: string | number
  height?: string | number
}

const review2n1Dimensions = [
  {
    maxWidth: '70%!important',
    flexBasis: '70%!important',
    height: '75%',
  },
  {
    maxWidth: '30%!important',
    flexBasis: '30%!important',
    height: '75%',
  },
  {
    height: '25%',
  },
]

export interface GridOption {
  icon?: (color: string) => JSX.Element
  name: string
  value: number[]
  customDimensions?: ViewportDimensions[]
}

export const gridOptions: GridOption[] = [
  {
    icon: (color: string) => <View21Standard color={color} />,
    name: '2/1',
    value: [2, 1],
  },
  {
    icon: (color: string) => <View22 color={color} />,
    name: '2/2',
    value: [2, 2],
  },
  {
    icon: (color: string) => <View32 color={color} />,
    name: '3/2',
    value: [3, 2],
  },
  {
    icon: (color: string) => <View33 color={color} />,
    name: '3/3',
    value: [3, 3],
  },
  {
    icon: (color: string) => <View41 color={color} />,
    name: '4/1',
    value: [4, 1],
  },
  {
    icon: (color: string) => <View42 color={color} />,
    name: '4/2',
    value: [4, 2],
  },
  {
    icon: (color: string) => <View43 color={color} />,
    name: '4/3',
    value: [4, 3],
  },
  {
    icon: (color: string) => <View21Review color={color} />,
    name: '2/1-review',
    value: [2, 1],
    customDimensions: review2n1Dimensions,
  },
  {
    icon: (color: string) => <View218020 color={color} />,
    name: '80/20',
    value: [2, 1],
    customDimensions: [{ height: '80%' }, { height: '80%' }, { height: '20%' }],
  },
]

interface IProps {
  pickGrid: (value: GridOption) => void
  closeHandler: () => void
  spawner: React.MutableRefObject<MenuRef | undefined>
}

const GridSelectors = ({ closeHandler, pickGrid, spawner }: IProps) => {
  const clientID = getClientID()
  const [pickedGrid, setPickedGrid] = useState<GridOption>(
    getDefaultGridOption(clientID)
  )

  const handleClick = (option: GridOption) => {
    setPickedGrid(option)
    pickGrid(option)
    localStorage.setItem('detailsLayout', JSON.stringify(option.name))
  }

  return (
    <PopupMenu
      arrowPosition='right'
      closeHandler={closeHandler}
      backgroundColor='#3f4c6b'
      ref={spawner}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        {gridOptions.map((btn) => (
          <Button
            data-testid={`viewportMenu-${btn.name}`}
            aria-valuemax={btn.value[0]}
            aria-valuemin={btn.value[1]}
            key={btn.name}
            onClick={() => handleClick(btn)}
          >
            {btn.icon &&
              btn.icon(pickedGrid.name === btn.name ? '#fff' : '#ffffff33')}
          </Button>
        ))}
      </div>
    </PopupMenu>
  )
}

export default GridSelectors
