import { useState } from 'react'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { IconButton, Tooltip } from '@mui/material'
import { Column, Row } from '@tanstack/react-table'
import PdfPopover from './pdfPopover'
import { enUS } from '@common/constants/messages'

interface PdfDownloadProps {
  visibleColumns: Column<any, any>[]
  bodyRows: Row<any>[]
}

export const PdfDownload = ({ bodyRows, visibleColumns }: PdfDownloadProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const columnNumber = visibleColumns.filter((col) => col.parent).length

  const openPdfMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Tooltip title={enUS.DOWNLOAD_PDF} placement={'top'} arrow>
        <IconButton data-testid='exportPDF' onClick={(e) => openPdfMenu(e)}>
          <PictureAsPdfIcon fontSize='medium' sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
      <PdfPopover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        bodyRows={bodyRows}
        visibleColumns={visibleColumns}
        columnNumber={columnNumber}
      />
    </>
  )
}
