import { KpiFilterResponseData } from '@api/index'
import { RuleGroupType, formatQuery } from 'react-querybuilder'
import { match } from 'ts-pattern'
import { ProjectName } from 'models/project'
import { FetchTypes, FilterData, FilterObject } from 'models/table'
import { ReportType } from '@pages/Report/types'
import {
  KpiFilterData,
  KpiFilterType,
  KpiSummaryState,
} from '@modules/sidebarToolbox/types/kpiFilters'

export const formatQuerySql = (query: RuleGroupType) =>
  formatQuery(query, {
    format: 'sql',
    parseNumbers: true,
  })

export const formatKpiFilterPayload = (
  filterState: KpiSummaryState,
  filterData: KpiFilterData
) => {
  const { dateRange, filters } = filterState
  const payload: FilterObject = {}

  if (dateRange[0] && dateRange[1]) {
    payload.startDate = dateRange[0]
    payload.endDate = dateRange[1]
  }

  Object.keys(filters).forEach((x) => {
    const filter = [
      ...filterData.generalFilters.filters,
      ...filterData.projectFilters.filters,
    ].find((f) => f.name === x)
    payload[x] = filter?.multiSelect ? JSON.stringify(filters[x]) : filters[x]
  })

  return payload
}

const lanePayload = {
  lane: "['EGO', 'Adjacent']",
  side: "['Left', 'Right']",
  kpi: "['LanePresent','TypeClassificationRate','LateralPosition-30m','LateralPosition-60m','LateralPosition-100m','LateralPosition-150m','ColorHostLaneBoundaries-White', 'ColorHostLaneBoundaries-Yellow']",
}

const lightsPayload = {
  kpi: "['GT', 'TP', 'FP', 'FN', 'Accuracy', 'Recall']",
}

const signsPayload = {
  'Speed Range': '',
  speed:
    "[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 'All Speeds']",
}

const getProjectPayload = (project: ProjectName) =>
  match(project)
    .with('Lanes', () => lanePayload)
    .with('Lights', () => lightsPayload)
    .with('Signs', () => signsPayload)
    .otherwise(() => {}) //Other projects currently don't need extra payload

export const createKpiFilterPayload = (
  filterData: KpiFilterData,
  filterState: KpiSummaryState,
  project: ProjectName,
  reportType: ReportType,
  limit: number = 150,
  skip: number = 0
): FilterData => {
  const filterNames: FilterObject = [
    ...filterData.generalFilters.filters,
    ...filterData.projectFilters.filters,
  ].reduce((acc, f) => ({ ...acc, [f.name]: '' }), {})

  const initialFilterShape = {
    startDate: '',
    endDate: '',
    ...filterNames,
    ...getProjectPayload(project),
  }

  const payload = formatKpiFilterPayload(filterState, filterData)

  return {
    kpiFilters:
      payload.length === 0 ? {} : { ...initialFilterShape, ...payload },
    limit,
    skip,
    project,
    reportType: reportType,
    fetchType: 'filter' as FetchTypes,
  }
}

export const prepareKpiFilters = (
  filters: KpiFilterResponseData[],
  project: string
): KpiFilterData => {
  const generalFilters: KpiFilterType = { name: 'General', filters: [] }
  const projectFilters: KpiFilterType = { name: project, filters: [] }
  filters.forEach((f) =>
    (f.isGeneral ? generalFilters : projectFilters).filters.push({
      name: f.name,
      multiSelect: f.multiSelect,
      values: f.values.map((value) => ({
        text: value,
        value,
      })),
    })
  )

  return {
    generalFilters,
    projectFilters,
  }
}
