import { GoogleLoginButton } from 'react-social-login-buttons'
import { useInitGoogleApi, useLoginContext } from '@modules/auth'

export function GoogleLogin() {
  const initGoogleApi = useInitGoogleApi()
  const { isAuthenticating } = useLoginContext()

  return (
    <GoogleLoginButton
      disabled={isAuthenticating}
      onClick={initGoogleApi}
      align='left'
      className='social-login-button google'
    />
  )
}
