import { RedirectData } from '@api/redirectionData'
import {
  SkewConverter,
  TimingObject,
  TimingProgress,
  TimingSampler,
} from '@common/services/timing/timing'
import { Dispatch, SetStateAction, createContext } from 'react'
import { HighlightMode } from '../providers/TimelineContextProvider'

export type SamplerReference = {
  terminate: () => void
}

export type SynchronizerReference = {
  pauseVideos: () => void
}

//#region DriveTrial
export interface DriveTrial {
  id: number
  DTID: number
  parentDTID: number
  version: string
  startDate: number
  endDate: number
  duration: number
  cumulativeDuration: number
  startTime: number
  endTime: number
  description: string
  previousDuration: number
  originalStartTime: number
  dtidDuration?: number
}

export interface HandleTitleChangeProps {
  setActiveVideo: (
    activeVideo: React.SetStateAction<number | undefined>
  ) => void
}

export interface IDriveTrialContext {
  modeKey: string
  driveTrials: DriveTrial[]
  redirectData: RedirectData
  highlightMode: HighlightMode
  setHighlightMode: React.Dispatch<React.SetStateAction<HighlightMode>>
  getCurrentDriveTrial: (timestamp: number) => DriveTrial | undefined
  getDriveTrialByKey: (key: number) => DriveTrial | undefined
  getDriveTrialByParentDTID: (key: number) => DriveTrial | undefined
  getDriveTrialById: (id: number) => DriveTrial | undefined
  isConcatenation: () => boolean
  synchronizerRef: React.RefObject<SynchronizerReference>
}

export const DriveTrialContext = createContext({} as IDriveTrialContext)
//#endregion DriveTrial

//#region VideoProvider
interface IVideoContext {
  url?: string
}

export const videoContext: IVideoContext = {
  url: undefined,
}

export const VideoContext = createContext(videoContext)
//#endregion VideoProvider

//#region LoadingProvider
interface LoadingContextProps {
  text: string
  showLoadingSpinner: boolean
  isVideoLoaded: boolean
  setIsVideoLoaded: Dispatch<SetStateAction<boolean>>
  setIsBuffering: Dispatch<SetStateAction<boolean>>
  clearUnusedCanvases: (canvses: string[]) => void
  setAreCanvasesLoading: (objectType: string, value: boolean) => void
}
export const LoadingContext = createContext({} as LoadingContextProps)
//#endregion LoadingProvider

export interface TimingSamplerInterface extends TimingSampler {
  on: (event: string, func: (time: number) => void) => SamplerReference
}

export interface MediaSync {
  sampler?: TimingSamplerInterface
  timingObj?: TimingObject
  progress?: TimingProgress
  activeVideo?: number
  activeVideoId?: number
  delay?: SkewConverter
  totalDuration: number
  isPlaying: boolean
  playbackSpeed: number
  isSeeking: boolean
}

const defaultContext: MediaSync = {
  totalDuration: 0,
  playbackSpeed: 1,
  isPlaying: false,
  activeVideoId: 1,
  isSeeking: false,
}
export const MediaSyncContext = createContext(defaultContext)
