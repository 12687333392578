import { useProjectData } from '@api/table/projects'
import { JUMP_FRAME_TIME_OFFSET } from 'components/TransportControls/utils'
import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDriveTrialContext } from '../providers/DriveTrialDataProvider'
import { MediaSyncContext } from '../types/providers'

export const useJumpToTime = () => {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { redirectData } = useDriveTrialContext()
  const { sectionLength, frameRate } = useProjectData()

  const [searchParams, setSearchParams] = useSearchParams()
  const time = searchParams.get('time')
  const frameId = searchParams.get('frameId')

  useEffect(() => {
    let newTime = null

    if (frameId) {
      newTime = Number(frameId) / frameRate + JUMP_FRAME_TIME_OFFSET
    } else {
      const { sendToDetailsData } = redirectData.rows[0]
      newTime = time ? +time : 0

      if (sendToDetailsData && newTime === 0) {
        const { sectionId, detailId } = sendToDetailsData
        newTime += sectionId ? sectionId * sectionLength : 0
        newTime += detailId ? detailId * 5 : 0
      }
    }

    if (newTime && mediaSyncContext && mediaSyncContext.timingObj) {
      mediaSyncContext.isSeeking = true
      mediaSyncContext.timingObj.update({
        velocity: 0,
        position: newTime,
      })
    }
  }, [
    frameId,
    frameRate,
    mediaSyncContext,
    redirectData.rows,
    sectionLength,
    setSearchParams,
    time,
  ])
}
