import { useCallback, useEffect } from 'react'

export const useDetectZoomLevel = (low: number, high: number) => {
  const handleEvent = useCallback((e: KeyboardEvent | WheelEvent) => {
    if (!e.ctrlKey) return
    const ratio = window.devicePixelRatio
    if (e instanceof KeyboardEvent) {
      if (
        (ratio >= high && (e.key === '+' || e.key === '=')) ||
        (ratio <= low && (e.key === '-' || e.key === '_'))
      ) {
        e.preventDefault()
      }
    }
    if (e instanceof WheelEvent) {
      if ((ratio >= high && e.deltaY < 0) || (ratio <= low && e.deltaY > 0)) {
        e.preventDefault()
      }
    }
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleEvent)
    document.addEventListener('wheel', handleEvent, { passive: false })

    return () => {
      document.removeEventListener('keydown', handleEvent)
      document.removeEventListener('wheel', handleEvent)
    }
  }, [handleEvent])
}
