import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { match } from 'ts-pattern'
import { getUniqueDTParentKeys } from './redirectionData'
import { urls } from './urls'

interface ExclusionsResponse {
  [key: number]: number[][]
}

export interface Exclusion {
  startFrame: number
  endFrame: number
  reason: string
}

export interface DTExclusions {
  [key: number]: Exclusion[]
}

const getReason = (reasonKey: number) =>
  match(reasonKey)
    .with(1, () => 'Intersection')
    .with(4, () => 'Lane change')
    .with(5, () => 'Multiple')
    .otherwise(() => '')

export const useExclusionsQuery = () => {
  const { redirectData } = useDriveTrialContext()
  const uniqueDriveTrials = getUniqueDTParentKeys(redirectData)
  const location = useLocation()
  const shouldShow =
    location.pathname.toLocaleLowerCase() === '/lanes/kpi-details' ||
    location.pathname.includes('/details')

  const query = useQuery({
    queryKey: ['exclusions', uniqueDriveTrials],
    staleTime: Infinity,
    enabled: shouldShow,
    queryFn: (): Promise<DTExclusions | undefined> =>
      axios
        .post<ExclusionsResponse>(urls.exclusions, { dtids: uniqueDriveTrials })
        .then(({ data }) => {
          const dtExclusions: DTExclusions = {}
          redirectData.rows.forEach((row) => {
            const dtExc = data[row.parentDTID]
            if (dtExc) {
              dtExclusions[row.DTID] = dtExc.map((exc) => ({
                startFrame: exc[0],
                endFrame: exc[1],
                reason: getReason(exc[2]),
              }))
            }
          })
          return Object.keys(dtExclusions).length ? dtExclusions : undefined
        }),
  })

  return {
    ...query,
    isExclusionsFetched: query.isFetched,
    exclusions: query.data,
  }
}
