import { createContext, useContext } from 'react'

interface LoginContextProps {
  isAuthenticating: boolean
  setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoginContext = createContext<LoginContextProps>(
  {} as LoginContextProps
)

export const useLoginContext = () => useContext(LoginContext)
