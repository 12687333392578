import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)

export const dateToYYYYMMDD = (date: dayjs.Dayjs | undefined | null) => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DD')
}

export const stringToDayJs = (val: string | null) => {
  if (!val) return val
  return dayjs(val, 'YYYY-MM-DD')
}

export const toSeconds = (time: number) => time / 1000
export const toMilliseconds = (time: number) => time * 1000
export const toTimeString = (time: number) =>
  dayjs(toMilliseconds(time)).utc().format('HH:mm:ss')
export const toDateTimeString = (time: number) =>
  dayjs(toMilliseconds(time)).utc().format('YYYY-MM-DD HH:mm:ss')

export const unixTimestampToDate = (unixTimestamp: number): Date => {
  return dayjs.unix(unixTimestamp).toDate()
}

export const formatCustomTime = (date: Date): string =>
  `Time: ${dayjs.utc(date).format('dddd, MMMM D, YYYY [at] HH:mm:ss')}`

export const secondsToTimeString = (seconds: number) => {
  if (isNaN(seconds)) {
    return '00:00'
  }
  const time = dayjs.duration(seconds, 'seconds').asMilliseconds()

  return dayjs.utc(time).format('HH:mm:ss')
}
