import '../style.scss'
import { SignData } from '../types'

interface IProps {
  data: SignData
  isFullscreen: boolean
}
export const DataTable3D = ({ data, isFullscreen }: IProps) => {
  if (!data) {
    return null
  }

  const renderValue = (val: string | number) => {
    if (typeof val === 'string') {
      return val
    }
    return Math.round(val * 100) / 100
  }

  return (
    <table
      className={`table-3d ${isFullscreen ? 'table-3d-fullscreen' : ''}`}
      style={{
        [data.Side]: '9px',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
    >
      <thead>
        <tr>
          <th></th>
          <th>Lon</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{'LIDAR'}</td>
          <td>{renderValue(data.GTLongitudinal ?? '-')}</td>
        </tr>
        <tr>
          <td>{'ME'}</td>
          <td>{renderValue(data.MELongitudinal)}</td>
        </tr>
        <tr>
          <td>{'OTTO'}</td>
          <td>{renderValue(data.OttoLongitudinal)}</td>
        </tr>
      </tbody>
    </table>
  )
}
