import { Button } from '@mui/material'
import { useTimelineContext } from '@pages/Details/providers/TimelineContextProvider'

function ShowHidden() {
  const { groups } = useTimelineContext()

  const showHidden = () => {
    groups.forEach((group) => {
      groups.update({ id: group.id, visible: true })
    })
  }

  return (
    <Button
      data-testid='showHidden'
      sx={{
        position: 'absolute',
        top: 10,
        padding: '0 4px',
        left: 118,
        lineHeight: '1.25rem',
        textTransform: 'capitalize',
        color: 'whitesmoke',
      }}
      color='secondary'
      size='small'
      variant='contained'
      onClick={showHidden}
      disableElevation
      disableFocusRipple
      disableRipple
      disableTouchRipple
    >
      Show hidden
    </Button>
  )
}

export default ShowHidden
