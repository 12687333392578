import { forwardRef } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { PopupMenu } from '@common/components/PopupMenu/PopupMenu'
import { MenuProps } from '../../types'

interface DriveTrialDropDownMenuProps extends MenuProps {
  id: number
  data: number[]
  selectedDTID: number
  setSelectedDTID: React.Dispatch<React.SetStateAction<number>>
}

export const DriveTrialDropDownMenu = forwardRef(
  function DriveTrialDropDownMenu(
    {
      arrowPosition,
      id,
      selectedDTID,
      closeHandler,
      setSelectedDTID,
      data,
    }: DriveTrialDropDownMenuProps,
    ref
  ) {
    return (
      <PopupMenu
        closeHandler={closeHandler}
        backgroundColor='#3f4c6b'
        arrowPosition={arrowPosition}
        ref={ref}
      >
        {data.map((item) => {
          const dataTestIdLayer = `version-selector-${item}-${id}`
          const dataTestIdIcon = `version-check-icon-${item}-${id}`
          return (
            <MenuItem
              sx={{ display: 'flex' }}
              data-testid={dataTestIdLayer}
              key={item}
              onClick={() => {
                setSelectedDTID(item)
                closeHandler()
              }}
            >
              <ListItemIcon sx={{ color: 'whitesmoke' }}>
                {item === selectedDTID && (
                  <CheckIcon data-testid={dataTestIdIcon} fontSize='small' />
                )}
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </MenuItem>
          )
        })}
      </PopupMenu>
    )
  }
)
