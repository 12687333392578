import { MicrosoftLoginButton } from 'react-social-login-buttons'
import MicrosoftIcon from '../MicrosoftIcon'
import { useHandleMicrosoftLogin, useLoginContext } from '@modules/auth'

export function MicrosoftLogin() {
  const { isAuthenticating } = useLoginContext()
  const handleMicrosoftLogin = useHandleMicrosoftLogin()

  return (
    <MicrosoftLoginButton
      disabled={isAuthenticating}
      onClick={handleMicrosoftLogin}
      align='left'
      className='social-login-button microsoft'
      icon={MicrosoftIcon}
    />
  )
}
