import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SelectData } from '../types'

interface IProps {
  title: string
  options: SelectData[]
  handleSelectionChange: (selection: string[]) => void
  maxWidth?: number
  minWidth?: number
  value: string[]
}

export default function MultiSelect({
  title,
  options,
  value,
  handleSelectionChange,
  maxWidth,
  minWidth = 150,
}: IProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const eventValue = event.target.value
    // On autofill we get a stringified value.
    const newValue =
      typeof eventValue === 'string' ? eventValue.split(',') : eventValue
    handleSelectionChange(newValue)
  }

  return (
    <FormControl variant='outlined'>
      <InputLabel id='multi-select-checkbox-label'>{title}</InputLabel>
      <Select
        labelId='multi-select-checkbox-label'
        id='multi-select-checkbox'
        multiple
        value={value ?? []}
        onChange={handleChange}
        input={<OutlinedInput label='Tag' />}
        renderValue={(selected) => selected.join(', ')}
        sx={{ minWidth, maxWidth }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              checked={
                !value ? false : value.indexOf(option.value?.toString()) > -1
              }
              sx={{
                color: '#e5e5e5!important',
              }}
            />
            <ListItemText primary={option.text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
