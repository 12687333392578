/* eslint-disable react-hooks/exhaustive-deps */
import { Search } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetSuggestions } from '../../hooks/useGetSuggestions'
import { getEmail } from '@common/utils/storage'
import { clearSearchHistory, setSearchHistory } from '../../utils/storage'

type SearchFieldProps = {
  isSmall?: boolean
  placeholder?: string
}

export function SearchField({
  isSmall = false,
  placeholder = 'Type something...',
}: SearchFieldProps) {
  const textColor = '#444'

  const [searchParams, setSearchParams] = useSearchParams()

  const email = getEmail()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const nlQuery = searchParams.get('nlQuery') || ''

  const [isFocused, setIsFocused] = useState(false)
  const [isSelectingByArrows, setIsSelectingByArrows] = useState(false)
  const [typedValue, setTypedQuery] = useState(nlQuery || '')

  const suggestions: string[] = useGetSuggestions({
    email,
    isSelectingByArrows,
    value: typedValue,
  })

  const isSuggestionOpen = suggestions?.length && isFocused

  const handleListItemClick = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>, newValue: string) => {
      setTypedQuery(newValue)
      setIsFocused(false)
      setSearchParams((sp) => {
        sp.set('nlQuery', newValue)
        sp.delete('page')
        return sp
      })
    },
    [setSearchParams, setTypedQuery]
  )

  const handleTyping = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTypedQuery(e.target.value)
    setIsSelectingByArrows(false)
  }, [])

  const clearTypedValue = useCallback(() => {
    setTypedQuery('')
  }, [])

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      inputRef.current?.blur()

      setSearchParams((sp) => {
        sp.set('nlQuery', typedValue)
        sp.delete('page')
        setSearchHistory(email, typedValue)
        setIsSelectingByArrows(false)
        setIsFocused(false)
        return sp
      })
    },
    [setSearchParams, typedValue, email]
  )

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const selectedIndex = suggestions.findIndex(
        (i: string) => i === typedValue
      )

      if (!isSuggestionOpen) return

      let newValue: string | null = null

      if (event.key === 'ArrowDown') {
        if (selectedIndex === -1 || selectedIndex === suggestions.length - 1) {
          newValue = suggestions[0]
        } else {
          newValue = suggestions[selectedIndex + 1]
        }
      } else if (event.key === 'ArrowUp') {
        if (selectedIndex === -1 || selectedIndex === 0) {
          newValue = suggestions[suggestions.length - 1]
        } else {
          newValue = suggestions[selectedIndex - 1]
        }
      } else if (event.key === 'Escape') {
        inputRef.current?.blur()
      }

      if (newValue) {
        setTypedQuery(newValue)
        setIsSelectingByArrows(true)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    JSON.stringify([
      isSuggestionOpen,
      setIsSelectingByArrows,
      setTypedQuery,
      suggestions,
      typedValue,
    ]),
  ])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isSelectingByArrows) {
        inputRef.current?.setSelectionRange(-1, -1)
      }
    }, 0)

    return () => window.clearTimeout(timeout)
  }, [typedValue, isSelectingByArrows])

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: 'white',
          px: isSmall ? 2 : 4,
          py: isSmall ? 0 : 1,
          width: isSmall ? '470px' : '660px',
          my: isSmall ? '4px' : 0,
          maxWidth: '100%',
          maxHeight: '100%',
          position: 'relative',
          borderTopRightRadius: isSuggestionOpen
            ? isSmall
              ? '15px'
              : '25px'
            : '25px',
          borderTopLeftRadius: isSuggestionOpen
            ? isSmall
              ? '15px'
              : '25px'
            : '25px',
          borderBottomLeftRadius: isSuggestionOpen ? 0 : '25px',
          borderBottomRightRadius: isSuggestionOpen ? 0 : '25px',
          zIndex: 10,
        }}
      >
        <Input
          inputRef={inputRef}
          placeholder={placeholder}
          disableUnderline
          fullWidth
          value={typedValue}
          type='text'
          onChange={handleTyping}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          inputProps={{
            sx: {
              fontSize: '1rem',
              color: textColor,

              '&::placeholder': {
                fontSize: isSmall ? '0.9rem' : '1rem',
              },
            },
          }}
          startAdornment={
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          }
          endAdornment={
            typedValue ? (
              <InputAdornment position='end'>
                <IconButton size='small' onClick={clearTypedValue}>
                  <Close fontSize='small' />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />

        {isSuggestionOpen ? (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              left: 0,
              bottom: 0,
              transform: 'translateY(100%)',
              marginTop: '-1px',
            }}
          >
            <Divider
              sx={{
                marginTop: '-1px',
                height: '1px',
                background: '#aaa',
                mx: 2,
              }}
            />

            <nav aria-label='search-suggestions'>
              <List
                sx={{
                  background: 'white',
                  color: textColor,
                  borderRadius: 0,
                  borderBottomLeftRadius: isSmall ? '15px' : '25px',
                  borderBottomRightRadius: isSmall ? '15px' : '25px',
                }}
              >
                <ListItem
                  sx={{ p: 0, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    onMouseDown={() => clearSearchHistory(email)}
                    sx={{ textTransform: 'unset', mx: 2, py: 0 }}
                    variant='text'
                    size='small'
                  >
                    <Typography sx={{ fontSize: '12px' }}>
                      Clear all history
                    </Typography>
                  </Button>
                </ListItem>
                {suggestions.map((item: string) => (
                  <ListItem sx={{ p: 0 }} key={item}>
                    <ListItemButton
                      onMouseDown={(e) => handleListItemClick(e, item)}
                      selected={item === typedValue}
                      sx={{ py: '2px' }}
                    >
                      <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
                        <Search />
                      </ListItemIcon>

                      <ListItemText primary={item} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </nav>
          </Box>
        ) : null}
      </Box>
    </form>
  )
}
