import { configureStore } from '@reduxjs/toolkit'
import viewportSlice, {
  SliceState as ViewportSlice,
} from './details/viewport/viewportSlice'
import viewportDataSlice, {
  SliceState as ViewportDetailsSlice,
} from './details/viewportData/viewportDataSlice'

export interface StoreInterface {
  viewportSlice: ViewportSlice
  viewportDataSlice: ViewportDetailsSlice
}

export const store = configureStore({
  reducer: {
    viewportSlice,
    viewportDataSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
