import React from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useReportContext } from '../providers/ReportContextProvider'

const PAGE_SIZE_OPTIONS = [10, 30, 50, 100, 150]

export function PageSize() {
  const { limit, setLimit, setPage } = useReportContext()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLimitChange = (size: number) => {
    setLimit(size)
    setPage(1)
    handleClose()
  }

  return (
    <div>
      <Button
        data-testid={`paginationButton-${limit}`}
        variant='text'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          textTransform: 'capitalize',
          padding: 0,
          color: '#e5e5e5',
          mr: 1,
          '&:hover': {
            color: 'white',
          },
        }}
      >
        Show {limit}
        <ExpandLessIcon
          fontSize='small'
          sx={{
            transform: open ? 'rotate(180deg)' : '',
            transition: 'transform 250ms ease-in-out',
          }}
        />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {PAGE_SIZE_OPTIONS.map((size) => (
          <MenuItem
            data-testid={`paginationMenuItem-${size}`}
            key={size}
            onClick={() => handleLimitChange(size)}
          >
            {size}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
