export const textFieldInputPropsSx = {
  color: 'white',
  // fontSize: '12px',
  // lineHeight: '20px',
  userSelect: 'none',
  minWidth: 360,
}

export const formHelperTextSx = {
  color: 'whitesmoke',
  fontSize: '12px',
  textAlign: 'right',
  paddingTop: '4px',
  paddingLeft: '10px',
  marginBottom: '12px',
}

export const formControlSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  paddingLeft: '24px',
}

export const textFieldContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
}
