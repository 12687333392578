import { PropsWithChildren } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Accordion } from '@mui/material'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: 'inherit',
  backgroundColor: theme.palette.primary.main,
  color: 'whitesmoke',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
    color: '#e5e5e580',
  },
  '&:hover .MuiAccordionSummary-expandIconWrapper': {
    color: '#e5e5e5',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(270deg)',
    color: '#e5e5e5',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: 'whitesmoke',
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

interface IProps {
  title: string
  expanded?: string
  onChange: (panel: string) => void
}

export const CustomAccordion = ({
  title,
  expanded = '',
  children,
  onChange,
}: PropsWithChildren<IProps>) => (
  <Accordion
    expanded={expanded === `panel-${title}`}
    onChange={(_event, newExpanded: boolean) =>
      onChange(newExpanded ? `panel-${title}` : '')
    }
  >
    <AccordionSummary
      aria-controls={`panel-${title}d-content`}
      id='panel1d-header'
    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)
