import { Fragment } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import './style.scss'

interface BreadcrumbsProps {
  items: (string | number)[]
  handleClick: (name: number) => void
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { items, handleClick } = props
  const isLast = (index: number) => index === items.length - 1

  const handleBreadcrumbClick = (index: number) => {
    if (isLast(index)) return

    handleClick(index)
  }

  return (
    <div className={'breadcrumbs-wrapper'}>
      {items.map((item, index) => (
        <Fragment key={`${item}-breadcrumb`}>
          <div
            onClick={() => handleBreadcrumbClick(index)}
            style={{ cursor: !isLast(index) ? 'pointer' : 'default' }}
          >
            {item}
          </div>
          {!isLast(index) && <ArrowForwardIosIcon />}
        </Fragment>
      ))}
    </div>
  )
}

export default Breadcrumbs
