import { TimestampDataFrameMap, useDriveTrialEgoQuery } from '@api/index'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { useEffect } from 'react'
import { Scene } from 'three'
import { removeObjectsByNameFromScene } from './utils/utils'

type View3DBufferProps = {
  time?: number
  scene?: Scene
  setEgoData: React.Dispatch<
    React.SetStateAction<TimestampDataFrameMap | undefined>
  >
  setAdjacentData: React.Dispatch<
    React.SetStateAction<TimestampDataFrameMap | undefined>
  >
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  synchronizer?: ISynchronizer
  viewportId: number
}

function View3DBuffer({
  time,
  scene,
  setEgoData,
  setAdjacentData,
  setIsFetching,
  synchronizer,
  viewportId,
}: View3DBufferProps) {
  const {
    data: egoData,
    isFetched: isEgoFetched,
    isLoading,
  } = useDriveTrialEgoQuery('EGO', time)
  const { data: adjacentData, isFetched: isAdjacentFetched } =
    useDriveTrialEgoQuery('ADJACENT', time)

  useEffect(() => {
    if (isEgoFetched && isAdjacentFetched) {
      const egoLength = Object.keys(egoData || {}).length
      const adjLength = Object.keys(adjacentData || {}).length

      setEgoData(egoLength === 0 ? undefined : egoData)
      setAdjacentData(adjLength === 0 ? undefined : adjacentData)
      setIsFetching(false)

      if (isLoading) {
        synchronizer?.updateStatus(viewportId, false)
      } else {
        synchronizer?.updateStatus(viewportId, true)
      }

      if (scene) {
        removeObjectsByNameFromScene(scene, [
          'otto-left',
          'otto-right',
          'otto-adjacent-left',
          'otto-adjacent-right',
        ])
      }
    }
  }, [
    adjacentData,
    egoData,
    isAdjacentFetched,
    isEgoFetched,
    scene,
    setAdjacentData,
    setEgoData,
    setIsFetching,
    synchronizer,
    viewportId,
    isLoading,
  ])

  return <></>
}

export default View3DBuffer
