import { REASON_ENUM, useCreateLogoutInfoMutation } from '@api/logout'
import { enUS } from '@common/constants/messages'
import { useClosableSnackbar } from '@common/hooks/useClosableSnackbar'
import { getEmail } from '@common/utils/storage'
import { useCallback } from 'react'
import { getStorageKey } from '@pages/NlGallery/utils/storage'
import { useAuthContext } from '../context/AuthContext'

const clearLocalStoragePreserveViewportsState = () => {
  const historyStorageKey = getStorageKey(getEmail()) || ''
  const viewports = window.localStorage.getItem('viewports') || ''
  const detailsLayout = window.localStorage.getItem('detailsLayout') || ''
  const searchHistory = window.localStorage.getItem(historyStorageKey)
  window.localStorage.clear()

  if (historyStorageKey && searchHistory) {
    window.localStorage.setItem(historyStorageKey, searchHistory)
  }

  window.localStorage.setItem('viewports', viewports)
  window.localStorage.setItem('detailsLayout', detailsLayout)
  window.sessionStorage.clear()
}

export const useTokenExpiredEndSession = () => {
  const wrappedEnqueue = useClosableSnackbar()
  const logout = useLogout(REASON_ENUM.TOKEN_EXPIRED)

  return useCallback(() => {
    logout()
    wrappedEnqueue('info', enUS.USER_ON_IDLE)
    // notify all open tabs and logout
  }, [logout, wrappedEnqueue])
}

export const useOnIdle = () => {
  const { isAuthenticated } = useAuthContext()
  const wrappedEnqueue = useClosableSnackbar()
  const logout = useLogout(REASON_ENUM.TIMEOUT_LOGOUT)

  return useCallback(async () => {
    // only fire these functions when user is logged in
    if (isAuthenticated) {
      logout()
      wrappedEnqueue('info', enUS.USER_ON_IDLE)
    }
  }, [isAuthenticated, logout, wrappedEnqueue])
}

export const useLogout = (reason: number) => {
  const { setAuthContext } = useAuthContext()
  const { createLogoutInfoMutation } = useCreateLogoutInfoMutation()

  return useCallback(async () => {
    createLogoutInfoMutation({ email: getEmail(), reason: reason })
    // clear auth context
    setAuthContext({})
    // clear local storage state
    clearLocalStoragePreserveViewportsState()
    // clear redux state
  }, [setAuthContext, useCreateLogoutInfoMutation])
}
