import { Property } from 'csstype'
import './style.scss'

interface LoaderProps {
  scale?: Scale
  color?: Property.Color
  center?: boolean
  text?: string
}

type Scale = 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5

export function Loader({
  scale = 1,
  color = 'whitesmoke',
  center,
  text,
}: LoaderProps) {
  const borderColor = `${color} transparent ${color} transparent`

  return (
    <div className={`loader ${center ? 'center' : ''}`}>
      <div
        className='loader-inner lines'
        style={{ transform: `scale(${scale}, ${scale})` }}
      >
        <div style={{ border: `3px solid`, borderColor }}></div>
        <div style={{ borderColor }}></div>
      </div>
      {text ? (
        <p
          style={{
            color: color,
            marginTop: scale * 20,
            fontSize: `${scale * 16}px`,
            fontWeight: 300,
            textAlign: 'center',
          }}
        >
          {text}...
        </p>
      ) : null}
    </div>
  )
}
