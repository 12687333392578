import { SprItem, useSprGalleryQuery } from '@api/sprGallery/spr-gallery'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useHandleImageClick = () => {
  const { frameId, dtid, data } = useSprGalleryQuery()
  const [, setSearchParams] = useSearchParams()

  const isDelegateView = !frameId || !dtid

  return useCallback(
    (item: SprItem) => {
      const selectedIndex = data.findIndex(
        (i) => i.dtid === item.dtid && i.frameId === item.frameId
      )

      if (!item.isDelegate) {
        setSearchParams((sp) => {
          sp.set('selectedIndex', String(selectedIndex))
          return sp
        })
        return
      } else {
        setSearchParams((sp) => {
          sp.set('dtid', String(item.dtid))
          sp.set('frameId', String(item.frameId))

          const page = sp.get('page')

          if (page && isDelegateView) {
            sp.set('prevDelegatePage', page)
          }

          sp.set('page', String(1))
          return sp
        })
      }
    },
    [data, isDelegateView, setSearchParams]
  )
}
