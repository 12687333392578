import { useLoginMutation } from '@api/index'
import {
  AuthenticationResult,
  BrowserAuthError,
  BrowserCacheLocation,
  PublicClientApplication,
} from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { enUS } from '@common/constants/messages'
import { closeSnackbar, useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useLoginContext } from './useLoginContext'
import { useAuthContext } from '../context/AuthContext'
import { setAuthStorage } from '../utils/storage'

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_APP_ID || '',
    authority: process.env.REACT_APP_MS_APP_AUTHORITY || '',
    redirectUri: process.env.REACT_APP_MS_REDIRECT_URI || '',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
}

export const msalClient = new PublicClientApplication(msalConfig)

export const useOnMicrosoftLoginSuccess = () => {
  const { setAuthContext } = useAuthContext()
  const { setIsAuthenticating } = useLoginContext()
  const { enqueueSnackbar } = useSnackbar()
  const { login } = useLoginMutation()

  return useCallback(
    async (response: AuthenticationResult) => {
      try {
        setIsAuthenticating(true)
        closeSnackbar()
        if (response.expiresOn && response.account?.username) {
          const { data } = await login({
            microsoftToken: response.accessToken,
          })

          const email = response.account.username
          const token = data.tokens.accessToken
          const source = 'ms'

          setAuthStorage(data, email, source)
          setAuthContext({ token, source, email })
        } else {
          throw new Error()
        }
      } catch (e) {
        enqueueSnackbar(enUS.ERR_LOGIN, {
          variant: 'info',
        })
      } finally {
        setIsAuthenticating(false)
      }
    },
    [enqueueSnackbar, login, setAuthContext, setIsAuthenticating]
  )
}

export const useHandleMicrosoftLogin = () => {
  const { instance } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const onMicrosoftLoginSuccess = useOnMicrosoftLoginSuccess()

  return useCallback(async () => {
    try {
      const response = await instance.loginPopup({
        scopes: ['User.Read'],
      })

      onMicrosoftLoginSuccess(response)
    } catch (e) {
      if (e instanceof BrowserAuthError && e.errorCode !== 'user_cancelled') {
        enqueueSnackbar(enUS.ERR_LOGIN, {
          variant: 'info',
        })
      }
    }
  }, [enqueueSnackbar, instance, onMicrosoftLoginSuccess])
}
