import { TrackDisplayMode } from '@pages/Details/providers/TimelineContextProvider'
import { TimelineWindow } from 'otto-vis-timeline'
import drawLineChart from '../Charts/LineChart'

export interface ChartData {
  start: number
  end: number
  value: number
  label: number
}

export interface TimelineRangeChangeEvent {
  start: Date
  end: Date
  byUser: boolean
}

export const createChartElement = (
  window: TimelineWindow,
  data: (ChartData | null)[],
  color: string,
  yScaleA: number,
  trackWidth: number,
  id: string,
  display: TrackDisplayMode
) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.id = id
  svg.style.display = 'block'
  svg.style.width = `${trackWidth}px`
  svg.style.height = `${yScaleA}px`
  drawLineChart(window, svg, data, color, yScaleA, display)
  return svg
}
