import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { TextField } from '@mui/material'

interface IProps {
  value?: string
  placeholder?: string
  onClick?: () => void
}

export function DatePickerCustomInput({ value, onClick, placeholder }: IProps) {
  return (
    <TextField
      onClick={onClick}
      value={value}
      autoComplete='off'
      InputProps={{
        readOnly: true,
        endAdornment: (
          <CalendarTodayIcon fontSize='small' sx={{ color: 'whitesmoke' }} />
        ),
      }}
      sx={{
        '& input': {
          color: 'whitesmoke',
          paddingTop: '18px',
          paddingBottom: '15px',
        },
      }}
      variant='filled'
      placeholder={placeholder}
    />
  )
}
