import {
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { greenColor, whiteColor } from './colors'
import { NFSPosition } from './constants'

export const addObjectToScene = (
  path: string,
  objName: string,
  objLoader: OBJLoader,
  scene: Scene
) => {
  const commonMaterial = new MeshBasicMaterial({
    color: greenColor,
    wireframe: true,
  })

  objLoader.load(path, (object: Object3D) => {
    object.scale.set(0.018 / 20, 0.018 / 20, 0.018 / 20)
    object.rotateX(MathUtils.degToRad(-90))
    object.position.set(0, 0, 0)
    ;(object.children[0] as Mesh).material = commonMaterial
    object.name = objName
    scene && scene.add(object)
  })
}

export const initializeEngineAndScene = (
  canvasRef: HTMLCanvasElement,
  canvasContainerSize: [number, number],
  labelRenderer: CSS2DRenderer
) => {
  const objLoader = new OBJLoader()
  const glEngine = new WebGLRenderer({
    canvas: canvasRef,
    antialias: true,
    alpha: true,
  })
  glEngine.setClearColor(whiteColor, 0)
  glEngine.setSize(canvasContainerSize[1], canvasContainerSize[0])

  const scene = new Scene()

  const camera = new PerspectiveCamera(
    50,
    canvasContainerSize[1] / canvasContainerSize[0],
    0.1,
    10000
  )

  const { x, y, z } = NFSPosition
  camera.position.set(x, y, z)

  scene.add(camera)

  labelRenderer.setSize(canvasContainerSize[1], canvasContainerSize[0])
  labelRenderer.domElement.style.position = 'absolute'
  labelRenderer.domElement.style.top = '0px'
  labelRenderer.domElement.style.pointerEvents = 'none'

  addObjectToScene('/car.obj', 'el_vehicle', objLoader, scene)

  return { glEngine, scene, camera }
}

export const setupOrbitControls = (
  camera: PerspectiveCamera,
  engine: WebGLRenderer
) => {
  const ctrl = new OrbitControls(camera, engine.domElement)
  ctrl.target = new Vector3(0, 2, 0)
  ctrl.maxPolarAngle = Math.PI / 2
  ctrl.minDistance = 4
  ctrl.maxDistance = 100
  ctrl.enableDamping = true
  ctrl.dampingFactor = 0.1
  ctrl.enabled = true
  ctrl.enablePan = false
  ctrl.enableZoom = false

  return ctrl
}
