import { useSprGalleryQuery } from '@api/sprGallery/spr-gallery'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useGoBackToDelegates = () => {
  const { frameId, dtid } = useSprGalleryQuery()
  const [, setSearchParams] = useSearchParams()

  const isDelegateView = !frameId || !dtid

  return useCallback(() => {
    if (isDelegateView) {
      return
    }

    setSearchParams((sp) => {
      sp.delete('frameId')
      sp.delete('dtid')

      const prevDelegatePage = sp.get('prevDelegatePage')

      if (prevDelegatePage) {
        sp.set('page', prevDelegatePage)
      }

      sp.delete('prevDelegatePage')
      return sp
    })
  }, [isDelegateView, setSearchParams])
}
