import { LoginRequest, useLoginMutation } from '@api/index'
import { closeSnackbar, useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useLoginContext } from './useLoginContext'
import { useAuthContext } from '../context/AuthContext'
import { setAuthStorage } from '../utils/storage'

export const useOttoLogin = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { setAuthContext } = useAuthContext()
  const { setIsAuthenticating } = useLoginContext()
  const { login } = useLoginMutation()

  return useCallback(
    async (loginData: LoginRequest) => {
      try {
        setIsAuthenticating(true)
        closeSnackbar()
        const { data } = await login(loginData)
        const token = data.tokens.accessToken
        const source = 'otto'
        const email = loginData.email!

        setAuthStorage(data, email, source)

        setAuthContext({
          token,
          source,
          email,
        })
      } catch (e) {
        enqueueSnackbar('Email or password is incorrect. Please try again.', {
          variant: 'error',
        })
      } finally {
        setIsAuthenticating(false)
      }
    },
    [enqueueSnackbar, login, setAuthContext, setIsAuthenticating]
  )
}
