import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react'
import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { enUS } from '@common/constants/messages'
import { Select } from '@common/components/Inputs/Select/Select'
import { SelectData } from '@common/components/Inputs/types'
import ModalDialog from '@common/components/ModalDialog/ModalDialog'
import { Panel } from '@common/components/Panels/Panel/Panel'
import { GroupAddPosition, NewGroupData } from '../types'

export interface TimelineMenuController {
  open: (relativeId: number | undefined) => void
}

interface IProps {
  onOK: (data: NewGroupData) => void
  relativeGroupId: number | undefined
}

export const TimelineGroupMenu = forwardRef<
  TimelineMenuController | undefined,
  IProps
>(function TimelineGroupMenu(props, ref) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [position, setPosition] = useState<GroupAddPosition>(
    GroupAddPosition.UNDER
  )

  const selections: SelectData[] = Array.from(Array(10).keys()).map((x) => ({
    text: `Option ${x + 1}`,
    value: x + 1,
  }))

  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
    }
  })

  const doCleanup = () => {
    setName('')
    setType('')
    setPosition(GroupAddPosition.UNDER)
  }

  const commonData = (
    <>
      <FormLabel id='add-position'>{enUS.GROUP_DATA}</FormLabel>
      <Stack direction='row' spacing={4}>
        <TextField
          label={enUS.GROUP_NAME}
          value={name}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setName(e.currentTarget.value)
          }}
          sx={{
            color: 'white',
            '.MuiInputBase-root > input': {
              color: 'white',
            },
          }}
        />
        <Select
          options={selections}
          title={enUS.GROUP_TYPE}
          onChange={(e: SelectChangeEvent) => {
            setType(e.target.value)
          }}
          value={type}
          variant='filled'
        />
      </Stack>
    </>
  )

  const positionSection =
    props.relativeGroupId === undefined ? null : (
      <Stack spacing={0}>
        <FormLabel id='add-position'>{enUS.GROUP_POSITION}</FormLabel>
        <RadioGroup
          defaultValue={GroupAddPosition.ABOVE}
          aria-labelledby='add-position'
          value={position}
          onChange={(e) => {
            setPosition(+e.currentTarget.value)
          }}
          sx={{
            color: 'white',
          }}
        >
          <FormControlLabel
            value={GroupAddPosition.ABOVE}
            control={
              <Radio
                disabled={props.relativeGroupId === undefined}
                sx={{
                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },
                }}
              />
            }
            label={enUS.GROUP_ABOVE}
          />
          <FormControlLabel
            value={GroupAddPosition.UNDER}
            control={
              <Radio
                disabled={props.relativeGroupId === undefined}
                sx={{
                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },
                }}
              />
            }
            label={enUS.GROUP_UNDER}
          />
        </RadioGroup>
      </Stack>
    )

  const buttons = (
    <Stack direction='row' spacing={4} sx={{ justifyContent: 'right' }}>
      <Button
        onClick={() => {
          props.onOK({
            order: 0,
            name,
            value: name, //TODO:??
            type,
            position: position ?? GroupAddPosition.UNDER,
            relativeToGroup: props.relativeGroupId,
          })
          setOpen(false)
          doCleanup()
        }}
        sx={{
          background: 'green',
          color: 'white',
        }}
        disabled={!name || !type}
      >
        {enUS.OK}
      </Button>
      <Button
        onClick={() => {
          setOpen(false)
          doCleanup()
        }}
        sx={{
          background: '#d32f2f',
          color: 'white',
        }}
      >
        {enUS.CANCEL}
      </Button>
    </Stack>
  )

  const dialog = (
    <Panel>
      <Stack spacing={3}>
        {commonData}
        {positionSection}
        {buttons}
      </Stack>
    </Panel>
  )

  return (
    <ModalDialog
      title={enUS.CREATE_TIMELINE_GROUP}
      onClose={() => setOpen(false)}
      open={open}
    >
      {dialog}
    </ModalDialog>
  )
})
