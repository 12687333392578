import { Styles } from 'jspdf-autotable'

export const headerStyling: Partial<Styles> = {
  halign: 'center',
  valign: 'middle',
  fillColor: '#7a86a1',
  lineWidth: 0.01,
  lineColor: 'whitesmoke',
}

export const bodyStyling: Partial<Styles> = {
  halign: 'center',
  valign: 'middle',
  fillColor: 'white',
}
