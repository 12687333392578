import { useKpiTableQuery, useTableQuery } from '@api/index'
import { useProjectData } from '@api/table/projects'
import { enUS } from '@common/constants/messages'
import { Routes, TableRouteParams } from '@common/constants/paths'
import { Loader } from '@common/components/Loader/Loader'
import { capitalize } from 'lodash'
import { useEffect } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { StyledAlert } from './components/StyledComponentsTable'
import TableCreator from './components/TableCreator'
import {
  INIT_BREADCRUMBS,
  INIT_FILTER_STATE,
  INIT_QUERY_BUILDER_STATE,
} from './initialStates'
import { useReportContext } from './providers/ReportContextProvider'
import { shouldSkipElement } from './utils'

function ReportTable() {
  const { reportType } = useParams<TableRouteParams>()
  const {
    setSearchColumns,
    setBreadcrumbs,
    setSelectedRowIds,
    headers,
    isLoadingHeaders,
    breadcrumbs,
    queryBuilderRef,
    preserveState,
    setPreserveState,
  } = useReportContext()
  const { projectID } = useProjectData()

  const location = useLocation()
  const isKpi = location.pathname.includes('kpi')
  const {
    data: tableData,
    isFetching: isFetchingData,
    isError: isErrorData,
    refetch: refreshTable,
  } = useTableQuery()

  const {
    data: KPITableData,
    isFetching: isFetchingKPI,
    isError: isErrorKPI,
  } = useKpiTableQuery()

  useEffect(() => {
    setSelectedRowIds(new Set())
    setBreadcrumbs(INIT_BREADCRUMBS)
  }, [reportType])

  useEffect(() => {
    if (!isKpi) refreshTable()
    queryBuilderRef.current?.resetQuery()
    const resetMetaFilters =
      preserveState[reportType!].searchType === 'sql'
        ? {
            filterState: INIT_FILTER_STATE,
            filterToSql: {},
          }
        : {}

    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        queryBuilderState: INIT_QUERY_BUILDER_STATE,
        searchType: 'filter',
        ...resetMetaFilters,
      },
    })
    setSelectedRowIds(new Set())
  }, [breadcrumbs])

  useEffect(() => {
    if (tableData?.data.searchColumns) {
      setSearchColumns(tableData.data.searchColumns)
    }
    if (KPITableData?.data.searchColumns) {
      setSearchColumns(KPITableData?.data.searchColumns)
    }
  }, [setSearchColumns, tableData, KPITableData])

  if (isFetchingData || isFetchingKPI || isLoadingHeaders) {
    return <Loader center text={`Loading ${capitalize(reportType)}`} />
  }

  if (shouldSkipElement(projectID, location.pathname)) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }

  if (isErrorData || isErrorKPI || !headers) {
    return (
      <StyledAlert variant='standard' severity='info'>
        {enUS.NO_DATA_TO_DISPLAY}
      </StyledAlert>
    )
  }

  return (
    <TableCreator
      tableData={tableData || KPITableData}
      reportType={reportType!}
    />
  )
}

export default ReportTable
