import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Input from '@mui/material/Input'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import { ThemeProvider } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import {
  QueryBuilderMaterial,
  RQBMaterialComponents,
} from '@react-querybuilder/material'
import {
  Field,
  QueryBuilder as ReactQueryBuilder,
  RuleGroupType,
} from 'react-querybuilder'
import theme from 'theme/theme'
import 'react-querybuilder/dist/query-builder.scss'
import './index.scss'

const muiComponents = {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextareaAutosize,
  Button,
  DragIndicator: DragIndicatorIcon,
} as RQBMaterialComponents

interface IProps {
  query: RuleGroupType
  setQuery: (val: RuleGroupType) => void
  fields: Field[]
}

export const QueryBuilder = ({ query, setQuery, fields }: IProps) => {
  const filteredFields = fields.filter((x) => x.name !== 'Version')

  return (
    <ThemeProvider theme={theme}>
      <QueryBuilderMaterial muiComponents={muiComponents}>
        <ReactQueryBuilder
          fields={filteredFields}
          query={query}
          onQueryChange={(q) => setQuery(q)}
        />
      </QueryBuilderMaterial>
    </ThemeProvider>
  )
}
