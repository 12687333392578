import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { getBaseAuthData } from './base'
import { RedirectData, getUniqueDTParentKeys } from './redirectionData'
import { urls } from './urls'

interface GpsData {
  dtid: number
  longitude: number
  latitude: number
  speed: number
  yaw: number
}

interface MapRoutesResponse {
  map: GpsData[]
}

const getMapRoutesParams = (dtidArray: string) => ({
  dtidArray,
  ...getBaseAuthData(),
})

const getMapPayload = (redirectData: RedirectData | undefined) => {
  const data = getUniqueDTParentKeys(redirectData)

  return data
}

export const useMapQuery = () => {
  const { redirectData } = useDriveTrialContext()
  const driveTrials = getMapPayload(redirectData)
  const mapPayload = (driveTrials || []).toString()

  return useQuery({
    queryKey: ['mapRoutes', mapPayload],
    staleTime: Infinity,
    queryFn: (): Promise<MapRoutesResponse> =>
      axios
        .get<MapRoutesResponse>(urls.mapRoutes, {
          params: getMapRoutesParams(mapPayload),
          withCredentials: true,
        })
        .then(({ data }) => {
          const dtids = redirectData.rows
          const gpsData = dtids
            .map((dt) =>
              data.map
                .filter((gps) => gps.dtid === dt.parentDTID)
                .map((x) => ({ ...x, dtid: dt.DTID }))
            )
            .flat()

          return { map: gpsData }
        }),
  })
}
