import { ReactElement, createContext, useState } from 'react'
import { PerspectiveCamera } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export interface TopViewContextData {
  cameras: Record<number, PerspectiveCamera | undefined>
  setCamera: (cam: PerspectiveCamera, id: number) => void
  controls: Record<number, OrbitControls | undefined>
  setControls: (ctrl: OrbitControls, id: number) => void
  isOrbitControlsLocked: boolean
  setIsOrbitControlsLocked: (flag: boolean) => void
}

interface IProps {
  children: ReactElement | ReactElement[]
}

export const TopViewContext = createContext<TopViewContextData>({
  cameras: {},
  controls: {},
  setCamera: () => {},
  setControls: () => {},
  isOrbitControlsLocked: false,
  setIsOrbitControlsLocked: () => {},
})

export const TopViewProvider = ({ children }: IProps) => {
  const [cameras, setCameras] =
    useState<Record<number, PerspectiveCamera | undefined>>()
  const [controls, setControls] =
    useState<Record<number, OrbitControls | undefined>>()
  const [isOrbitControlsLocked, setIsOrbitControlsLocked] = useState(false)

  return (
    <TopViewContext.Provider
      value={{
        cameras: cameras ?? {},
        controls: controls ?? {},
        setCamera: (cam: PerspectiveCamera, id: number) => {
          const newCams = {
            ...cameras,
            [id]: cam,
          }

          setCameras(newCams)
        },
        setControls: (ctrl: OrbitControls, id: number) => {
          const newControls = {
            ...controls,
            [id]: ctrl,
          }

          setControls(newControls)
        },
        isOrbitControlsLocked,
        setIsOrbitControlsLocked,
      }}
    >
      {children}
    </TopViewContext.Provider>
  )
}
