import { LineType, ObjectType, SourceType } from '@api/objects'
import { useProjectData } from '@api/table/projects'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { ObjectColor, TimedData } from '@modules/videoViewport'
import { useDriveTrialContext } from 'pages/Details/providers/DriveTrialDataProvider'
import {
  LoadingContext,
  MediaSyncContext,
} from '@pages/Details/types/providers'
import { useCallback, useContext, useEffect, useState } from 'react'
import { InitializationMessageWorker, WorkerEvent } from '../../worker/messages'
import { CanvasBuffer } from '../CanvasBuffer'
import './styles.scss'

interface CanvasProps {
  videoId: number
  size: {
    width: number
    height: number
  }
  DTID: number
  parentDTID: number
  dataTestId: string
  sourceType: SourceType
  objectType: ObjectType
  colors: ObjectColor[]
  camera: number
  lineType?: LineType
  synchronizer?: ISynchronizer
  viewportId: number
  isFullscreen: boolean
}

export function Canvas({
  videoId,
  size,
  DTID,
  parentDTID,
  dataTestId,
  sourceType,
  objectType,
  colors,
  camera,
  lineType,
  synchronizer,
  viewportId,
  isFullscreen,
}: CanvasProps) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { setAreCanvasesLoading } = useContext(LoadingContext)
  const { getCurrentDriveTrial, highlightMode } = useDriveTrialContext()
  const [isPending, setIsPending] = useState(false)
  const [isPendingStream, setIsPendingStream] = useState(false)
  const [objectWorker, setWorker] = useState<Worker | null>(null)
  const { frameRate } = useProjectData()

  const isVideoActive = () => mediaSyncContext.activeVideoId === videoId

  const draw = useCallback(
    (time: number) => {
      const currentDriveTrial = getCurrentDriveTrial(
        mediaSyncContext.timingObj?.pos
      )
      if (!currentDriveTrial) return

      const { previousDuration, startTime, originalStartTime } =
        currentDriveTrial
      const frameOffset = previousDuration * frameRate
      const currentTime =
        time +
        ((originalStartTime || originalStartTime === 0) &&
        highlightMode.id !== -1
          ? originalStartTime - startTime
          : 0)
      const currentFrame = Math.floor(currentTime * +frameRate)

      const wm: WorkerEvent = {
        type: 'timeChange',
        data: currentFrame - frameOffset,
      }

      objectWorker?.postMessage(wm)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectWorker, frameRate, objectType, highlightMode]
  )

  const triggerDraw = useCallback(() => {
    if (!mediaSyncContext.isSeeking) {
      const time = mediaSyncContext.timingObj?.pos
      draw(time)
    }
  }, [draw, mediaSyncContext.isSeeking, mediaSyncContext.timingObj])

  const setupWebWorker = useCallback(
    (node: HTMLCanvasElement | null) => {
      if (node !== null && colors?.length > 0) {
        try {
          const canvas = node.transferControlToOffscreen()

          const worker = new Worker(
            new URL(`../../worker/index.ts`, import.meta.url)
          )

          const initMessage: InitializationMessageWorker = {
            canvas,
            colors,
            sourceType,
            objectType,
            isFullscreen,
          }

          const event: WorkerEvent = {
            data: { ...initMessage },
            type: 'initialize',
          }

          worker.postMessage(event, [canvas])
          setWorker(worker)
        } catch (e) {
          // fails silently
        }
      }
    },
    // If something of these dependencies change, we initial Web worker
    [colors, objectType, sourceType]
  )

  useEffect(() => {
    const wf: WorkerEvent = {
      type: 'fullscreen',
      data: isFullscreen,
    }

    objectWorker?.postMessage(wf)
    triggerDraw()
  }, [isFullscreen])

  useEffect(() => {
    if (objectWorker && synchronizer) {
      synchronizer?.addDrawCallback(draw, viewportId, videoId)
    }
    return () => {
      if (objectWorker) {
        objectWorker?.terminate()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectWorker, synchronizer])

  useEffect(() => {
    const key = `${objectType}-${sourceType}`
    synchronizer?.updateCanvasStatus(viewportId, !isPendingStream, key)
    setAreCanvasesLoading(key, isPendingStream)
  }, [isPendingStream])

  useEffect(() => {
    const key = `${objectType}-${sourceType}`
    synchronizer?.updateCanvasStatus(viewportId, !isPending, key)
    setAreCanvasesLoading(key, isPending)
  }, [isPending])

  useEffect(() => {
    const event: WorkerEvent = {
      data: size,
      type: 'reconfigure',
    }

    objectWorker?.postMessage(event)
    triggerDraw()
  }, [size])

  const handleBufferData = useCallback(
    (data: TimedData<unknown>) => {
      const event: WorkerEvent = {
        type: 'populate',
        data: {
          objectData: data,
        },
      }

      objectWorker?.postMessage(event)
      triggerDraw()
    },
    [objectWorker]
  )

  const handleFetching = (isFetching: boolean) => {
    const key = `${objectType}-${sourceType}`
    if (isVideoActive()) {
      synchronizer?.updateCanvasStatus(viewportId, !isFetching, key)
    }

    setAreCanvasesLoading(key, isFetching)
  }

  const handleDraw = (time: number) => {
    if (
      !synchronizer?.anyLoadingViewports() &&
      isVideoActive() &&
      mediaSyncContext.isPlaying &&
      !mediaSyncContext.isSeeking
    ) {
      draw(time)
    }
  }

  return size.width === 0 || size.height === 0 ? null : (
    <div
      className={`worker-canvas-container ${
        objectType.includes('Label') ? 'label' : ''
      }`}
    >
      {objectWorker && (
        <CanvasBuffer
          id={videoId}
          dtid={sourceType === 'me' ? DTID : parentDTID}
          objectType={objectType}
          sourceType={sourceType}
          camera={camera}
          lineType={lineType}
          timeChangeCallback={handleDraw}
          handleFetching={handleFetching}
          fetchCallback={handleBufferData}
          setIsPending={setIsPending}
          setIsPendingStream={setIsPendingStream}
        />
      )}
      <canvas
        height={size.height}
        width={size.width}
        ref={setupWebWorker}
        data-testid={dataTestId}
      />
    </div>
  )
}
