import { useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { SidebarToolbox } from '@modules/sidebarToolbox/containers/SidebarToolbox'

const ReportContextLayout = () => {
  const { pathname } = useLocation()
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const onDrawerToggle = (isOpen: boolean) => {
    const style = tableContainerRef.current?.style
    if (style) {
      style.transition = 'margin-left 0.21s ease-in-out'
      style.marginLeft = isOpen ? '264px' : 'unset'
    }
  }

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <SidebarToolbox onDrawerToggle={onDrawerToggle} variant='persistent' />
      <div
        ref={tableContainerRef}
        style={{
          padding: pathname.includes('dashboards')
            ? '2px 0 0 66px'
            : '16px 0 0 66px',
          height: pathname.includes('dashboards') ? '100.7%' : '',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Outlet />
      </div>
    </div>
  )
}

export default ReportContextLayout
