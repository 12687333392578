import { FilterData, FilterObject, FiltersBySqlTable } from 'models/table'
import {
  Environment,
  FilterObjects,
  FiltersState,
  General,
  ObjectKeyType,
  ReportType,
} from '@pages/Report/types'
import { inPercentages } from '@modules/sidebarToolbox/components/ObjectSelectors'

const createDateFilterObject = (
  objectsFilterState: FilterObjects,
  generalFilterState: General,
  environmentFilterState: Environment
) => {
  const filterObject = {} as FilterObject
  const { dateRange, processedDateRange } = generalFilterState
  const { country } = environmentFilterState

  if (dateRange[0]) filterObject.StartDate = dateRange[0]
  if (dateRange[1]) filterObject.EndDate = dateRange[1]
  if (processedDateRange[0])
    filterObject.ProcessedStartDate = processedDateRange[0]
  if (processedDateRange[1])
    filterObject.ProcessedEndDate = processedDateRange[1]
  if (country) filterObject.Country = country

  Object.keys(objectsFilterState).forEach((x) => {
    const filter = objectsFilterState[x as ObjectKeyType]
    if (filter) {
      if (x !== 'camera' && inPercentages.includes(x)) {
        filterObject[x] = +filter / 100
      } else {
        filterObject[x] = filter
      }
    }
  })

  return filterObject
}

export const formatFilterPayload = (filterState: FiltersState) => {
  const { environmentFilters, objectFilters, generalFilters } = filterState
  const filters: Array<string | FilterObject> = []

  const filterObject = createDateFilterObject(
    objectFilters,
    generalFilters,
    environmentFilters
  )

  environmentFilters?.weather && filters.push(environmentFilters?.weather)
  environmentFilters?.roadType && filters.push(environmentFilters?.roadType)
  environmentFilters?.timeOfDay && filters.push(environmentFilters?.timeOfDay)

  if (Object.keys(filterObject).length !== 0) filters.push(filterObject)

  return filters
}

export const createFilterPayload = (
  filterState: FiltersState,
  reportType: ReportType,
  pageNumber: number,
  limit = 50,
  skip: number = limit * (pageNumber - 1)
) => {
  const payload = {
    filters: [],
  } as FiltersBySqlTable

  payload.filters = formatFilterPayload(filterState)

  const filterData: FilterData = {
    filters: payload.filters.length === 0 ? {} : payload,
    limit,
    skip,
    reportType: reportType,
    fetchType: 'filter',
  }

  return filterData
}
