import { PropsWithChildren } from 'react'

const MediaWrapper = ({ children }: PropsWithChildren) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      paddingRight: '40px',
    }}
  >
    {children}
  </div>
)

export default MediaWrapper
