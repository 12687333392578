import React from 'react'
import { Avatar, IconButton, Tooltip } from '@mui/material'
import { enUS } from '@common/constants/messages'
import './style.scss'

interface IProps {
  email?: string | null
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const ProfileLogo = ({ email, onClick }: IProps) => (
  <Tooltip title={enUS.ACCOUNT_SETTINGS} arrow placement='left'>
    <IconButton
      data-testid='AccountSettings'
      onClick={(e) => {
        onClick(e)
      }}
    >
      <Avatar className='profile-logo'>
        {email?.toUpperCase().substring(0, 1) ?? 'O'}
      </Avatar>
    </IconButton>
  </Tooltip>
)
