import { RefObject } from 'react'
import { LngLat, LngLatBounds } from 'mapbox-gl'
import { MapLayerMouseEvent, MapRef } from 'react-map-gl'
import {
  CoodinateTimes,
  DriveCoords,
  GpsMap,
  GpsMapData,
  GpsPoint,
  MarkerSourceRef,
  StartEndMarker,
} from './types'

const OFFSET_DISTANCE_LAT_LNG = 0.0032574
const DEFAULT_FLY_TO_SPEED = 5

export const getNearestPoint = (
  e: MapLayerMouseEvent,
  gpsCoordinates: GpsPoint[],
  gpsHighCoordinates: GpsPoint[]
) => {
  const distance = (p: GpsPoint) => {
    const [longitude, latitude] = p
    return Math.sqrt(
      Math.pow(point.lng - longitude, 2) + Math.pow(point.lat - latitude, 2)
    )
  }
  const route =
    gpsHighCoordinates.length > 0 ? gpsHighCoordinates : gpsCoordinates

  const point = { lng: e.lngLat.lng, lat: e.lngLat.lat },
    closest = route
      .filter((p) => p !== undefined)
      .reduce((a, b) => (distance(a) < distance(b) ? a : b))

  const offsetDistance = Math.sqrt(
    Math.pow(e.lngLat.lat - closest[1], 2) +
      Math.pow(e.lngLat.lng - closest[0], 2)
  )

  if (offsetDistance <= OFFSET_DISTANCE_LAT_LNG) return closest
  else return [null, null]
}

export const getNearestPointForDragging = (
  e: MapLayerMouseEvent,
  gpsCoordinates: GpsPoint[],
  gpsHighCoordinates: GpsPoint[]
) => {
  const distance = (p: GpsPoint) => {
    const [longitude, latitude] = p
    return Math.sqrt(
      Math.pow(point.lng - longitude, 2) + Math.pow(point.lat - latitude, 2)
    )
  }
  const route =
    gpsHighCoordinates.length > 0 ? gpsHighCoordinates : gpsCoordinates

  const point = { lng: e.lngLat.lng, lat: e.lngLat.lat },
    closest = route
      .filter((p) => p !== undefined)
      .reduce((a, b) => (distance(a) < distance(b) ? a : b))

  return closest
}

export const generateHighlightCords = (
  cords: GpsPoint[],
  highCords: CoodinateTimes[]
) => {
  const highlightCords: GpsPoint[][] = []
  const highMapData: GpsMapData = []
  const highMap: GpsMap = {}
  highCords.forEach((x) => {
    if (x.originalStart <= x.originalEnd) {
      highlightCords.push(cords.slice(x.originalStart, x.originalEnd + 1))

      let counter = x.start
      for (let i = x.originalStart; i <= x.originalEnd + 1; i++) {
        highMap[counter] = cords[i]
        highMapData[counter] = {
          cords: cords[i],
          dtid: x.dtid,
        }
        counter++
      }
    }
  })

  return { highlightCords, highMapData, highMap }
}

export const calculateBounds = (coordinates: GpsPoint[]): LngLatBounds => {
  const initialBounds = new LngLatBounds(
    new LngLat(coordinates[0][0], coordinates[0][1]),
    new LngLat(coordinates[0][0], coordinates[0][1])
  )

  return coordinates.reduce((bounds: LngLatBounds, coord: GpsPoint) => {
    const [lng, lat] = coord.slice(0, 2)
    bounds.extend(new LngLat(lng, lat))
    return bounds
  }, initialBounds)
}

export const centerMarker = ({
  mapRef,
  markerRef,
}: {
  mapRef: RefObject<MapRef>
  markerRef: RefObject<MarkerSourceRef>
}) => {
  if (mapRef.current && markerRef.current) {
    const currentPosition = markerRef.current.getPosition()
    if (currentPosition) {
      mapRef.current.flyTo({
        center: [currentPosition[0], currentPosition[1]],
        zoom: mapRef.current.getZoom(),
        speed: DEFAULT_FLY_TO_SPEED,
      })
    }
  }
}

export const drawStartEndMarkers = (coordsPerDrive: DriveCoords[]) => {
  const startEndMarkers: StartEndMarker[] = []
  coordsPerDrive.map((drive, index) => {
    const startMarker = {
      lat: drive.coordinates[0][1],
      lng: drive.coordinates[0][0],
      key: drive.key,
      position: index + '-start',
    }
    startEndMarkers.push(startMarker)

    const endMarker = {
      lat: drive.coordinates[drive.coordinates.length - 1][1],
      lng: drive.coordinates[drive.coordinates.length - 1][0],
      key: drive.key,
      position: index + '-end',
    }
    startEndMarkers.push(endMarker)
  })

  const startEndSamePositionMarkers: StartEndMarker[][] = []
  const isEqualWithPreviousMarkers = (
    currentMarker: StartEndMarker,
    previousMarkers: StartEndMarker[]
  ) => {
    const foundMarker = previousMarkers.find(
      (prevMarker) =>
        prevMarker.lat === currentMarker.lat &&
        prevMarker.lng === currentMarker.lng
    )
    if (!foundMarker) return -1
    return findMarkerPosition(foundMarker)
  }

  const findMarkerPosition = (marker: StartEndMarker) => {
    return startEndSamePositionMarkers.findIndex((markerArray) =>
      markerArray.find(
        (m) => m.key === marker.key && m.position === marker.position
      )
    )
  }

  startEndMarkers.map((marker, index) => {
    const isEqualKey = isEqualWithPreviousMarkers(
      marker,
      startEndMarkers.slice(0, index)
    )
    if (isEqualKey === -1) {
      const newArray = []
      newArray.push(marker)
      startEndSamePositionMarkers.push(newArray)
    } else {
      startEndSamePositionMarkers[isEqualKey!].push(marker)
    }
  })

  return startEndSamePositionMarkers
}

export const extractBackgroundColor = (style: string | undefined) => {
  if (!style) return undefined
  const match = style.match(/background-color:\s*rgb\(([^)]+)\)/)
  return match ? match[1] : undefined
}

export const rgbaToHex = (rgba: string | undefined) => {
  if (!rgba) return undefined
  const [r, g, b] = rgba
    .split(',')
    .slice(0, 3)
    .map((num) => parseFloat(num.trim()))
  const toHex = (num: number) => {
    const hex = Math.round(num).toString(16).padStart(2, '0')
    return hex.length === 1 ? '0' + hex : hex
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export function filterIndexes<T>(
  array: T[],
  conditionFn: (element: T, index: number, array: T[]) => boolean
): number[] {
  const indexes: number[] = []

  array.forEach((element, index) => {
    if (conditionFn(element, index, array)) {
      indexes.push(index)
    }
  })

  return indexes
}
