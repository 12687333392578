import { enUS } from '@common/constants/messages'
import ClearIcon from '@mui/icons-material/Clear'
import Crop169Icon from '@mui/icons-material/Crop169'
import GradeIcon from '@mui/icons-material/Grade'
import HideImageIcon from '@mui/icons-material/HideImage'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded'
import SsidChartIcon from '@mui/icons-material/SsidChart'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import {
  TrackDisplayMode,
  trackHeightMap,
  useTimelineContext,
} from '@pages/Details/providers/TimelineContextProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { NestedMenuItem } from 'mui-nested-menu'
import { useContext, useRef } from 'react'
import { DataSet } from 'vis-data'
import { commonStyle } from '../../Menus/types'
import {
  TimelineGroupMenu,
  TimelineMenuController,
} from '../TimelineGroupMenu/TimelineGroupMenu'
import { applyNonLinearTransformation } from '../TimelineHighlightMode/timelineHighlight'
import { hideShow } from '../TimelineTags/handlers'
import { NewGroupData, TimelineItemData } from '../types'
import {
  onGroupAdd,
  onGroupSelect,
  removeSelected,
  transformDataToChartPoints,
} from '../utils'

interface TimelineTrackMenuProps {
  anchorEl: HTMLElement | null
  handleClose: () => void
  groupId: number
}

export function TimelineTrackMenu({
  anchorEl,
  handleClose,
  groupId,
}: TimelineTrackMenuProps) {
  const timelineContext = useTimelineContext()
  const {
    selectedGroup,
    setSelectedGroup,
    items,
    originalItems,
    hidden,
    setHidden,
    timelineSettings,
    setTrackParameters,
    trackParameters,
  } = timelineContext
  const {
    highlightLength: timelineSettingsHighlightLength,
    sortBy: timelineSettingsSortBy,
  } = timelineSettings
  const { highlightMode, setHighlightMode } = useDriveTrialContext()
  const open = Boolean(anchorEl)
  const timelineModalRef = useRef<TimelineMenuController>()
  const mediaSyncCtx = useContext(MediaSyncContext)
  const displayMode = trackParameters?.[groupId]?.displayMode

  const areChartsAvailable = timelineContext.originalItems
    .get()
    .filter(
      (item) =>
        item.group === groupId && !item.id?.toString().includes('placeholder')
    )
    .some((i) => i.flag === 2)

  const handleSelect = () => {
    onGroupSelect(groupId, selectedGroup, setSelectedGroup)
    handleClose()
  }

  const handleTrackDisplay = (value: TrackDisplayMode) => {
    if (!trackParameters || !trackParameters[groupId]) {
      const groupItems = timelineContext.items
        .get()
        .filter(
          (item) =>
            item.group === groupId &&
            !item.id?.toString().includes('placeholder')
        )
      const height =
        trackParameters && trackParameters[groupId]
          ? trackHeightMap[trackParameters[groupId].height || 'small']
          : 24
      const chartData = transformDataToChartPoints(groupItems, height)
      setTrackParameters((prev) => ({
        ...prev,
        [groupId]: {
          ...prev?.[groupId],
          displayMode: value,
          chartData: chartData,
          items: groupItems,
        },
      }))
    } else {
      if (trackParameters[groupId].items) {
        setTrackParameters((prev) => ({
          ...prev,
          [groupId]: {
            ...prev?.[groupId],
            displayMode: value,
          },
        }))
      } else {
        const groupItems = timelineContext.items
          .get()
          .filter(
            (item) =>
              item.group === groupId &&
              !item.id?.toString().includes('placeholder')
          )
        const height =
          trackParameters &&
          trackParameters[groupId] &&
          trackParameters[groupId].height
            ? trackHeightMap[trackParameters[groupId].height || 'small']
            : 24
        const chartData = transformDataToChartPoints(groupItems, height)
        setTrackParameters((prev) => ({
          ...prev,
          [groupId]: {
            ...prev?.[groupId],
            displayMode: value,
            height: trackParameters[groupId].height || 'small',
            chartData: chartData,
            items: groupItems,
          },
        }))
      }
    }
    handleClose()
  }

  const handleShowHide = () => {
    hideShow(timelineContext, groupId)
    const element = document.getElementById(`group-tags-visibility-${groupId}`)
    handleClose()
    if (hidden.includes(groupId)) {
      setHidden(hidden.filter((item) => item !== groupId))
      if (element) {
        element.style.display = 'none'
      }
    } else {
      setHidden([...hidden, groupId])
      if (element) {
        element.style.display = 'flex'
      }
    }
  }

  const changeTimelineMode = (
    items: DataSet<TimelineItemData, 'id'>,
    id: number
  ) =>
    applyNonLinearTransformation(
      items,
      id,
      timelineSettingsHighlightLength,
      timelineSettingsSortBy
    )

  const applyHighlightMode = () => {
    setHighlightMode({
      id: groupId,
      items: changeTimelineMode(originalItems, groupId),
      sortBy: timelineSettingsSortBy,
    })
    removeSelected()
    handleClose()
  }

  const exitHighlightMode = () => {
    setHighlightMode({
      id: -1,
      items: new DataSet([]),
      sortBy: undefined,
    })
    mediaSyncCtx.isPlaying = false
    handleClose()
  }

  const hideGroup = () => {
    setSelectedGroup(selectedGroup === groupId ? undefined : selectedGroup)
    timelineContext.groups.update({ id: groupId, visible: false })
    handleClose()
  }

  return (
    <>
      <Menu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-testid='selectDeselectTrack' onClick={handleSelect}>
          <ListItemIcon sx={commonStyle}>
            <ViewTimelineIcon fontSize='small' />
            <div>
              {enUS.GROUP_SELECT}/{enUS.GROUP_UNSELECT} track
            </div>
          </ListItemIcon>
        </MenuItem>
        <MenuItem data-testid='showHideTags' onClick={handleShowHide}>
          <ListItemIcon sx={commonStyle}>
            <VisibilityIcon fontSize='small' />
            <div>{enUS.GROUP_SHOW_HIDE}</div>
          </ListItemIcon>
        </MenuItem>

        {highlightMode.id !== groupId ? (
          <MenuItem
            data-testid='enterHighlight'
            onClick={applyHighlightMode}
            disabled={
              !items
                .get()
                .filter((item) => item.group === groupId)
                .some((item) => item.isItem)
            }
          >
            <ListItemIcon sx={commonStyle}>
              <GradeIcon fontSize='small' />
              <div>{enUS.HIGHLIGHT_MODE}</div>
            </ListItemIcon>
          </MenuItem>
        ) : (
          <MenuItem data-testid='exitHighlight' onClick={exitHighlightMode}>
            <ListItemIcon sx={commonStyle}>
              <ClearIcon fontSize='small' />
              <div>{enUS.HIGHLIGHT_MODE_EXIT}</div>
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem data-testid='hideGroup' onClick={hideGroup}>
          <ListItemIcon sx={commonStyle}>
            <HideImageIcon fontSize='small' />
            <div>{enUS.HIDE_GROUP}</div>
          </ListItemIcon>
        </MenuItem>
        {areChartsAvailable &&
          highlightMode.id === -1 && [
            <NestedMenuItem
              key='display-mode'
              leftIcon={<ShowChartRoundedIcon />}
              label='Display mode'
              parentMenuOpen={open}
              sx={{ paddingLeft: '14px', '& p': { paddingLeft: '18px' } }}
            >
              <MenuItem
                data-testid={`track-${groupId}-value`}
                disabled={
                  !displayMode || displayMode === TrackDisplayMode.Value
                }
                onClick={() => handleTrackDisplay(TrackDisplayMode.Value)}
              >
                <ListItemIcon>
                  <Crop169Icon fontSize='small' sx={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText>Value</ListItemText>
              </MenuItem>
              <MenuItem
                data-testid={`track-${groupId}-lines`}
                disabled={displayMode === TrackDisplayMode.Lines}
                onClick={() => handleTrackDisplay(TrackDisplayMode.Lines)}
              >
                <ListItemIcon>
                  <SsidChartIcon fontSize='small' sx={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText>Lines</ListItemText>
              </MenuItem>
              <MenuItem
                data-testid={`track-${groupId}-bars`}
                disabled={displayMode === TrackDisplayMode.Bars}
                onClick={() => handleTrackDisplay(TrackDisplayMode.Bars)}
              >
                <ListItemIcon>
                  <LeaderboardIcon fontSize='small' sx={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText>Bars</ListItemText>
              </MenuItem>
            </NestedMenuItem>,

            /*<NestedMenuItem
              key='track-height'
              leftIcon={<HeightIcon />}
              label='Track Height'
              parentMenuOpen={open}
              sx={{ paddingLeft: '14px', '& p': { paddingLeft: '18px' } }}
            >
              <MenuItem
                data-testid={`track-${groupId}-small`}
                onClick={() => handleTrackHeight('small')}
              >
                <ListItemIcon>
                  <DensitySmallIcon fontSize='small' sx={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText>Small</ListItemText>
              </MenuItem>
              <MenuItem
                data-testid={`track-${groupId}-large`}
                onClick={() => handleTrackHeight('large')}
              >
                <ListItemIcon>
                  <DensityLargeIcon fontSize='small' sx={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText>Large</ListItemText>
              </MenuItem>
            </NestedMenuItem>,*/
          ]}
      </Menu>

      <TimelineGroupMenu
        ref={timelineModalRef}
        relativeGroupId={groupId}
        onOK={(data: NewGroupData) =>
          onGroupAdd(data, timelineContext, groupId)
        }
      />
    </>
  )
}
