import { useEditTagMutation } from '@api/index'
import {
  EditJiraRequest,
  useCreateJiraMutation,
  useEditJiraMutation,
  useRemoveJiraMutation,
} from '@api/timeline/jira'
import { Loader } from '@common/components/Loader/Loader'
import { getProjectID } from '@common/utils/storage'
import { toSeconds } from '@common/utils/time'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useTimelineContext } from '@pages/Details/providers/TimelineContextProvider'
import { isAxiosError } from 'axios'
import { DateType } from 'otto-vis-timeline'
import { ChangeEvent, useEffect, useState } from 'react'
import { JiraTeam, jiraTeamIds } from '../VideoTimeline/types'
import { jiraStatusUpdate } from '../VideoTimeline/utils'
import './style.scss'
import {
  formControlSx,
  formHelperTextSx,
  textFieldInputPropsSx,
} from './styles.sx'
import { captureScreenshot, findKeyByValue } from './utils'

interface IProps {
  anchorEl?: HTMLDivElement
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>
  activeTag?: number
  setActiveTag: React.Dispatch<React.SetStateAction<number | undefined>>
  onEdit: (
    activeTag: number,
    text: string,
    summary: string,
    issueType?: string,
    team?: string,
    jiraID?: string | undefined
  ) => void
  onRemove: (activeTag: number) => void
}

export const TagPopup = ({
  anchorEl,
  setAnchorEl,
  activeTag,
  setActiveTag,
  onEdit,
  onRemove,
}: IProps) => {
  const timelineContext = useTimelineContext()
  const [text, setText] = useState('')
  const [summary, setSummary] = useState<string>('')
  const [disposition, setDisposition] = useState<'otto' | 'jira'>('otto')
  const [issueType, setIssueType] = useState<'Bug' | 'Task' | undefined>(
    undefined
  )
  const [jiraIssueID, setJiraIssueID] = useState<string | undefined>(undefined)
  const [start, setStart] = useState<DateType>(0)
  const [end, setEnd] = useState<DateType>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [captureScreenshotOnClose, setCaptureScreenshotOnClose] =
    useState(false)
  const [team, setTeam] = useState<JiraTeam>('FE')
  const open = Boolean(anchorEl)
  const projectID = getProjectID()
  const { createJiraMutation } = useCreateJiraMutation()
  const { removeJiraMutation } = useRemoveJiraMutation()
  const { editJiraMutation } = useEditJiraMutation()
  const { editTagMutation } = useEditTagMutation()
  const id = open ? 'tag-popover' : undefined
  const jiraProjectID = process.env.REACT_APP_JIRA_PROJECT_ID

  const closeNotationPopup = () => {
    setAnchorEl(undefined)
    setActiveTag(undefined)
    setCaptureScreenshotOnClose(false)
  }

  const handleCreateJiraIssue = async () => {
    if (!jiraProjectID) return

    const taskData = {
      projectKey: jiraProjectID,
      summary: summary,
      description: text,
      issueType: issueType!,
      team: jiraTeamIds[team],
      webLink: `${
        window.location.href.split('&')[0]
      }&jira=true&time=${toSeconds(+start)}&projectID=${projectID}`,
    }

    setIsLoading(true)

    try {
      const jiraResponse = await createJiraMutation(taskData)

      if (jiraResponse && jiraResponse.status === 201) {
        const jiraID = jiraResponse.data.issue_key
        setJiraIssueID(jiraID)
        setIsLoading(false)
        jiraStatusUpdate(jiraID, timelineContext, activeTag!, editTagMutation)
        if (captureScreenshotOnClose) {
          captureScreenshot(jiraID)
        }
        onEdit(activeTag!, text, summary, issueType, team, jiraID)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      handleError(error)
      setIsLoading(false)
    } finally {
      closeNotationPopup()
    }
  }

  // Handle editing an existing Jira issue
  const handleEditJiraIssue = async () => {
    if (!jiraIssueID) return
    const editJira: EditJiraRequest = {
      issueKey: jiraIssueID,
      description: text,
      summary: summary,
      team: jiraTeamIds[team],
      issueType: issueType,
    }
    try {
      await handleJiraItem(jiraIssueID, editJira)
      if (captureScreenshotOnClose) {
        captureScreenshot(jiraIssueID)
      }
      onEdit(activeTag!, text, summary, issueType, team, jiraIssueID)
    } catch (error) {
      handleError(error)
    } finally {
      closeNotationPopup()
    }
  }

  // Handle Jira item actions
  const handleJiraItem = async (
    jiraIssueID: string,
    editObject?: EditJiraRequest
  ) => {
    try {
      if (!editObject) {
        await removeJiraMutation({ issueKey: jiraIssueID })
      } else {
        await editJiraMutation(editObject)
      }
    } catch (error: unknown) {
      handleError(error)
    }
  }

  // Handle errors with notifications
  const handleError = (error: unknown) => {
    if (isAxiosError(error)) {
      console.error('Axios error:', error.message)
      // Implement user notification logic here
    } else {
      console.error('Unknown error:', error)
      // Implement user notification logic here
    }
  }

  // Handle the publish button click
  const handlePublish = () => {
    if (activeTag) {
      if (disposition === 'jira') {
        if (!jiraIssueID) {
          handleCreateJiraIssue()
        } else {
          handleEditJiraIssue()
        }
      } else {
        onEdit(activeTag, text, summary, issueType, team)
        closeNotationPopup()
      }
    }
  }

  // Handle the delete button click
  const handleDelete = async () => {
    if (activeTag) {
      onRemove(activeTag)
      if (disposition === 'jira' && jiraIssueID) {
        handleJiraItem(jiraIssueID)
      }
      closeNotationPopup()
    }
  }

  // Handle disposition change
  const handleChangeDisposition = (event: ChangeEvent<HTMLInputElement>) => {
    setDisposition(event.target.value as 'otto' | 'jira')
  }

  const handleChangeJiraIssueType = (event: ChangeEvent<HTMLInputElement>) => {
    setIssueType(event.target.value as 'Bug' | 'Task')
  }

  const handleChangeTeam = (event: ChangeEvent<{ value: JiraTeam }>) => {
    setTeam(event.target.value as JiraTeam)
  }

  useEffect(() => {
    if (open) {
      const foundItem = timelineContext.items.get(`tag-${activeTag}`)
      if (foundItem) {
        setStart(foundItem.start)
        setEnd(foundItem.end!)
        setText(foundItem.title || '')
        setSummary(foundItem.summary || '')
        setIssueType(foundItem.issueType || 'Task')
        setJiraIssueID(foundItem.jiraID)
        setDisposition(foundItem.jiraID === undefined ? 'otto' : 'jira')
        setTeam(findKeyByValue(foundItem.team || '10022') as JiraTeam)
      }
    }
  }, [open, activeTag, timelineContext.items])

  return (
    <div className='MuiDialog-root dialog-container MuiModal-root'>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeNotationPopup}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isLoading && (
          <div className='loader-wrapper'>
            <Loader center />
          </div>
        )}
        <Box
          component='div'
          sx={{
            padding: 1,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          {end ? (
            <>
              <FormControl sx={formControlSx}>
                <FormLabel sx={{ fontSize: '0.75rem' }} focused={false}>
                  Disposition:
                </FormLabel>
                <RadioGroup
                  value={disposition}
                  onChange={handleChangeDisposition}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value='otto'
                    disabled={jiraIssueID !== undefined}
                    sx={{ color: disposition === 'otto' ? 'white' : '#8a8a8a' }}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          color: '#8a8a8a',
                          '&.MuiRadio-root.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label='Otto'
                  />
                  <FormControlLabel
                    value='jira'
                    sx={{ color: disposition === 'jira' ? 'white' : '#8a8a8a' }}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          color: '#8a8a8a',
                          '&.MuiRadio-root.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label='Jira'
                  />
                </RadioGroup>
                <span style={{ color: 'whitesmoke' }}>{jiraIssueID}</span>
              </FormControl>
              <div className='mark-container'>
                <Typography fontSize='0.75rem' color='whitesmoke'>
                  Mark in:{' '}
                  <span className='mark-in-mark-out'>{start.toString()}</span>
                </Typography>
                <Typography fontSize='0.75rem' color='whitesmoke'>
                  Mark out:{' '}
                  <span className='mark-in-mark-out'>{end.toString()}</span>
                </Typography>
              </div>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }}
              />

              <div>
                <div>
                  <FormControl sx={formControlSx}>
                    <RadioGroup
                      value={issueType}
                      onChange={handleChangeJiraIssueType}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value='Bug'
                        sx={{ color: 'white' }}
                        control={
                          <Radio
                            size='small'
                            disabled={disposition !== 'jira'}
                            sx={{
                              color: '#8a8a8a',
                              '&.MuiRadio-root.Mui-checked': {
                                color: 'white',
                              },
                            }}
                          />
                        }
                        label='Bug'
                      />
                      <FormControlLabel
                        value='Task'
                        sx={{ color: 'white' }}
                        control={
                          <Radio
                            disabled={disposition !== 'jira'}
                            size='small'
                            sx={{
                              color: '#8a8a8a',
                              '&.MuiRadio-root.Mui-checked': {
                                color:
                                  disposition !== 'jira'
                                    ? 'rgba(0, 0, 0, 0.38)'
                                    : 'white',
                              },
                            }}
                          />
                        }
                        label='Task'
                      />
                    </RadioGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={captureScreenshotOnClose}
                          onChange={(e) =>
                            setCaptureScreenshotOnClose(e.target.checked)
                          }
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'white',
                            },
                          }}
                          disabled={disposition !== 'jira'}
                        />
                      }
                      label='Add screenshot'
                      sx={{ color: 'white' }}
                    />
                  </FormControl>
                </div>

                <FormControl
                  disabled={disposition !== 'jira'}
                  required={disposition !== 'jira' ? false : true}
                  sx={{
                    marginTop: 1,
                    width: '100%',
                    color: 'white',
                    '.MuiSelect-select': { color: 'white' },
                    '.MuiInputLabel-root': {
                      color:
                        disposition !== 'jira'
                          ? 'rgba(0, 0, 0, 0.38)'
                          : 'rgba(255, 255, 255)',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                >
                  <InputLabel>Team</InputLabel>
                  <Select
                    label='Team'
                    value={team}
                    onChange={(e: any) => handleChangeTeam(e)}
                    required
                  >
                    {Object.entries(jiraTeamIds).map(([key, value]) => (
                      <MenuItem key={value} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className='textFieldContainerSx'>
                  <TextField
                    multiline
                    required={true}
                    maxRows={4}
                    onFocus={() => timelineContext.setInputInFocus(true)}
                    onBlur={() => timelineContext.setInputInFocus(false)}
                    inputProps={{ maxLength: 256 }}
                    InputProps={{
                      readOnly: false,
                      sx: textFieldInputPropsSx,
                    }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setSummary(e.target.value)
                    }}
                    value={summary}
                    label='Summary'
                    variant='outlined'
                  />
                  <FormHelperText sx={formHelperTextSx}>
                    Characters left: {Math.abs(summary.length - 256)}
                  </FormHelperText>
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: end ? '' : '8px',
            }}
          >
            <div className='textFieldContainerSx'>
              <TextField
                placeholder='Description...'
                multiline
                maxRows={4}
                onFocus={() => timelineContext.setInputInFocus(true)}
                onBlur={() => timelineContext.setInputInFocus(false)}
                inputProps={{ maxLength: 256 }}
                InputProps={{
                  readOnly: false,
                  sx: textFieldInputPropsSx,
                }}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setText(e.target.value)
                }}
                value={text}
                label='Description'
                variant='outlined'
              />
              <FormHelperText sx={formHelperTextSx}>
                Characters left: {Math.abs(text.length - 256)}
              </FormHelperText>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              style={{
                marginTop: '12px',
              }}
              onClick={handleDelete}
            >
              <DeleteIcon fontSize='medium' color='error' />
            </IconButton>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <Button
                onClick={closeNotationPopup}
                color='secondary'
                variant='outlined'
                sx={{ marginRight: 1 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handlePublish}
                color='primary'
                variant='contained'
                disabled={
                  isLoading ||
                  (end && !summary) ||
                  (disposition === 'jira' && !team)
                }
              >
                Publish
              </Button>
            </div>
          </div>
        </Box>
      </Popover>
    </div>
  )
}
