import { forwardRef, useImperativeHandle, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { GpsPoint } from './types'

interface PointSource {
  id: string
  type: 'geojson'
  data: {
    id: string
    type: 'Feature'
    geometry: {
      type: 'Point'
      coordinates: GpsPoint
    }
    properties: null
  }
}

interface MarkerSourceProps {
  icon: string
  initPosition: GpsPoint
}

const MarkerSource = forwardRef(function MarkerSource(
  { icon, initPosition }: MarkerSourceProps,
  ref
) {
  const [position, setPosition] = useState<GpsPoint>(initPosition)
  const [rotationAngle, setRotationAngle] = useState(0)

  const markerSourceProps: PointSource = {
    id: 'point',
    type: 'geojson',
    data: {
      id: 'map-marker',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: position,
      },
      properties: null,
    },
  }

  useImperativeHandle(ref, () => ({
    setPosition: (point: GpsPoint) => setPosition(point),
    getPosition: () => position,
    setRotationAngle: (angle: number) => setRotationAngle(angle),
  }))

  return (
    <Source {...markerSourceProps}>
      <Layer
        id='points'
        type='symbol'
        source='point'
        layout={{
          'icon-image': icon,
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-rotation-alignment': 'map',
          'icon-pitch-alignment': 'map',
          'icon-rotate': rotationAngle,
        }}
      />
    </Source>
  )
})

export default MarkerSource
