import { Map, View } from 'ol'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import { BingMaps, TileImage } from 'ol/source'
import VectorSource from 'ol/source/Vector'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'

export const source = new VectorSource()

const featuresLayer = new VectorLayer({
  source: source,
  style: new Style({
    stroke: new Stroke({
      color: '#00ff00',
      width: 2,
    }),
  }),
})

export const view = new View({
  projection: 'EPSG:3857',
  center: fromLonLat([-83.21104937105021, 42.64401623615981]),
  zoom: 17,
  maxZoom: 19,
})

export const TOP_VIEW_MAP = new Map({
  layers: [
    new TileLayer({
      visible: false,
      source: new TileImage({
        projection: 'proj3857D',
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
      }),
    }),
    new TileLayer({
      visible: true,
      source: new BingMaps({
        key: 'Aq9R6UW215ihWE5oxEc2GpBckZFYnaV5bkpETNrX5gkww51KeYhfo4GVK2Zz7bdT',
        imagerySet: 'Aerial',
      }),
    }),
    featuresLayer,
  ],
  view: view,
  controls: [],
}) as Map
