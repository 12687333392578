import { TimelineGroup, TimelineItem } from 'otto-vis-timeline'
import { DataSet } from 'vis-data'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { SignDataResponse } from '../TopDown3D_V1_Copy/types'

export enum GroupAddPosition {
  ABOVE,
  UNDER,
}

export interface NewGroupData {
  order: number
  name: string
  value: string
  type: string
  position: GroupAddPosition
  relativeToGroup?: number
  additionalData?: AdditionalGroupData
}

export interface VideoTimelineProps {
  synchronizer?: ISynchronizer
  viewportId: number
  signsData?: SignDataResponse
}

export interface VideoTimelineFetcherProps extends VideoTimelineProps {
  synchronizer?: ISynchronizer
  viewportId: number
  signsData?: SignDataResponse
  onDataReceived: () => void
}

export interface KpiTimelineFetcherProps {
  slideOffsetPercent: number
  initialItems?: DataSet<TimelineItemData>
  synchronizer?: ISynchronizer
  viewportId: number
}

export interface KpiHighlightObject {
  kpi: string
  lane: string
  side: string
}

export type AdditionalGroupData = Omit<TimelineGroup, 'content' | 'id'>

export interface TimelineDataGroup extends TimelineGroup {
  order: number
  value: string
  hasFocus: boolean
}

type What =
  | 'item'
  | 'background'
  | 'axis'
  | 'group-label'
  | 'custom-time'
  | 'current-time'

type Target = {
  className: string[]
  classList: DOMTokenList
  offsetLeft: number
  offsetTop: number
  parentElement: {
    classList: DOMTokenList
  }
}
export interface TimelineEvent extends Omit<MouseEvent, 'target'> {
  target: Target
  srcElement: HTMLDivElement
}
export interface TimelineEventData {
  group: number | null
  item: string
  customTime: number | null
  pageX: number
  pageY: number
  x: number
  y: number
  time: Date
  snappedTime: Date
  what: What
  event: TimelineEvent
  id: number
}

export type JiraTeam = 'CV' | 'AN' | 'FE' | 'DevOps' | 'QA' | 'ANO'

export const jiraTeamIds = {
  CV: '10020',
  AN: '10021',
  FE: '10022',
  DevOps: '10027',
  QA: '10023',
  ANO: '10064',
}

export type JiraIssueType = 'Bug' | 'Task'

export interface TimelineItemData extends TimelineItem {
  isItem: boolean
  gain?: number
  dtid?: number
  originalEnd?: number
  originalStart?: number
  jiraID?: string | undefined
  status?: string
  summary?: string
  issueType?: JiraIssueType
  team?: string
  metric?: number
  dataTestId?: string
  flag?: number
}

export type SortHighlights = 'time' | 'gain'

export type JiraStatus =
  | 'To Do'
  | 'Reopened'
  | 'In Progress'
  | 'Done'
  | 'Closed'
  | 'On Hold'
  | 'QA'
  | 'Ready 4 QA'
  | 'Reopened'
  | 'Review'

export interface JiraIssueStatusMap {
  [issueId: string]: string
}
