import { SelectChangeEvent, Stack } from '@mui/material'
import { Select } from '@common/components/Inputs/Select/Select'
import { FilterProps } from '@modules/sidebarToolbox/types/sideFilters'
import {
  countryOptions,
  roadTypeOptions,
  timeOfDayOptions,
  weatherOptions,
} from '@modules/sidebarToolbox/constants/filters'

export const EnvironmentSelectors = ({ filters, onChange }: FilterProps) => {
  const handleChange = (value: string, fieldName: string) => {
    onChange({
      ...filters,
      environmentFilters: {
        ...filters.environmentFilters,
        [fieldName]: value,
      },
    })
  }

  return (
    <Stack direction='column' spacing={4}>
      <Stack direction='column' spacing={2}>
        <Select
          variant='filled'
          title='weather'
          label='Weather'
          styledFirstChild
          value={filters?.environmentFilters?.weather}
          onChange={(e: SelectChangeEvent) =>
            handleChange(e.target.value, 'weather')
          }
          options={[{ value: '', text: 'Reset' }, ...weatherOptions]}
        />

        <Select
          variant='filled'
          title='roadType'
          label='Road Type'
          styledFirstChild
          value={filters?.environmentFilters?.roadType}
          onChange={(e: SelectChangeEvent) =>
            handleChange(e.target.value, 'roadType')
          }
          options={[{ value: '', text: 'Reset' }, ...roadTypeOptions]}
        />

        <Select
          variant='filled'
          title='timeOfDay'
          label='Time of Day'
          styledFirstChild
          value={filters?.environmentFilters?.timeOfDay}
          onChange={(e: SelectChangeEvent) =>
            handleChange(e.target.value, 'timeOfDay')
          }
          options={[{ value: '', text: 'Reset' }, ...timeOfDayOptions]}
        />

        <Select
          variant='filled'
          title='country'
          label='Country'
          styledFirstChild
          value={filters?.environmentFilters?.country}
          onChange={(e: SelectChangeEvent) =>
            handleChange(e.target.value, 'country')
          }
          options={[{ value: '', text: 'Reset' }, ...countryOptions]}
        />
      </Stack>
    </Stack>
  )
}
