import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import './style.scss'

export interface ModalDialogProps {
  onClose: () => void
  open: boolean
  children: React.ReactElement[] | React.ReactElement
  title: string
  overflowScroll?: boolean
}

const ModalDialog = (props: ModalDialogProps) => {
  const { onClose, open, children, title, overflowScroll } = props

  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: '#3f4c6b',
          boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.3)',
          borderRadius: '4px',
          border: '2px solid #6779a5f2',
          cursor: 'default',
          filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.3))',
          rowGap: '10px',
          overflow: 'visible',
        },
      }}
      className='dialog-container'
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        sx={{
          '&.MuiDialogTitle-root': {
            padding: '0 12px 0 24px',
          },
        }}
        className='dialog-title'
      >
        <Typography>{title}</Typography>
        <CloseIcon
          className='close-icon'
          fontSize='small'
          onClick={onClose}
          cursor='pointer'
        />
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        sx={{
          '&.MuiDialogContent-root': {
            overflow: overflowScroll ? 'scroll' : 'auto',
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default ModalDialog
