import { PanelProps } from '../types'
import './style.scss'

export const Panel = ({ title, children, shadows }: PanelProps) => (
  <div
    className='panel'
    style={{
      borderRadius: shadows ? '4px' : 0,
      boxShadow: shadows ? '0 2px 4px black' : 'unset',
      filter: shadows ? 'drop-shadow(0 2px 4px black)' : 'unset',
    }}
  >
    <div>{title || ''}</div>
    <div className='panel-content'>{children}</div>
  </div>
)
