import { useKpiFiltersQuery } from '@api/index'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import FilterListIcon from '@mui/icons-material/FilterList'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import { Button, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { isDevelopmentOrLocal } from '@common/constants/env'
import { enUS_SIDEBAR_TOOLBOX } from '@common/constants/messages'
import { ReportTypes, TableRouteParams } from '@common/constants/paths'
import { DrawerMenu } from '@common/components/DrawerMenu/DrawerMenu'
import { Toolbar } from '@common/components/Toolbar/Toolbar'
import { ProjectName } from 'models/project'
import {
  INIT_KPI_LANES_COLUMN_VISIBILITY,
  INIT_KPI_LIGHTS_COLUMN_VISIBILITY,
  INIT_KPI_SIGNS_COLUMN_VISIBILITY,
} from '@pages/Report/components/initColumnVisibility'
import { useReportContext } from '@pages/Report/providers/ReportContextProvider'
import { DashboardParametersType, FiltersState } from '@pages/Report/types'
import { HideShowPicker } from '@modules/sidebarToolbox/components/HideShowPicker'
import { ReportsSelector } from 'components/ReportsSelector/ReportsSelector'
import './style.scss'
import {
  IconDefinition,
  TabsRoutes,
  ToolbarItem,
  ToolboxProps,
} from '@modules/sidebarToolbox/types/sidebar'
import { createFilterPayload } from '@modules/sidebarToolbox/utils/filters'
import {
  iconStyles,
  iconStylesDisabled,
} from '@modules/sidebarToolbox/constants/styles'
import { prepareKpiFilters } from '@modules/sidebarToolbox/utils/kpis'
import { KpiFilters } from '../KpiFilters'
import { SideFilters } from '../SideFilters'
import { QueryBuilderSelector } from '../QueryBuilderSelector'

const availableTabs: TabsRoutes = {
  'Data Completeness': ReportTypes.COMPLETENESS,
  'Data Quality': ReportTypes.QUALITY,
  Characterization: ReportTypes.CHARACTERIZATION,
}

const tabRouteMap: TabsRoutes = {
  [ReportTypes.COMPLETENESS]: 'Data Completeness',
  [ReportTypes.QUALITY]: 'Data Quality',
  [ReportTypes.CHARACTERIZATION]: 'Characterization',
}

if (isDevelopmentOrLocal) {
  availableTabs.Characterization = ReportTypes.CHARACTERIZATION
  availableTabs['Pipeline Status'] = ReportTypes.PIPELINE_STATUS
  tabRouteMap[ReportTypes.CHARACTERIZATION] = 'Characterizations'
}

export const SidebarToolbox = ({
  anchor = 'left',
  variant = 'temporary',
  onDrawerToggle,
}: ToolboxProps) => {
  const { pathname } = useLocation()
  const { reportType } = useParams<TableRouteParams>()
  const { preserveState, setPreserveState } = useReportContext()
  const [openedTool, setOpenedTool] = useState<ToolbarItem>('')
  const isKPI = pathname.includes('kpis')
  const isEnabled =
    pathname.includes('dashboards') || pathname.includes('spr-gallery')
  const { data = [] } = useKpiFiltersQuery(isKPI)
  const [dashboardFilterObject, setDashboardFilterObject] =
    useState<DashboardParametersType>()

  const initKpiState = match(reportType)
    .with('lanes', () => INIT_KPI_LANES_COLUMN_VISIBILITY)
    .with('signs', () => INIT_KPI_SIGNS_COLUMN_VISIBILITY)
    .with('lights', () => INIT_KPI_LIGHTS_COLUMN_VISIBILITY)
    .otherwise(() => {})

  useEffect(() => {
    if (!dashboardFilterObject) return
    const { weather, roadType, timeOfDay } = dashboardFilterObject

    Object.keys(dashboardFilterObject).forEach((key) => {
      if (
        dashboardFilterObject[key as keyof DashboardParametersType] === 'Total'
      ) {
        dashboardFilterObject[key as keyof DashboardParametersType] = ''
      }
    })

    const filterState = {
      ...preserveState[reportType!].filterState,
      environmentFilters: dashboardFilterObject,
    } as FiltersState

    const filterPayload = createFilterPayload(filterState, reportType!, 1)

    if (roadType && weather && timeOfDay) {
      setPreserveState({
        ...preserveState,
        [reportType!]: {
          ...preserveState[reportType!],
          filterToSql: filterPayload.filters!,
          searchType: 'filter',
          filterState,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardFilterObject])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const jsonDataString = queryParams.get('data')

    if (jsonDataString) {
      const decodedData: DashboardParametersType = JSON.parse(
        decodeURIComponent(jsonDataString)
      )
      const { weather, roadType, timeOfDay } = decodedData

      if (roadType && weather && timeOfDay) {
        setDashboardFilterObject({ weather, roadType, timeOfDay })
      }
    }

    if (!reportType) setOpenedTool('')
    const kpis = ['lanes', 'signs', 'lights']
    if (openedTool === 'Advanced Search' && kpis.includes(reportType!)) {
      setOpenedTool('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  const toggleDrawer = (tool: ToolbarItem) => {
    setOpenedTool(openedTool === tool ? '' : tool)
  }

  const clearAll = () => {
    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        columnVisibility: isKPI ? initKpiState : {},
      },
    })
  }

  useEffect(() => {
    onDrawerToggle(Boolean(openedTool))
  }, [openedTool, onDrawerToggle])

  const toolbarContent: IconDefinition = [
    {
      icon: (
        <AccountTreeIcon
          fontSize='medium'
          sx={isEnabled ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Columns'),
      opened: openedTool === 'Columns',
      title: enUS_SIDEBAR_TOOLBOX.columns,
      disabled: isEnabled,
    },
    {
      icon: (
        <FilterListIcon
          fontSize='medium'
          sx={isEnabled ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Search'),
      opened: openedTool === 'Search',
      title: enUS_SIDEBAR_TOOLBOX.search,
      disabled: isEnabled,
    },
    {
      icon: (
        <FlashOnIcon
          fontSize='medium'
          sx={isEnabled ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Advanced Search'),
      opened: openedTool === 'Advanced Search',
      title: enUS_SIDEBAR_TOOLBOX.advancedSearch,
      disabled: isEnabled,
    },
  ]

  return (
    <>
      <Toolbar side={anchor} content={toolbarContent} />
      <DrawerMenu anchor={anchor} variant={variant} open={Boolean(openedTool)}>
        <div className='drawer-content'>
          <Typography
            variant='h6'
            display='flex'
            alignItems='center'
            justifyContent={'space-between'}
            sx={{
              color: '#e5e5e5',
              padding: '24px 0 24px 8px',
              userSelect: 'none',
            }}
          >
            {openedTool.toUpperCase()}
            {openedTool === 'Columns' && (
              <Button variant='contained' onClick={clearAll}>
                Clear
              </Button>
            )}
          </Typography>
          {match(openedTool)
            .with('Reports', () => <ReportsSelector />)
            .with('Columns', () => <HideShowPicker reportType={reportType!} />)
            .with('Search', () =>
              isKPI ? (
                <KpiFilters
                  project={capitalize(reportType) as ProjectName}
                  filterData={prepareKpiFilters(data, reportType || '')}
                  reportType={reportType!}
                />
              ) : (
                <SideFilters reportType={reportType!} />
              )
            )
            .with('Advanced Search', () => (
              <QueryBuilderSelector reportType={reportType!} />
            ))
            .otherwise(() => null)}
        </div>
      </DrawerMenu>
    </>
  )
}
