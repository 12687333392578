import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'

export default function useCanvasStreamQuery(
  enabled: boolean,
  dtid: number,
  start: number,
  end: number,
  s3Uri: string
) {
  return useQuery({
    staleTime: Infinity,
    enabled,
    queryKey: ['canvasStream', dtid, start, end, s3Uri],
    queryFn: async () => {
      try {
        const response = await axios.get(urls.canvasStream, {
          params: {
            dtid,
            start,
            end,
            s3Uri,
          },
        })
        return response.data
      } catch (error) {
        console.error('Error fetching canvas stream data:', error)
        return ''
      }
    },
  })
}
