export interface ResponseError extends Error {
  response: Response
}

export const getResponseStatusCode = (error: Error | null) => {
  if (error) {
    const responseError = error as ResponseError
    return responseError?.response?.status || 200
  }
  return 200
}
