import { PropsWithChildren } from 'react'

export function QueryStringWrapper({ children }: PropsWithChildren) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5rem',
        borderColor: '#8081a2',
        borderStyle: 'solid',
        borderRadius: '0.25rem',
        borderWidth: '1px',
        background: '#606c88',
        margin: '5px',
        color: 'white',
        wordBreak: 'break-all',
      }}
    >
      {children}
    </div>
  )
}
