import moment, { MomentInput } from 'moment'
import { TimelineOptions } from 'otto-vis-timeline'

export const TIMELINE_OPTIONS_DEFAULT = {
  margin: { axis: 0, item: 0 },
  autoResize: true,
  groupHeightMode: 'fixed',
  type: 'range',
  zoomable: true,
  moveable: false,
  verticalScroll: true,
  width: '100%',
  height: '100%',
  preferZoom: true,
  zoomFriction: 20,
  showMajorLabels: false,
  showCurrentTime: false,
  zoomKey: 'ctrlKey',
  format: {
    minorLabels: {
      millisecond: 'HH:mm:ss.SS',
      second: 'HH:mm:ss',
      minute: 'HH:mm:ss',
      hour: 'HH:mm:ss',
    },
  },
  orientation: 'top',
  snap: null,
  stack: false,
  groupEditable: false,
  /*  groupOrder: function (a, b) {
    return a.order - b.order
  },
  groupOrderSwap: function (a, b) {
    const v = a.order
    a.order = b.order
    b.order = v
  }, */
  moment: function (date: MomentInput): moment.Moment {
    return moment.utc(date)
  },
} as TimelineOptions
