export const RoadType = {
  CITY: 'City',
  HIGHWAY: 'Highway',
  RURAL: 'Rural',
}

export const Weather = {
  SUNNY: 'Sunny',
  RAIN: 'Rain',
  CLOUDY: 'Cloudy',
}

export const TimeOfDay = {
  DAY: 'Day',
  TWILIGHT: 'Twilight',
  NIGHT: 'Night',
}

export const DashboardTableSource = {
  RAW_HOURS: 'Raw Hours',
  TARGET_HOURS: 'Target Hours',
}

export const DashboardTableMajorOrganization = {
  ROAD_TYPE: 'Road Type',
  WEATHER: 'Weather',
  TIME_OF_DAY: 'Time of day',
}

export interface Cell {
  roadType: string
  weather: string
  timeOfDay: string
  rawHours: number
  targetHours: number
}

export const DummyDataVolvo: Cell[] = [
  {
    roadType: RoadType.CITY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 1.32967777778,
    targetHours: 3,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0.98356666666,
    targetHours: 2,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 4.8069,
    targetHours: 3,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 2,
  },

  {
    roadType: RoadType.CITY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 2,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0.95801111111,
    targetHours: 3,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 2.43828888889,
    targetHours: 2,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 5.80801111111,
    targetHours: 3,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 2,
  },

  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 2,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 1.76634444444,
    targetHours: 3,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0.2794,
    targetHours: 2,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 4.92967777778,
    targetHours: 3,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.RURAL,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 2,
  },

  {
    roadType: RoadType.RURAL,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 2,
  },
]

export const DummyData: Cell[] = [
  {
    roadType: RoadType.CITY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.CITY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.CITY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.HIGHWAY,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.DAY,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.TWILIGHT,
    rawHours: 0,
    targetHours: 0,
  },
  {
    roadType: RoadType.RURAL,
    weather: Weather.SUNNY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.RURAL,
    weather: Weather.RAIN,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },

  {
    roadType: RoadType.RURAL,
    weather: Weather.CLOUDY,
    timeOfDay: TimeOfDay.NIGHT,
    rawHours: 0,
    targetHours: 0,
  },
]
