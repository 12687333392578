import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { FilterObject } from '../models/table'
import { urls } from './urls'

export interface SendToDetailsData {
  DTID?: number
  sectionId?: number
  detailId?: number
  minSectionId?: number
}

export interface DriveTrialDataRedirect {
  DTID: number
  parentDTID: number
  version?: string
  sendToDetailsData?: SendToDetailsData
}

export interface VisibleColumns {
  kpi: string
  items: string[]
}

export interface RedirectData {
  filters?: FilterObject
  columns?: VisibleColumns[]
  rows: DriveTrialDataRedirect[]
}

export const getDriveTrials = (data: RedirectData | undefined) =>
  data?.rows?.map((x) => x.DTID) || []

export const getUniqueDTParentKeys = (data: RedirectData | undefined) => [
  ...new Set(data?.rows?.map((x) => x.parentDTID) || []),
]

export const getParentKeys = (data: RedirectData | undefined): number[] =>
  data?.rows?.map((x) => x.parentDTID) || []

export const useRedirectData = (guid: string) =>
  useQuery({
    queryKey: ['guid', guid],
    queryFn: async (): Promise<RedirectData> => {
      const redirectUrl = `${urls.redirectionData}/${guid}`
      const res = (await axios.get<RedirectData>(redirectUrl))?.data
      return res
    },
  })
