import { ViewportContent } from '@pages/Details/types/viewportContent'
import { match } from 'ts-pattern'

export enum CAMERA_POSITION {
  FRONT = 1,
  LEFT,
  RIGHT,
  REAR,
}

const viewportToCameraPosition = (content: ViewportContent) =>
  match(content)
    .with(ViewportContent.FRONT_CAMERA, () => CAMERA_POSITION.FRONT)
    .with(ViewportContent.LEFT_CAMERA, () => CAMERA_POSITION.LEFT)
    .with(ViewportContent.RIGHT_CAMERA, () => CAMERA_POSITION.RIGHT)
    .with(ViewportContent.REAR_CAMERA, () => CAMERA_POSITION.REAR)
    .otherwise(() => CAMERA_POSITION.FRONT)

export const getCameraURL = (camPosition: ViewportContent) => {
  const cameraPosition = viewportToCameraPosition(camPosition)

  const videoUrl = match(cameraPosition)
    .with(CAMERA_POSITION.FRONT, () => 'undestorted_raw_white.mp4')
    .with(CAMERA_POSITION.LEFT, () => 'basler-1-960.mp4')
    .with(CAMERA_POSITION.RIGHT, () => 'basler-0-960.mp4')
    .otherwise(() => `undestorted_raw_white.mp4`)

  return videoUrl
}
