import { useQuery } from '@tanstack/react-query'
import { AdminLevel } from '../../models/table'
import { authApi } from '../base'
import { urls } from '../urls'
import { getEmail } from '@common/utils/storage'

export const PUBLISH_ADMIN = 4

export function useAdminLevel() {
  const email = getEmail()

  return useQuery<AdminLevel | null>({
    staleTime: Infinity,
    queryKey: ['admin_level', email],
    queryFn: async (): Promise<AdminLevel | null> => {
      if (!email) return null
      const resp = await authApi.post(urls.adminLevel, { email })
      return resp.data.adminLevels.length ? resp.data.adminLevels[0] : null
    },
    enabled: !!email,
  })
}
