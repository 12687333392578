import { Divider, DividerOwnProps, styled } from '@mui/material'

interface DividerProps extends DividerOwnProps {
  textcolor: string
  linecolor: string
}

export const StyledDivider = styled(Divider)(
  ({ textcolor, linecolor }: DividerProps) => ({
    margin: '10px 0 !important',
    fontFamily: "'Inter', sans-serif;",
    color: textcolor,
    '&:after': {
      borderTop: `thin solid ${linecolor}`,
    },
    '&:before': {
      borderTop: `thin solid ${linecolor}`,
    },
  })
)
