import { useEffect, useState } from 'react'
import {
  Cell,
  DashboardTableSource,
  TimeOfDay,
  Weather,
} from '@pages/Dashboard/mocks/DummyData'
import { match, P } from 'ts-pattern'
import { Tooltip } from '@mui/material'
import chroma from 'chroma-js'

interface DashboardTableProps {
  roadType: string
  tableSource: string
  dummyData: Cell[]
}

type TableCell = {
  actualValue: number
  targetValue: number
  label: boolean
  labelText: string
  actualValuePercentage: number
  backgroundColor: string
}

type HoverCell = {
  actualValue: number
  targetValue: number
  weather: string
  timeOfDay: string
}

type TableRow = TableCell[]

type TableData = TableRow[]

export function DashboardTable({
  roadType,
  tableSource,
  dummyData,
}: DashboardTableProps) {
  const [tableData, setTableData] = useState<TableData>()
  const [hoverCell, setHoverCell] = useState<HoverCell | null>(null)

  const color1 = '#d32f2f'
  const color2 = '#ed6c02'
  const color3 = '#d2dc23'
  const color4 = '#2e7d32'
  const mainRangeColors = [color1, color2, color3, color4]

  const handleMouseEnter = (rowIndex: number, colIndex: number) => {
    const weather =
      tableData?.[tableData.length - 1]?.[colIndex].labelText || ''
    const timeOfDayText = tableData?.[rowIndex][0].labelText.toLowerCase() || ''
    const timeOfDay =
      timeOfDayText?.charAt(0).toUpperCase() +
      timeOfDayText.slice(1).toLowerCase()

    const actualValue = tableData?.[rowIndex][colIndex].actualValue || 0
    const targetValue = tableData?.[rowIndex][colIndex].targetValue || 0
    setHoverCell({
      actualValue,
      targetValue,
      weather,
      timeOfDay,
    })
  }

  const handleMouseLeave = () => {
    setHoverCell(null)
  }

  useEffect(() => {
    generateTableData(roadType)
  }, [tableSource, dummyData])

  const generateTableData = (roadType: string) => {
    const tableRows: TableRow[] = []
    Object.keys(TimeOfDay).forEach((timeOfDay) => {
      const rowValues: TableRow = []
      const rowSumCell: TableCell = {
        actualValue: 0,
        targetValue: 0,
        label: false,
        actualValuePercentage: 0,
        backgroundColor: '',
        labelText: '',
      }
      const rowLabelCell: TableCell = {
        actualValue: -1,
        targetValue: -1,
        label: true,
        actualValuePercentage: -1,
        backgroundColor: 'transparent',
        labelText: '',
      }
      dummyData
        .filter((data) => data.roadType === roadType)
        .filter(
          (roadTypeData) =>
            roadTypeData.timeOfDay.toLowerCase() === timeOfDay.toLowerCase()
        )
        .map((item) => {
          const tableCellItem: TableCell = {
            actualValue: 0,
            targetValue: 0,
            label: false,
            actualValuePercentage: 0,
            backgroundColor: '',
            labelText: '',
          }
          tableCellItem.actualValue = item.rawHours
          tableCellItem.targetValue = item.targetHours
          tableCellItem.actualValuePercentage = item.targetHours
            ? (item.rawHours / item.targetHours) * 75
            : 100
          tableCellItem.backgroundColor =
            tableSource === DashboardTableSource.RAW_HOURS
              ? calculateBackgroundColor(tableCellItem.actualValuePercentage)
              : 'whitesmoke'
          rowSumCell.actualValue += tableCellItem.actualValue
          rowSumCell.targetValue += tableCellItem.targetValue
          rowValues.push(tableCellItem)
        })

      rowSumCell.actualValuePercentage = rowSumCell.targetValue
        ? (rowSumCell.actualValue / rowSumCell.targetValue) * 75
        : 100
      rowSumCell.backgroundColor =
        tableSource === DashboardTableSource.RAW_HOURS
          ? calculateBackgroundColor(rowSumCell.actualValuePercentage)
          : 'whitesmoke'

      rowValues.push(rowSumCell)

      const timeOfDayText = timeOfDay.toLowerCase()
      const timeOfDayLabel =
        timeOfDayText?.charAt(0).toUpperCase() +
        timeOfDayText.slice(1).toLowerCase()
      rowLabelCell.labelText = timeOfDayLabel
      rowValues.unshift(rowLabelCell)

      tableRows.push(rowValues)
    })

    const totalRow: TableRow = []

    for (let j = 1; j <= tableRows[0].length - 1; j++) {
      const totalRowCell: TableCell = {
        actualValue: 0,
        targetValue: 0,
        label: false,
        actualValuePercentage: 0,
        backgroundColor: '',
        labelText: '',
      }
      let actualValueSum = 0
      let targetValueSum = 0
      for (let i = 0; i < tableRows.length; i++) {
        actualValueSum += tableRows[i][j].actualValue
        targetValueSum += tableRows[i][j].targetValue
      }
      totalRowCell.actualValue = actualValueSum
      totalRowCell.targetValue = targetValueSum
      totalRowCell.actualValuePercentage = targetValueSum
        ? (actualValueSum / targetValueSum) * 75
        : 100
      totalRowCell.backgroundColor =
        tableSource === DashboardTableSource.RAW_HOURS
          ? calculateBackgroundColor(totalRowCell.actualValuePercentage)
          : 'whitesmoke'
      totalRow.push(totalRowCell)
    }

    const totalRowLabelCell: TableCell = {
      actualValue: -1,
      targetValue: -1,
      label: true,
      actualValuePercentage: -1,
      backgroundColor: 'transparent',
      labelText: 'Total',
    }

    totalRow.unshift(totalRowLabelCell)
    tableRows.push(totalRow)

    const labelsForLastRow = [
      '',
      Weather.SUNNY,
      Weather.RAIN,
      Weather.CLOUDY,
      'Total',
    ]

    const finalRow: TableRow = []
    labelsForLastRow.forEach((label) => {
      const finalLabelRow: TableCell = {
        actualValue: -1,
        targetValue: -1,
        label: true,
        actualValuePercentage: -1,
        backgroundColor: 'transparent',
        labelText: '',
      }
      finalLabelRow.labelText = label
      finalRow.push(finalLabelRow)
    })

    tableRows.push(finalRow)
    setTableData(tableRows)
  }

  const calculateBackgroundColor = (percentage: number): string => {
    return match(percentage)
      .with(0, () => mainRangeColors[0])
      .with(25, () => mainRangeColors[1])
      .with(50, () => mainRangeColors[2])
      .with(
        P.when((percentage) => percentage >= 75),
        () => mainRangeColors[3]
      )
      .with(
        P.when((percentage) => percentage > 0 && percentage < 25),
        () => {
          // `linear-gradient(to right, ${red} 0%, ${orange} ${percentage}`
          const scale = chroma
            .scale([mainRangeColors[0], mainRangeColors[1]])
            .mode('lab')
          const colorAtPercentage = scale(percentage / 100).hex()
          return colorAtPercentage
        }
      )
      .with(
        P.when((percentage) => percentage > 25 && percentage < 50),
        () => {
          const scale = chroma
            .scale([mainRangeColors[1], mainRangeColors[2]])
            .mode('lab')
          const colorAtPercentage = scale(percentage / 100).hex()
          return colorAtPercentage
        }
      )
      .with(
        P.when((percentage) => percentage > 50 && percentage < 75),
        () => {
          const scale = chroma
            .scale([mainRangeColors[2], mainRangeColors[3]])
            .mode('lab')
          const colorAtPercentage = scale(percentage / 100).hex()
          return colorAtPercentage
        }
      )
      .otherwise(() => 'transparent')
  }

  const handleClick = (rowIndex: number, colIndex: number) => {
    const timeOfDayText = tableData?.[rowIndex][0].labelText.toLowerCase() || ''
    const timeOfDay =
      timeOfDayText?.charAt(0).toUpperCase() +
      timeOfDayText.slice(1).toLowerCase()

    const weatherText = tableData?.[tableData.length - 1][colIndex].labelText
    const weather =
      weatherText === Weather.RAIN ? weatherText + 'ing' : weatherText

    const dataToSend = {
      roadType,
      timeOfDay,
      weather,
    }

    const jsonData = JSON.stringify(dataToSend)
    const url = '/reporting/drive-trials/characterization'
    window.open(`${url}?data=${encodeURIComponent(jsonData)}`, '_blank')
  }

  return (
    <table
      border={1}
      style={{
        width: '33%',
        borderCollapse: 'collapse',
        fontSize: '12px',
        borderColor: '#606c88',
        tableLayout: 'fixed',
      }}
    >
      <caption
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          marginBottom: '10px',
          color: 'white',
          textAlign: 'left',
          marginLeft: '40px',
        }}
      >
        {roadType.toUpperCase()}
      </caption>
      <tbody>
        {tableData?.map((row, rowIndex) => (
          <tr key={`${roadType}_${rowIndex}_${row[rowIndex].labelText}`}>
            {row.map((cell, colIndex) => {
              return !cell.label ? (
                <Tooltip
                  title={
                    <>
                      Road Type: {roadType}
                      <br />
                      Weather: {hoverCell?.weather}
                      <br />
                      Time of Day: {hoverCell?.timeOfDay}
                      <br />
                      Raw Hours: {hoverCell?.actualValue.toFixed(2)}
                      <br />
                      Target Hours: {hoverCell?.targetValue.toFixed(2)}
                    </>
                  }
                  followCursor
                >
                  <td
                    role='button'
                    key={`${rowIndex}_${colIndex}`}
                    style={{
                      padding: '10px',
                      backgroundColor: cell.backgroundColor,
                      color: cell.label ? 'white' : 'black',
                      width: '40%',
                      textAlign: 'center',
                      fontFamily: '"DM Mono", monospace',
                      fontSize: '0.875rem',
                      fontWeight: 300,
                      borderRadius: '4px',
                      userSelect: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleClick(rowIndex, colIndex)}
                    onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {tableSource === DashboardTableSource.RAW_HOURS
                      ? cell.actualValue.toFixed(2)
                      : cell.targetValue.toFixed(2)}
                  </td>
                </Tooltip>
              ) : (
                <th
                  key={`${rowIndex}_${colIndex}`}
                  style={{
                    padding: '10px',
                    fontWeight: 500,
                    backgroundColor: cell.backgroundColor,
                    color: cell.label ? 'white' : 'black',
                    width: '40%',
                    textAlign:
                      rowIndex === tableData.length - 1 ? 'center' : 'right',
                    fontSize: '0.875rem',
                    fontFamily: '"DM Sans",sans-serif',
                  }}
                >
                  {cell.label && cell.labelText}
                </th>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
