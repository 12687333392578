import { DashboardCharts } from './containers/DashboardCharts'
import { useGetTableauParams } from './hooks/useGetTableauParams'
import './style.scss'

export function Dashboard() {
  const { token, wurl } = useGetTableauParams()

  return (
    <div className='dashboards'>
      <tableau-viz
        id='tableau-viz'
        src={wurl}
        width='100%'
        height='calc(100vh - 52px)'
        hide-tabs
        toolbar='bottom'
        token={token || ''}
      />

      <div className='apex-chart-container'>
        <DashboardCharts />
      </div>
    </div>
  )
}
