import { useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton, Typography } from '@mui/material'
import ModalDialog from '@common/components/ModalDialog/ModalDialog'
import './shortcuts.scss'

const shortcuts = [
  {
    title: 'Play/Pause',
    buttons: [
      <span key={1} className='shortcut-button'>
        Space
      </span>,
    ],
    children: [],
  },
  {
    title: '10s backward',
    buttons: [
      <span key={1} className='shortcut-button'>
        Ctrl
      </span>,
      <span key={2} className='shortcut-button'>
        <ArrowBackRoundedIcon fontSize='small' className='shortcut-icon' />
      </span>,
    ],
    children: [],
  },
  {
    title: 'Change seeking mode',
    buttons: [
      <span key={1} className='shortcut-button'>
        Shift
      </span>,
    ],
    children: [
      {
        title: 'Frame/tag backward',
        buttons: [
          <span key={1} className='shortcut-button'>
            <ArrowBackRoundedIcon fontSize='small' className='shortcut-icon' />
          </span>,
        ],
      },
      {
        title: 'Frame/tag forward',
        buttons: [
          <span key={1} className='shortcut-button'>
            <ArrowForwardRoundedIcon
              fontSize='small'
              className='shortcut-icon'
            />
          </span>,
        ],
      },
    ],
  },

  {
    title: 'Create a tag',
    buttons: [
      <span key={1} className='shortcut-button'>
        M
      </span>,
    ],
    children: [],
  },
]

export function Shortcuts() {
  const [keyboardShortcuts, setKeyboardShortcuts] = useState(false)

  return (
    <>
      <IconButton onClick={() => setKeyboardShortcuts(true)}>
        <InfoIcon sx={{ color: 'whitesmoke' }} />
      </IconButton>
      <ModalDialog
        open={keyboardShortcuts}
        title='Keyboard Shortcuts'
        onClose={() => setKeyboardShortcuts(false)}
      >
        {shortcuts.map((shortcut) => (
          <Typography
            key={shortcut.title}
            variant='body1'
            color='whitesmoke'
            mt={2}
          >
            {shortcut.title}: {shortcut.buttons.map((button) => button)}
            {shortcut.children.length > 1 && (
              <div
                style={{
                  marginLeft: 4,
                  paddingLeft: 8,
                  borderLeft: '1px solid #aeaeae',
                }}
              >
                {shortcut.children.map((child) => (
                  <Typography
                    key={shortcut.title}
                    variant='body1'
                    color='whitesmoke'
                    mt={2}
                  >
                    {child.title}: {child.buttons.map((button) => button)}
                  </Typography>
                ))}
              </div>
            )}
          </Typography>
        ))}
      </ModalDialog>
    </>
  )
}
