import axios from 'axios'
import { getToken } from '@modules/auth'
import { getProjectID, getEmail, getClientID } from '@common/utils/storage'
import { removeTrailingSlash } from '@common/utils/formatting'

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND_AUTH,
})

export const grafanaBaseUrl = removeTrailingSlash(
  process.env.REACT_APP_URL_GRAFANA ?? ''
)

axios.defaults.baseURL = process.env.REACT_APP_URL_BACKEND_WEB

axios.interceptors.request.use((cfg) => {
  cfg.headers.Authorization = `Bearer ${getToken()}`
  return cfg
})

export interface EndPointDic {
  [key: string]: string
}

export interface BaseAuthorizationData {
  email: string | null
  clientId: string | null
  projectId: string | null
}

export interface BaseAuthExcludeProject {
  email: string | null
  clientId: string | null
}

export const getBaseAuthData = (): BaseAuthorizationData => ({
  email: getEmail(),
  clientId: getClientID(),
  projectId: getProjectID(),
})
