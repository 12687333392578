import { DelegateType } from '@api/sprGallery/spr-gallery'
import { SPR_DAYS_OPTIONS } from '@modules/gallery'
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import './styles.scss'

type SidebarProps = {
  day: number
  frameId?: number
  dtid?: number
  delegateType: DelegateType
}

export function Sidebar({ day, frameId, dtid, delegateType }: SidebarProps) {
  const [, setSearchParams] = useSearchParams()

  const isDelegateView = !frameId || !dtid

  const labelStyle = (isActive = false) => ({
    transition: 'all 0.13s ease-in',
    fontSize: '1rem',
    color: 'white',
    opacity: isActive ? 1 : 0.6,
    ':hover': {
      opacity: '0.9',
    },
  })

  const handleDayChange = (day: number) => {
    setSearchParams((sp) => {
      sp.set('day', String(day))
      sp.delete('dtid')
      sp.delete('frameId')
      sp.delete('prevDelegatePage')
      sp.set('page', '1')

      return sp
    })
  }

  const handleTypeChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSearchParams((sp) => {
      sp.set('delegateType', value)
      sp.delete('page')
      return sp
    })
  }

  return (
    <div className='spr-gallery-sidebar'>
      <FormControl>
        <FormLabel>
          <Typography fontSize={'0.8rem'}>Days</Typography>
        </FormLabel>
        <ButtonGroup
          orientation='vertical'
          variant='text'
          sx={{ whiteSpace: 'nowrap' }}
        >
          {SPR_DAYS_OPTIONS.map((current) => (
            <Button
              key={current}
              sx={{
                px: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                color: day === current ? '#fff' : '#BBB',
                ':hover': { color: '#fff' },
              }}
              onClick={() => handleDayChange(current)}
            >
              <Typography fontSize={'0.85rem'}>Day {current}</Typography>
            </Button>
          ))}
        </ButtonGroup>
      </FormControl>

      {isDelegateView ? (
        <>
          <FormControl>
            <FormLabel>
              <Typography fontSize={'0.8rem'}>Type</Typography>
            </FormLabel>
            <RadioGroup
              onChange={handleTypeChange}
              value={delegateType}
              defaultValue={DelegateType.RARE}
              name='radio-buttons-group'
            >
              <FormControlLabel
                value={DelegateType.RARE}
                control={<Radio size='small' />}
                label={
                  <Typography
                    fontSize={'0.85rem'}
                    sx={labelStyle(delegateType === DelegateType.RARE)}
                  >
                    Rare
                  </Typography>
                }
              />
              <FormControlLabel
                value={DelegateType.COMMON}
                control={<Radio size='small' />}
                label={
                  <Typography
                    fontSize={'0.85rem'}
                    sx={labelStyle(delegateType === DelegateType.COMMON)}
                  >
                    Common
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </>
      ) : null}
    </div>
  )
}
