import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useHandleCloseImageModal = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(() => {
    setSearchParams((sp) => {
      sp.delete('selectedIndex')
      return sp
    })
  }, [setSearchParams])
}
