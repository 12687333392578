import { useProject } from '@api/table/projects'
import { getProjectID } from '@common/utils/storage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem } from '@mui/material'
import React, { forwardRef, useEffect, useState } from 'react'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'

export const ProjectPicker = forwardRef<HTMLSpanElement>(
  function ProjectPicker(_, ref) {
    const { data: projectData } = useProject()
    const [programAnchorEl, setProgramAnchorEl] = useState<HTMLElement>()
    const { projectID, setProjectID } = useReportContext()

    useEffect(() => {
      if (projectData && projectData.length > 0) {
        if (getProjectID() === null) {
          window.localStorage.setItem(
            'projectID',
            projectData[0].projectID.toString()
          )
          if (ref && 'current' in ref && ref.current) {
            ref.current.textContent = 'Program: ' + projectData[0].projectName
          }
          setProjectID(projectData[0].projectID.toString())
        } else {
          if (projectID) {
            const projectLocalStorageData = projectData.filter(
              (project) => project.projectID === parseInt(getProjectID()!)
            )
            if (ref && 'current' in ref && ref.current) {
              ref.current.textContent =
                'Program: ' + projectLocalStorageData[0].projectName
            }
          }
        }
      }
    }, [projectData])

    const handleCloseProgram = () => {
      setProgramAnchorEl(undefined)
    }

    const handleChosenProgramClick = (
      projectName: string,
      projectID: number
    ) => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.textContent = 'Program: ' + projectName
      }
      setProjectID(projectID.toString())
      window.localStorage.setItem('projectID', projectID.toString())
      handleCloseProgram()
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
      setProgramAnchorEl(event.currentTarget)

    return (
      <div key={'Programs'} style={{ position: 'relative' }}>
        <Button
          data-testid={'Programs'}
          sx={{
            minWidth: 0,
            textTransform: 'capitalize',
            p: 0,
            color: '#fff',
            '&:hover': {
              color: '#fff',
            },
          }}
          onClick={handleClick}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          {'Programs'}
          <ExpandMoreIcon fontSize='small' />
        </Button>
        <Menu
          anchorEl={programAnchorEl}
          open={Boolean(programAnchorEl)}
          onClose={handleCloseProgram}
        >
          {projectData?.map((item) => (
            <MenuItem
              data-testid={`${item.projectName}-${item.projectID}`}
              sx={{ textTransform: 'capitalize' }}
              key={item.projectID}
              onClick={() =>
                handleChosenProgramClick(item.projectName, item.projectID)
              }
            >
              {item.projectName}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
)

export default ProjectPicker
