import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { HilVersion } from '../../models/table'
import { urls } from '../urls'

export function useHilVersion() {
  return useQuery({
    staleTime: Infinity,
    queryKey: ['hil'],
    queryFn: async (): Promise<HilVersion> => {
      const resp = await axios.get(urls.hilVersions)
      return resp.data
    },
  })
}
