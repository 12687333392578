import { PaginationItem, PaginationRenderItemParams } from '@mui/material'
import { PageSize } from './PageSize'
import { StyledPagination } from './StyledComponentsTable'
import { useReportContext } from '../providers/ReportContextProvider'

const getTestId = (item: PaginationRenderItemParams) => {
  let testId: string

  switch (item.type) {
    case 'first':
      testId = 'pagination-first-page'
      break
    case 'last':
      testId = 'pagination-last-page'
      break
    case 'previous':
      testId = 'pagination-previous-page'
      break
    case 'next':
      testId = 'pagination-next-page'
      break
    default:
      testId = `pagination-page-${item.page}`
      break
  }

  return testId
}

export function Pagination({ pageCount }: { pageCount: number }) {
  const { page, setPage } = useReportContext()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      }}
    >
      <PageSize />
      <StyledPagination
        data-testid={`pagination-all-${page}`}
        size='small'
        shape='rounded'
        count={pageCount}
        page={page}
        onChange={(_, value: number) => setPage(value)}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem {...item} data-testid={getTestId(item)} />
        )}
      />
    </div>
  )
}
