import { useMutation } from '@tanstack/react-query'
import { urls } from './urls'
import { authApi } from './base'
import { AxiosResponse } from 'axios'

interface CreateLogoutInfoRequest {
  email: string
  reason: number
}

export enum REASON_ENUM {
  GOOGLE_LOGIN = 100,
  MS_LOGIN = 101,
  PASWORD_LOGIN = 102,
  JIRA_LOGIN = 103,
  EXPLICIT_LOGOUT = 200,
  TIMEOUT_LOGOUT = 201,
  TOKEN_EXPIRED = 202,
  PROJECT_NOT_AUTHORIZED = 203,
}

export const useCreateLogoutInfoMutation = () => {
  const {
    mutateAsync: createLogoutInfoMutation,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['createLogoutInfoMutation'],
    mutationFn: (
      data: CreateLogoutInfoRequest
    ): Promise<AxiosResponse<string>> => {
      return authApi.post(urls.logoutInfo, data)
    },
  })

  return { createLogoutInfoMutation, data, isPending }
}
