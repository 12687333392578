import { NLGalleryItem, useNLGalleryQuery } from '@api/nlGallery/nl-gallery'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useHandleImageClick = () => {
  const { data } = useNLGalleryQuery()
  const [, setSearchParams] = useSearchParams()

  return useCallback(
    (item: NLGalleryItem) => {
      const selectedIndex = data.findIndex(
        (i) => i.dtid === item.dtid && i.frameId === item.frameId
      )

      setSearchParams((sp) => {
        sp.set('selectedIndex', String(selectedIndex))
        return sp
      })
      return
    },
    [data, setSearchParams]
  )
}
