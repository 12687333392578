import { LineType } from '@api/objects'
import { toMilliseconds } from '@common/utils/time'
import { TimedData } from '@modules/videoViewport'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext } from 'react'
import { urls } from '../urls'

const FETCH_OFFSET = 5
const FETCH_RANGE = 35

export interface DataFrame {
  dtid: number
  timestamp: number
  lon: number
  lat: number
  x: number
  y: number
  z: number
  heading: number
  leftConfidence: number
  leftC0: number
  leftC1: number
  leftC2: number
  leftC3: number
  leftStart: number
  leftEnd: number
  rightConfidence: number
  rightC0: number
  rightC1: number
  rightC2: number
  rightC3: number
  rightStart: number
  rightEnd: number
  leftAvailability: number
  rightAvailability: number
  leftConfidenceGT: number
  rightConfidenceGT: number
  leftC0GT: number
  rightC0GT: number
}

export type TimestampDataFrameMap = Record<number, DataFrame>

const calculateStartEndTime = (
  startDate: number,
  offset: number,
  time?: number
) => {
  const startTime = time
    ? toMilliseconds(startDate + Math.floor(time - offset - FETCH_OFFSET))
    : toMilliseconds(startDate)
  const endTime = time
    ? toMilliseconds(startDate + Math.floor(time - offset) + FETCH_RANGE)
    : toMilliseconds(startDate + FETCH_RANGE)

  return {
    startTime,
    endTime,
  }
}

export const useDriveTrialEgoQuery = (lineType: LineType, time?: number) => {
  // we will always have this data since this call is in the `View3DBuffer`
  // component which is conditionally rendered and depends on this data
  const { getCurrentDriveTrial } = useDriveTrialContext()
  const { timingObj } = useContext(MediaSyncContext)
  const currentTime = timingObj?.pos
  const activeVideo = getCurrentDriveTrial(currentTime)

  return useQuery({
    staleTime: Infinity,
    retryOnMount: false,
    queryKey: ['driveTrialEgo', activeVideo, lineType, time],
    queryFn: async (): Promise<TimedData<DataFrame>> => {
      const startDate = activeVideo!.startDate
      const offset = activeVideo!.previousDuration

      const { data } = await axios.get<TimedData<DataFrame>>(
        urls.driveTrialEgo.replace(':id', activeVideo!.DTID.toString()),
        {
          params: {
            ...calculateStartEndTime(startDate, offset, time),
            lineType,
          },
        }
      )

      return data
    },
  })
}
