import {
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  Scene,
} from 'three'
import { commonLineMaterial } from './constants'
import { LineType } from '../types'

export const calculateGeometry = (
  start: number,
  end: number,
  c0: number,
  c1: number,
  c2: number,
  c3: number,
  heading: number
): BufferGeometry => {
  const geometry = new BufferGeometry()
  const positions: number[] = []
  const cs = Math.cos(heading)
  const ss = Math.sin(heading)

  for (let x = start; x < end; x += 1) {
    const x2 = x * x
    const x3 = x2 * x
    const y = c0 + c1 * x + c2 * x2 + c3 * x3

    const xx = cs * x + ss * y
    const yy = -ss * x + cs * y

    positions.push(yy, 0, xx)
  }
  geometry.setAttribute('position', new Float32BufferAttribute(positions, 3))

  return geometry
}

export const showMeLane = (
  [c0, c1, c2, c3]: [number, number, number, number],
  color: Color,
  aLine?: Line
) => {
  const start = 0
  const end = 100

  const geometry = calculateGeometry(start, end, c0, c1, c2, c3, -Math.PI / 2)

  const material = new LineBasicMaterial({
    ...commonLineMaterial,
    color: color,
  })

  if (!aLine) {
    const points = new Line(geometry, material)
    points.computeLineDistances()
    return points
  }

  aLine.geometry = geometry
  aLine.computeLineDistances()
  return aLine
}

export const modifyMeLine = (
  el: [number, number, number, number],
  color: Color,
  lineType: LineType,
  scene: Scene,
  setter: (object: Line) => void,
  line?: Line
) => {
  if (!line) {
    const aLine = showMeLane(el, color, line)
    aLine.name = lineType
    scene && scene.add(aLine)
    setter(aLine)
  }
}
