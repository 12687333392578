import { NLGalleryItem } from '@api/nlGallery/nl-gallery'
import { ImageWithPlaceholder } from '@common/components/ImageWithPlaceholder'

type ImageItemProps = {
  item: NLGalleryItem
  handleClick: (item: NLGalleryItem) => void
}

export const ImageItem = ({ item, handleClick }: ImageItemProps) => {
  return (
    <div className='image-container' onClick={() => handleClick(item)}>
      <ImageWithPlaceholder
        className='image'
        src={item.url}
        alt={item.dtid.toString()}
        placeholder={'/gallery-placeholder.png'}
      />
      <div className='image-overlay'></div>
    </div>
  )
}
