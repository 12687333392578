import './style.scss'

interface IProps {
  min?: number
  max: number
  onRender?: (node: HTMLInputElement) => void
  sliderRef: React.MutableRefObject<HTMLInputElement | null>
}

export const Slider = ({ max, min, onRender, sliderRef }: IProps) => (
  <input
    data-testid='contorlsSlider'
    defaultValue={0}
    className='slider-range-input'
    type='range'
    step={'any'}
    min={min}
    max={max}
    aria-label='Default'
    onFocus={(e) => e.currentTarget.blur()}
    ref={(node) => {
      sliderRef.current = node

      if (onRender && node) {
        onRender(node)
      }
    }}
  />
)
