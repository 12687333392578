import {
  BufferAttribute,
  BufferGeometry,
  Float32BufferAttribute,
  InterleavedBufferAttribute,
  Line,
  LineBasicMaterial,
  Scene,
} from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import { whiteColor } from './colors'

const generateGridLineLabel = (
  scene: Scene,
  isLeft: boolean,
  text: string,
  id: string,
  position: BufferAttribute | InterleavedBufferAttribute
) => {
  const realId = `${id}-${isLeft ? 'left' : 'right'}`
  const markerDiv = document.createElement('div')

  markerDiv.id = realId
  markerDiv.textContent = text
  markerDiv.style.backgroundColor = 'transparent'
  markerDiv.style.color = 'white'
  markerDiv.style.fontSize = '15px'
  markerDiv.style.textIndent = isLeft ? '-2em' : '2em'

  const markerLabel = new CSS2DObject(markerDiv)
  markerLabel.layers.set(0)
  markerLabel.name = realId
  markerLabel.position.set(
    position.getX(isLeft ? 0 : 1),
    position.getY(isLeft ? 0 : 1),
    position.getZ(isLeft ? 0 : 1)
  )

  const objectToRemove = scene && scene.getObjectByName(realId)
  if (objectToRemove) {
    scene.remove(objectToRemove)
  }

  const oldLabel = document.getElementById(realId)
  if (oldLabel && oldLabel.parentElement) {
    oldLabel.parentElement.removeChild(oldLabel)
  }

  return markerLabel
}

export const drawGridMarkers = (
  scene: Scene,
  X: number,
  Y: number,
  heading: number
) => {
  const cs = Math.cos(heading)
  const ss = Math.sin(heading)
  const markers = [30, 60, 90]

  for (const i in markers) {
    const y = markers[i]
    const x0 = -8
    const x1 = 8
    const xx0 = X + cs * x0 + ss * y
    const yy0 = Y + -ss * x0 + cs * y
    const xx1 = X + cs * x1 + ss * y
    const yy1 = Y + -ss * x1 + cs * y
    const positions: number[] = []
    positions.push(yy0, 0, xx0)
    positions.push(yy1, 0, xx1)

    const markerText = `${markers[i]}m`
    const id = `marker-${markers[i]}-m`

    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3))
    const material = new LineBasicMaterial({
      color: whiteColor,
      linewidth: 2,
    })
    const line = new Line(geometry, material)
    line.name = `grid-line-${i}`

    const oldLine = scene && scene.getObjectByName(`grid-line-${i}`)
    if (oldLine) {
      scene.remove(oldLine)
    }

    const positionGeo = geometry.getAttribute('position')
    line.add(generateGridLineLabel(scene, true, markerText, id, positionGeo)) //Left label
    line.add(generateGridLineLabel(scene, false, markerText, id, positionGeo)) //Right label
    scene && scene.add(line)
  }
}
