import { Sparklines, SparklinesBars, SparklinesLine } from 'react-sparklines'
import { match } from 'ts-pattern'

export type SparklineVariant = 'bars' | 'line'

interface IProps {
  data: number[]
  variant: SparklineVariant
  min?: number
  max?: number
  color: string
  svgHeight?: string | number | undefined
  svgWidth?: string | number | undefined
  minWidth?: string | number | undefined
  maxWidth?: string | number | undefined
}

export const Sparkline = ({
  data,
  variant,
  min,
  max,
  color,
  svgHeight,
  svgWidth,
  minWidth,
  maxWidth,
}: IProps) => (
  <Sparklines
    data={data}
    min={min}
    max={max}
    svgHeight={svgHeight}
    svgWidth={svgWidth}
    style={{ maxWidth: maxWidth, minWidth: minWidth }}
  >
    {match(variant)
      .with('bars', () => <SparklinesBars style={{ fill: color }} />)
      .with('line', () => (
        <SparklinesLine color={color} style={{ strokeWidth: 3, fill: color }} />
      ))
      .exhaustive()}
  </Sparklines>
)
