import DownloadIcon from '@mui/icons-material/Download'
import { IconButton, Tooltip } from '@mui/material'
import { Column, Row } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { csvAndPdfTransformer } from './csvTransformers'
import { TableRouteParams } from '@common/constants/paths'
import { enUS } from '@common/constants/messages'
import { ReportType } from '../../pages/Report/types'

interface CsvDownloadProps {
  visibleColumns: Column<any, any>[]
  bodyRows: Row<any>[]
}

export const createCsvFile = (
  visibleColumns: Column<any, any>[],
  bodyRows: Row<any>[],
  reportType: ReportType | undefined
): Blob | null => {
  const leafHeaders = visibleColumns.filter(
    (col) => col.parent && !col.id.includes('select')
  )
  const headers = leafHeaders.map((col) => col.columnDef.header).join(',')
  const superHeaders = leafHeaders
    .map((col) => col.parent?.columnDef.header)
    .join(',')
  const data = bodyRows
    .map((row) =>
      row
        ._getAllVisibleCells()
        .filter((cell) => !cell.id.includes('select'))
        .map((cell) => csvAndPdfTransformer(cell, reportType))
        .join(',')
    )
    .join('\n')

  return new Blob([`${superHeaders}\n${headers}\n${data}`], {
    type: 'text/csv;charset=utf-8,',
  })
}

export const CsvDownload = ({ bodyRows, visibleColumns }: CsvDownloadProps) => {
  const { reportType } = useParams<TableRouteParams>()
  const csvBlob = createCsvFile(visibleColumns, bodyRows, reportType)
  const href = !csvBlob ? '#' : URL.createObjectURL(csvBlob)

  return (
    <Tooltip title={enUS.DOWNLOAD_CSV} placement='top' arrow>
      <a
        data-testid='exportCSV'
        download={`${dayjs().utc().format('DD-MM-YYYY HH:mm')}.csv`}
        href={href}
      >
        <IconButton>
          <DownloadIcon fontSize='medium' sx={{ color: 'white' }} />
        </IconButton>
      </a>
    </Tooltip>
  )
}
