import { AlwaysDepth, NormalBlending } from 'three'

export const commonLineMaterial = {
  transparent: true,
  opacity: 0.5,
  depthFunc: AlwaysDepth,
  blending: NormalBlending,
  linewidth: 4,
}

export const NFSPosition = {
  x: -7.080362981962773,
  y: 3.317541816541957,
  z: 0.033981466670125374,
}
