import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material'
import { SearchField } from '../SearchField'
import { useHandleSuggestionClick } from 'pages/NlGallery/hooks/useHandleSuggestionClick'
import { SUGGESTED_SEARCHES } from 'pages/NlGallery/constants'

export const InitialSearchView = () => {
  const handleSuggestionClick = useHandleSuggestionClick()

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ color: 'white', lineHeight: 1, mb: 4 }} variant='h3'>
          Search
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mb: 5,
          }}
        >
          {SUGGESTED_SEARCHES.map((e) => (
            <Card
              sx={{
                color: 'white',
                maxWidth: '150px',
                boxShadow: 'none',
                borderRadius: '10px',
                border: '1px solid white',
                opacity: 0.8,
                cursor: 'pointer',
                transition: 'all 0.1s ease-in',

                ':hover': {
                  background: '#7683a1',
                },
              }}
              key={e}
            >
              <CardActionArea
                onClick={() => handleSuggestionClick(e.toLowerCase())}
              >
                <CardContent
                  sx={{
                    paddingBottom: '16px !important',
                    fontSize: '1rem',
                  }}
                >
                  {e}
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>

        <SearchField />
      </Box>
    </Box>
  )
}
