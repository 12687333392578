import { ITimelineContext } from '@pages/Details/providers/TimelineContextProvider'
import { TimelineEventData } from 'components/VideoTimeline/types'
import dayjs from 'dayjs'
import { Timeline } from 'otto-vis-timeline'
import { MutableRefObject } from 'react'

export const onTimeClicked = (
  data: TimelineEventData,
  clickPosition: number,
  onTimeClick: (date: number) => void
) => {
  const seek = data.pageX === clickPosition

  if (seek) {
    const time = dayjs(data.time as Date)
    onTimeClick(time.valueOf())
  }
}

export const mouseDownHandler = (
  data: TimelineEventData,
  timeline: Timeline | null,
  clickPosition: MutableRefObject<number | undefined>
) => {
  if (timeline && data.pageX && data.event.button !== 2) {
    clickPosition.current = data.pageX
  }
}

export const mouseUpHandler = (
  data: TimelineEventData,
  clickPosition: MutableRefObject<number | undefined>,
  onTimeClick: ((time: number) => void) | undefined,
  timelineContext: ITimelineContext
) => {
  if (data.what === 'group-label') {
    return
  }

  const isTimeChange = data.what === 'background'
  const isExpandSectionClicked = data.event.target.className
    .toString()
    .includes('vis-nesting-group')
  if (!isExpandSectionClicked) {
    if (isTimeChange && onTimeClick) {
      onTimeClicked(data, clickPosition.current as number, onTimeClick)
    }
  }
  if (data.customTime?.toString() === 'startMarker') {
    timelineContext.setStartMarker!(new Date(data.time).getTime())
  }
}
