import { getClientID, getProjectID } from '@common/utils/storage'

export const DEFAULT_PAYLOAD_LANES_KPI = {
  clientId: getClientID(),
  projectId: getProjectID(),
  speedRange: '',
  lane: "['EGO', 'Adjacent']",
  side: "['Left', 'Right']",
  kpi: "['LanePresent','TypeClassificationRate','LateralPosition-30m','LateralPosition-60m','LateralPosition-100m','LateralPosition-150m','ColorHostLaneBoundaries-White', 'ColorHostLaneBoundaries-Yellow']",
}

export const DEFAULT_PAYLOAD_SIGNS_KPI = {
  clientId: getClientID(),
  projectId: getProjectID(),
  speedRange: '',
  speed:
    "[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 'All Speeds']",
}

export const DEFAULT_PAYLOAD_LIGHTS_KPI = {
  clientId: getClientID(),
  projectId: getProjectID(),
  kpi: "['GT', 'TP', 'FP', 'FN', 'Accuracy', 'Recall']",
}
