import { NL_ITEM_LIMIT } from '@modules/gallery'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { useDriveTrialDescriptions } from '../driveTrial/descriptions'
import { urls } from '../urls'

export interface NLGalleryItem {
  dtid: number
  frameId: number
  url: string
  gain: number
  description?: string
}

interface NLGalleryResponse {
  data: NLGalleryItem[]
  total: number
}

export const useNLGalleryQuery = () => {
  const { projectID } = useReportContext()
  const [searchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || 1)
  const queryString = searchParams.get('nlQuery')

  const descriptionsQuery = useDriveTrialDescriptions()

  const nlQuery = useQuery({
    enabled: Boolean(queryString),
    queryKey: ['nl-gallery', projectID, queryString, page],
    staleTime: Infinity,
    retry: false, // It should fail when it fails in the API
    queryFn: (): Promise<NLGalleryResponse> =>
      axios
        .post<NLGalleryResponse>(urls.nlGallery, {
          page,
          limit: NL_ITEM_LIMIT,
          nlQuery: queryString,
        })
        .then(({ data }) => data),
  })

  const data = useMemo(() => {
    if (nlQuery.data?.data && descriptionsQuery.data) {
      return nlQuery.data.data.map((item) => ({
        ...item,
        description: descriptionsQuery.data[item.dtid],
      }))
    }

    return nlQuery.data?.data || []
  }, [descriptionsQuery.data, nlQuery.data])

  return useMemo(
    () => ({
      data,
      isError: nlQuery.isError,
      isLoading: nlQuery.isLoading,
      error: nlQuery.error,
      count: Math.ceil((nlQuery.data?.total || 1) / NL_ITEM_LIMIT),
      page,
    }),
    [
      data,
      nlQuery.isError,
      nlQuery.isLoading,
      nlQuery.error,
      nlQuery.data?.total,
      page,
    ]
  )
}
