import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  SelectChangeEvent,
} from '@mui/material'
import { camelCase } from 'lodash'
import { SelectData } from '../types'

interface IProps {
  title: string
  label?: string | undefined
  options: SelectData[]
  value?: string | number | undefined
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  onChange?: (event: SelectChangeEvent) => void
  maxWidth?: number
  minWidth?: number
  styledFirstChild?: boolean
  size?: 'small' | 'medium'
}

export const Select = ({
  title,
  label,
  options,
  value = '',
  styledFirstChild = false,
  variant,
  maxWidth,
  minWidth = 150,
  onChange,
  size,
}: IProps) => {
  const onSelectFilterChange = (event: SelectChangeEvent) => {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <FormControl variant='outlined'>
      <InputLabel id={`select-${title}-label`}>{label ?? title}</InputLabel>
      <MUISelect
        variant={variant}
        id={`select-${title}`}
        label={title}
        labelId={`select-${title}-label`}
        value={value?.toString()}
        sx={{ minWidth, maxWidth }}
        onChange={onSelectFilterChange}
        size={size}
      >
        {options.map((x, i) => {
          const dataTestId = `filter-${camelCase(title)}-${camelCase(x.text)}`
          return (
            <MenuItem key={x.value} value={x.value} data-testid={dataTestId}>
              {i === 0 && styledFirstChild ? (
                <em style={{ color: 'rgba(255,255,255,0.5)' }}>{x.text}</em>
              ) : (
                x.text
              )}
            </MenuItem>
          )
        })}
      </MUISelect>
    </FormControl>
  )
}
