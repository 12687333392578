import { useRef } from 'react'
import { Outlet } from 'react-router-dom'

const GalleryContextLayout = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null)

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        ref={tableContainerRef}
        style={{
          padding: '2px 0 0 0',
          height: '100%',
        }}
      >
        <Outlet />
      </div>
    </div>
  )
}

export default GalleryContextLayout
