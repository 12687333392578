import { Feature } from 'ol'
import OlGeoJSON from 'ol/format/GeoJSON'
import { Point } from 'ol/geom'
import { Projection, fromLonLat } from 'ol/proj'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import { source, view } from './initMap'
import { GeoJSONObject } from './types'

export const createLineStyle = (color: string, width: number) => {
  const ret = () => {
    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    })

    return style
  }

  return ret
}

export const createPointStyle = (id: string, color: string, width: number) => {
  const style = new Style({
    text: new Text({
      text: '' + id,
      fill: new Fill({ color: 'white' }),
      stroke: new Stroke({ color: color, width: width }),
    }),
  })

  return style
}

export const checkMarkerVisibility = (marker: Point) => {
  const markerPosition = marker.getCoordinates()
  const viewExtent = view.calculateExtent()

  if (
    markerPosition[0] <= viewExtent[0] ||
    markerPosition[0] >= viewExtent[2] ||
    markerPosition[1] <= viewExtent[1] ||
    markerPosition[1] >= viewExtent[3]
  ) {
    view.animate({
      duration: 150,
      center: markerPosition,
    })
  }
}

export const showJSON = (geojsonObject: GeoJSONObject) => {
  const interpolatedCoords = geojsonObject.features.find(
    (x) => x.properties?.layer === 'interpolated_gps'
  )?.geometry.coordinates

  if (!interpolatedCoords) return

  const features = new OlGeoJSON().readFeatures(geojsonObject, {
    featureProjection: new Projection({ code: 'EPSG:3857' }),
  })

  const interpolatedFeature = features.find(
    (x) => x.getProperties().layer === 'interpolated_gps'
  )

  if (interpolatedFeature) {
    interpolatedFeature.setId('' + Date.now())
    interpolatedFeature.setStyle(createLineStyle('#ff0000', 3))
    source.addFeature(interpolatedFeature)
  }

  const coords20Hz = []

  for (let i = 0; i < interpolatedCoords.length - 1; i++) {
    const vv = interpolatedCoords[i]
    const cc = [vv[0], vv[1]]
    const point = fromLonLat(cc)
    const point_thing = new Point(point)

    coords20Hz.push([point[0], point[1]])

    if (i % 20 === 0) {
      const id = i + '' + Date.now()
      const pfeaturething = new Feature({
        name: 'Point',
        obj: id,
        geometry: point_thing,
      })
      pfeaturething.setId(id)
      if (i % 100 === 0)
        pfeaturething.setStyle(createPointStyle('+', '#00ff00', 1))
      else pfeaturething.setStyle(createPointStyle('-', '#00ff00', 1))

      source.addFeature(pfeaturething)
    }
  }

  const layers = features.map((feature) => {
    if (
      feature.getProperties().color &&
      feature.getProperties().layer !== 'interpolated_gps'
    ) {
      feature.setStyle(createLineStyle(feature.getProperties().color, 2))
    }
    return feature
  })

  source.addFeatures(layers)

  return coords20Hz
}

export const handleMarkerPosition = (pointThing: Point) => {
  const id = 'marker'
  const mapMarker = source.getFeatureById(id)

  if (mapMarker) {
    source.removeFeature(mapMarker)
  }

  const pfeaturething = new Feature({
    name: 'Point',
    obj: id,
    geometry: pointThing,
  })

  pfeaturething.setId(id)
  pfeaturething.setStyle(createPointStyle('O', 'blue', 8))
  source.addFeature(pfeaturething)

  checkMarkerVisibility(pointThing)
}
