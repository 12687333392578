import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from '../urls'

export interface Changelog {
  releaseDate: string
  logData: string
  version: string
}

export interface Changelogs {
  changelog: Changelog[]
}

export const useChangelogQuery = () => {
  return useQuery({
    queryKey: ['changelog'],
    staleTime: Infinity,
    queryFn: (): Promise<Changelogs> =>
      axios.get<Changelogs>(urls.changelog).then(({ data }) => data),
  })
}
