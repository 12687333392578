import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { SnackbarKey } from 'notistack'

interface CloseSnackbarProps {
  id: SnackbarKey
  closeSnackbar: (id: SnackbarKey) => void
}

const CloseSnackbarAction = ({ id, closeSnackbar }: CloseSnackbarProps) => {
  return (
    <IconButton
      style={{
        color: 'whitesmoke',
        borderRadius: 0,
        borderLeft: '1px solid #b4b4b4',
      }}
      onClick={() => {
        closeSnackbar(id)
      }}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  )
}

export default CloseSnackbarAction
