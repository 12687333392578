import { MouseEvent, ReactElement } from 'react'
import { Box, MenuItem as MUIMenuItem } from '@mui/material'

interface IProps {
  onClick?: () => void
  onMouseEnter?: (e: MouseEvent<HTMLElement>) => void
  onMouseLeave?: (e: MouseEvent<HTMLElement>) => void
  closeHandler?: () => void
  dataTestId?: string
  children: ReactElement | ReactElement[]
  isDisabled?: boolean
  selected?: boolean
}

export const MenuItem = ({
  children,
  closeHandler,
  dataTestId,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isDisabled,
  selected,
}: IProps) => {
  return (
    <Box>
      <MUIMenuItem
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '2px',
          '&&.MuiMenuItem-root.Mui-selected': {
            backgroundColor: '#606c88',
          },
        }}
        data-testid={dataTestId}
        selected={selected}
        onClick={() => {
          if (onClick) {
            onClick()
          }

          if (closeHandler) {
            closeHandler()
          }
        }}
        onMouseEnter={(e) => {
          if (onMouseEnter) {
            onMouseEnter(e)
          }
        }}
        onMouseLeave={(e) => {
          if (onMouseLeave) {
            onMouseLeave(e)
          }
        }}
        disabled={isDisabled}
      >
        {children}
      </MUIMenuItem>
    </Box>
  )
}
