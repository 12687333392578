export const DEFAULT_LAYERS_DATA = [
  {
    name: 'OTTO LAYERS',
    categories: [
      {
        name: 'Vehicle',
        options: [
          {
            name: 'ActiveCarColor',
            color: '',
            isChecked: true,
          },
          {
            name: 'ActiveTruckColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveBusColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveMotorcycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Traffic Signs',
        options: [
          {
            name: 'ActiveSignColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'Stop',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveTrafficLightColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Pedestrians',
        options: [
          {
            name: 'ActivePedColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveCycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Lane',
        options: [
          {
            name: 'ActiveEgoLineColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveEgoLineClass',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneClass',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'System',
        options: [{ name: 'ActiveTtdColor', color: '', isChecked: false }],
      },
    ],
  },
  {
    name: 'VEHICLE LAYERS',
    categories: [
      {
        name: 'Vehicle',
        options: [
          {
            name: 'ActiveCarColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveTruckColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveBusColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveMotorcycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Traffic Signs',
        options: [
          {
            name: 'ActiveSignColor',
            color: '9E0067',
            isChecked: false,
          },
          {
            name: 'Stop',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveTrafficLightColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Pedestrians',
        options: [
          {
            name: 'ActivePedColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveCycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Lane',
        options: [
          {
            name: 'ActiveEgoLineColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveEgoLineClass',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneClass',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'System',
        options: [{ name: 'ActiveTtdColor', color: '', isChecked: false }],
      },
    ],
  },
]
