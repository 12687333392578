import { useTopViewMapQuery } from '@api/topViewMap'
import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { Loader } from '@common/components/Loader/Loader'
import { NoData } from '@common/components/NoData/NoData'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { Point } from 'ol/geom'
import { useEffect, useRef, useState } from 'react'
import { TOP_VIEW_MAP } from './initMap'
import { handleMarkerPosition, showJSON } from './utils'

interface ViewerMapProps {
  synchronizer?: ISynchronizer
  viewportId: number
}

export const TopViewMap = ({ viewportId, synchronizer }: ViewerMapProps) => {
  const { getCurrentDriveTrial, highlightMode } = useDriveTrialContext()
  const { data, isLoading, isFetching, isError } = useTopViewMapQuery()
  const mapElement = useRef<HTMLDivElement>(null)
  const [coordinates20Hz, setCoordinates20Hz] = useState<number[][]>([])

  useEffect(() => {
    if (isLoading) {
      synchronizer?.updateStatus(viewportId, false)
      return
    }

    synchronizer?.updateStatus(viewportId, true)
  }, [isLoading, synchronizer, viewportId])

  const handleTimeChange = (time: number) => {
    const activeDTID = getCurrentDriveTrial(time)

    if (coordinates20Hz.length === 0 || !activeDTID) {
      return
    }

    const calculateIndex = (percent: number) =>
      Math.round(coordinates20Hz.length * percent)

    const percent =
      highlightMode.id === -1
        ? (time - activeDTID.previousDuration) / activeDTID.duration
        : (activeDTID.originalStartTime +
            time -
            activeDTID.startTime -
            activeDTID.previousDuration) /
          (activeDTID.dtidDuration || 1)

    const index = calculateIndex(percent)
    const pointThing = new Point(coordinates20Hz[index])

    handleMarkerPosition(pointThing)
  }

  useActiveTrial(handleTimeChange)

  useEffect(() => {
    if (data) {
      const coords20Hz = showJSON(data)
      setCoordinates20Hz(coords20Hz!)

      const initialMap = TOP_VIEW_MAP
      initialMap.setTarget(mapElement.current || '')
    }
  }, [data, synchronizer, highlightMode.id])

  if (isLoading || isFetching) {
    return <Loader text='Loading top view map' center />
  }

  if (isError) {
    synchronizer?.updateStatus(viewportId, true)

    return <NoData languageCode='enUS' />
  }

  return (
    <div
      ref={mapElement}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    />
  )
}
