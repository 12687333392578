import { ViewportMenuObject } from '@api/clientViewportMenu'
import { isDevelopmentOrLocal } from '@common/constants/env'
import { PROJECT_IDS } from '@common/constants/projects'
import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'
import { isTimelineDisabled } from '@common/utils/viewports'
import { ObjectColor } from '@modules/videoViewport'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import PreviewIcon from '@mui/icons-material/Preview'
import { IconButton, Stack } from '@mui/material'
import ColorLegend from 'components/ColorLegend/ColorLegend'
import { MapOptionsRef, MarkerSourceRef } from 'components/Map/types'
import { MenuRef } from 'components/Menus/types'
import { ViewportMenu } from 'components/Menus/ViewportMenu/ViewportMenu'
import ObjectsTable from 'components/ObjectsTable/ObjectsTable'
import { CameraTools } from 'components/ViewportToolbarTools/CameraTools'
import { iconStyles } from 'components/ViewportToolbarTools/commonStyles'
import { MapTools } from 'components/ViewportToolbarTools/MapTools'
import { TimelineTools } from 'components/ViewportToolbarTools/TimelineTools'
import { TopDownTools } from 'components/ViewportToolbarTools/TopDownTools'
import { ILayerPanelItem } from '@pages/Details/types/layersPanel'
import { ViewportContent } from '@pages/Details/types/viewportContent'
import { useReportContext } from 'pages/Report/providers/ReportContextProvider'
import { PropsWithChildren, RefObject, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
import { P, match } from 'ts-pattern'
import { LayersPanel } from '../LayersPanel'
import './styles.scss'

interface ViewportToolbarProps {
  direction: 'row' | 'column'
  isFullscreen: boolean
  focusMap: boolean
  setFocusMap: React.Dispatch<React.SetStateAction<boolean>>
  layerPanelData?: ILayerPanelItem[]
  colors: ObjectColor[]
  currentContent: ViewportContent
  setContent: (content: ViewportContent) => void
  goFullscreen: React.MouseEventHandler<HTMLButtonElement>
  exitFullScreen: React.MouseEventHandler<HTMLButtonElement>
  mapRef: RefObject<MapRef>
  markerRef: RefObject<MarkerSourceRef>
  mapOptionsRef: RefObject<MapOptionsRef>
  viewportId: number
  menuOptions: ViewportMenuObject[]
  selectedDTID: number
  setSelectedDTID: React.Dispatch<React.SetStateAction<number>>
}

export const ViewportToolbar = ({
  direction,
  isFullscreen,
  setFocusMap,
  currentContent,
  layerPanelData,
  colors,
  setContent,
  goFullscreen,
  exitFullScreen,
  mapRef,
  viewportId,
  menuOptions,
  selectedDTID,
  setSelectedDTID,
  mapOptionsRef,
}: PropsWithChildren<ViewportToolbarProps>) => {
  const layerPanelRefSpawner = useRef<MenuRef>()
  const colorLegendRefSpawner = useRef<MenuRef>()
  const viewportSpawnerMenu = useRef<MenuRef>()
  const objectsTableSpawner = useRef<MenuRef>()
  const [timelineDisabled, setTimelineDisabled] = useState<boolean>(false)
  const { projectID } = useReportContext()

  const isDisabled =
    (+projectID === PROJECT_IDS.VOLVO && !isDevelopmentOrLocal) ||
    currentContent === 2 ||
    currentContent === 3

  const renderCameraTools = () => (
    <CameraTools
      viewportId={viewportId}
      layersHandler={(e) => {
        layerPanelRefSpawner.current?.spawn(e.currentTarget)
      }}
      legendHandler={(e) => {
        colorLegendRefSpawner.current?.spawn(e.currentTarget)
      }}
      objectsHandler={(e) => {
        objectsTableSpawner.current?.spawn(e.currentTarget)
      }}
      isDisabled={isDisabled}
    />
  )

  return (
    <>
      <Stack direction={direction} className={'toolbar-items-container'}>
        <StyledTooltip title='Change viewport'>
          <IconButton
            data-testid={`viewportMenu-${viewportId}`}
            onClick={(e) => {
              setTimelineDisabled(isTimelineDisabled())
              const dataTestId = e.currentTarget.getAttribute('data-testid')

              if (dataTestId) {
                const match = dataTestId.match(/viewportMenu-(\d+)/)
                if (match && match[1]) {
                  const number = match[1]
                  const localStorageViewports = JSON.parse(
                    localStorage.getItem('viewports') || '{}'
                  )
                  if (
                    localStorageViewports[number].content ===
                    ViewportContent.TIMELINE
                  )
                    setTimelineDisabled(false)
                }
              }

              viewportSpawnerMenu.current?.spawn(e.currentTarget)
            }}
          >
            <PreviewIcon sx={iconStyles} />
          </IconButton>
        </StyledTooltip>

        {!isFullscreen ? (
          <StyledTooltip title='Fullscreen'>
            <IconButton
              data-testid={`fullscreen-enter-${viewportId}`}
              onClick={goFullscreen}
            >
              <FullscreenIcon sx={iconStyles} />
            </IconButton>
          </StyledTooltip>
        ) : (
          <StyledTooltip title='Exit fullscreen'>
            <IconButton
              data-testid={`fullscreen-exit-${viewportId}`}
              onClick={exitFullScreen}
            >
              <FullscreenExitIcon sx={iconStyles} />
            </IconButton>
          </StyledTooltip>
        )}

        {match(currentContent)
          .with(ViewportContent.MAP, () => (
            <MapTools
              mapRef={mapRef}
              viewportId={viewportId}
              setFocusMap={setFocusMap}
              selectedDTID={selectedDTID}
              setSelectedDTID={setSelectedDTID}
              mapOptionsRef={mapOptionsRef}
            />
          ))
          .with(ViewportContent.TIMELINE, () => (
            <TimelineTools zoomStep={1} viewportId={viewportId} />
          ))
          .with(ViewportContent['3D_VIEW'], () => (
            <TopDownTools viewportId={viewportId} />
          ))
          .with(
            P.union(
              ViewportContent.FRONT_CAMERA,
              ViewportContent.LEFT_CAMERA,
              ViewportContent.REAR_CAMERA,
              ViewportContent.RIGHT_CAMERA
            ),
            renderCameraTools
          )
          .otherwise(() => (
            <></>
          ))}
      </Stack>
      <ViewportMenu
        setContent={setContent}
        closeHandler={() => viewportSpawnerMenu.current?.spawn(undefined)}
        ref={viewportSpawnerMenu}
        menuOptions={menuOptions}
        viewportId={viewportId}
        closeAllPopups={() => {
          layerPanelRefSpawner.current?.close()
          colorLegendRefSpawner.current?.close()
          objectsTableSpawner.current?.close()
        }}
        isTimelineDisabled={timelineDisabled}
      />
      {layerPanelData && (
        <LayersPanel
          viewportId={viewportId}
          ref={layerPanelRefSpawner}
          closeHandler={() => {
            layerPanelRefSpawner.current?.close()
          }}
          data={layerPanelData}
        />
      )}
      <ColorLegend
        ref={colorLegendRefSpawner}
        closeHandler={() => {
          colorLegendRefSpawner.current?.close()
        }}
        popupPosition='left'
        colors={colors}
      />
      <ObjectsTable
        popupPosition='right'
        ref={objectsTableSpawner}
        closeHandler={() => {
          objectsTableSpawner.current?.close()
        }}
      />
    </>
  )
}
