import { useChangelogQuery } from '@api/changelog/changelog'
import { REASON_ENUM } from '@api/logout'
import { enUS } from '@common/constants/messages'
import { About } from '@common/components/About/About'
import ModalDialog from '@common/components/ModalDialog/ModalDialog'
import { MenuItem } from '@common/components/PopupMenu/MenuItem/MenuItem'
import { PopupMenu } from '@common/components/PopupMenu/PopupMenu'
import { Logout as LogoutIcon } from '@mui/icons-material'
import UpdateIcon from '@mui/icons-material/Update'
import { Divider, ListItemIcon } from '@mui/material'
import { forwardRef, useState } from 'react'
import { useLogout } from '@modules/auth'
import { ChangeLog } from '../../ChangeLog/ChangeLog'
import { commonStyle, menuItemStyle, MenuProps } from '../types'

export const AccountMenu = forwardRef(function AccountMenu(
  { arrowPosition, closeHandler }: MenuProps,
  ref
) {
  const logout = useLogout(REASON_ENUM.EXPLICIT_LOGOUT)
  const [changeLogModal, setChangeLogModal] = useState(false)
  const { data, isError, isFetching, isLoading } = useChangelogQuery()

  return (
    <>
      <PopupMenu
        closeHandler={closeHandler}
        backgroundColor='#3f4c6b'
        arrowPosition={arrowPosition}
        ref={ref}
      >
        <MenuItem
          data-testid='changelog'
          onClick={() => setChangeLogModal(true)}
        >
          <ListItemIcon sx={commonStyle}>
            <UpdateIcon className='account-icon' fontSize='small' />
            <div className='account-menu-text'>{enUS.CHANGELOG}</div>
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          data-testid='logout'
          onClick={async () => {
            logout()
            // notify all open tabs and logout
          }}
        >
          <ListItemIcon sx={commonStyle}>
            <LogoutIcon fontSize='small' className='account-icon' />
            <div className='account-menu-text'>{enUS.LOGOUT}</div>
          </ListItemIcon>
        </MenuItem>
        <Divider sx={{ backgroundColor: menuItemStyle.dividerColor }} />

        <div style={{ padding: '8px 16px 0' }}>
          <About />
        </div>
      </PopupMenu>
      <ModalDialog
        title='Change log'
        open={changeLogModal}
        onClose={() => {
          setChangeLogModal(false)
          localStorage.setItem('isChangeLogClosed', 'true')
        }}
      >
        <ChangeLog
          changelogs={data?.changelog}
          isError={isError}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </ModalDialog>
    </>
  )
})
