import { ChangeEvent } from 'react'
import PercentIcon from '@mui/icons-material/Percent'
import { Input, InputAdornment } from '@mui/material'

interface IProps {
  mask: RegExp
  onChange: (value: string, name: string) => void
  value: string | number | undefined
  name: string
  dataTestId: string
}

export const MaskedInput = ({
  mask,
  value,
  name,
  onChange,
  dataTestId,
}: IProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || mask.test(e.target.value)) {
      onChange(e.target.value, e.target.name)
    }
  }

  return (
    <Input
      autoComplete='off'
      onChange={handleChange}
      value={value}
      name={name}
      id={`standard-adornment-${name}`}
      endAdornment={
        <InputAdornment position='end'>
          <PercentIcon sx={{ color: '#d1d1d1' }} fontSize='small' />
        </InputAdornment>
      }
      aria-describedby={`standard-${name}-helper-text`}
      inputProps={{
        'aria-label': name,
        'data-testid': dataTestId,
      }}
      sx={{ color: 'whitesmoke' }}
    />
  )
}
