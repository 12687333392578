import { Button, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useGoBackToDelegates } from './useGoBackToDelegates'
import { ViewType } from '../types/ViewType'
import { useSprGalleryQuery } from '@api/sprGallery/spr-gallery'

export const useBreadcrumbs = () => {
  const { frameId, dtid } = useSprGalleryQuery()
  const goBackToDelegates = useGoBackToDelegates()
  const isDelegateView = !frameId || !dtid

  return useMemo(() => {
    const items = [
      <Button
        sx={{
          textTransform: 'none',
          cursor: isDelegateView ? 'initial' : 'pointer',
        }}
        onClick={goBackToDelegates}
        variant='text'
        key={ViewType.DELEGATES}
      >
        <Typography color={'white'}>{ViewType.DELEGATES}</Typography>
      </Button>,
    ]

    if (!isDelegateView) {
      items.push(
        <Button
          sx={{ textTransform: 'none', cursor: 'initial' }}
          variant='text'
          key={ViewType.CONSTITUENTS}
        >
          <Typography color={'white'}>{ViewType.CONSTITUENTS}</Typography>
        </Button>
      )
    }

    return items
  }, [goBackToDelegates, isDelegateView])
}
