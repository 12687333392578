import { TopDownData } from '@api/objects'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnabledLayers } from '@pages/Details/types/layersPanel'
import { ViewportContent } from '@pages/Details/types/viewportContent'
import { match, P } from 'ts-pattern'
import {
  getViewportLocalStorageState,
  setNewState,
  setSynced,
} from '../viewportStateUtils'

export interface SliceState {
  [viewportKey: number]: TopDownData | EnabledLayers | undefined
  synced: boolean
}

interface ViewportContentChangeAction {
  id: number
  content: ViewportContent
}

interface TopDownChangeAction {
  id: number
  data: TopDownData
}

interface LayerChangeAction {
  id: number
  data: EnabledLayers
}

const defaultLayerState: EnabledLayers = {
  me: [],
  otto: [],
}

const defaultTopViewState: TopDownData = {
  lane: 'both',
  source: 'both',
}

interface SyncLayersAction {
  id: number
  areSynced: boolean
}

const syncAllLayers = (state: SliceState, syncState: EnabledLayers) => {
  const localStorageViewportState = getViewportLocalStorageState()

  if (!localStorageViewportState) {
    return
  }

  Object.keys(state).forEach((k: string) => {
    const key = parseInt(k)

    if (Number.isNaN(key)) {
      return
    }

    const content = localStorageViewportState[key].content
    if (
      ![
        ViewportContent.MAP,
        ViewportContent['3D_VIEW'],
        ViewportContent.TIMELINE,
      ].includes(content)
    ) {
      state[key] = syncState
      setNewState(key, undefined, syncState)
    }
  })
}

const localStorageSavedState = getViewportLocalStorageState()

const getViewportSavedState = (viewportKey: number) => {
  if (!localStorageSavedState) {
    return undefined
  }

  return localStorageSavedState[viewportKey].content ===
    ViewportContent['3D_VIEW']
    ? localStorageSavedState[viewportKey].topDown
    : localStorageSavedState[viewportKey].layerState
}

const initialState: SliceState = !localStorageSavedState
  ? {
      1: defaultLayerState,
      2: undefined,
      3: undefined,
      4: defaultTopViewState,
      5: defaultLayerState,
      6: defaultLayerState,
      7: defaultLayerState,
      synced: false,
    }
  : {
      1: getViewportSavedState(1),
      2: getViewportSavedState(2),
      3: getViewportSavedState(3),
      4: getViewportSavedState(4),
      5: getViewportSavedState(5),
      6: getViewportSavedState(6),
      7: getViewportSavedState(7),
      synced: false,
    }

const viewportDataSlice = createSlice({
  name: 'viewportDataSlice',
  initialState,
  reducers: {
    viewportChange: (
      state: SliceState,
      action: PayloadAction<ViewportContentChangeAction>
    ) => {
      const content = match(action.payload.content)
        .with(
          P.union(
            ViewportContent.FRONT_CAMERA,
            ViewportContent.LEFT_CAMERA,
            ViewportContent.RIGHT_CAMERA,
            ViewportContent.REAR_CAMERA
          ),
          () => {
            if (!state.synced) {
              return defaultLayerState
            }

            const key = Object.keys(state).find((x) => {
              const k = parseInt(x)
              const stateData = state[k]

              if (stateData && 'otto' in stateData) {
                return k
              }

              return undefined
            })

            if (key) {
              return state[parseInt(key)]
            }

            return undefined
          }
        )
        .with(ViewportContent['3D_VIEW'], () => defaultTopViewState)
        .otherwise(() => undefined)

      state[action.payload.id] = content
    },
    layerDataChange: (
      state: SliceState,
      action: PayloadAction<LayerChangeAction>
    ) => {
      state[action.payload.id] = action.payload.data
      if (state.synced) {
        syncAllLayers(state, action.payload.data)
      } else {
        setNewState(action.payload.id, undefined, action.payload.data)
      }
    },
    topDownDataChange: (
      state: SliceState,
      action: PayloadAction<TopDownChangeAction>
    ) => {
      state[action.payload.id] = action.payload.data
      setNewState(action.payload.id, undefined, action.payload.data)
    },
    syncLayers: (
      state: SliceState,
      action: PayloadAction<SyncLayersAction>
    ) => {
      const syncState = state[action.payload.id]
      const { areSynced } = action.payload

      if (!syncState || !('me' in syncState)) {
        return
      }

      if (areSynced) {
        syncAllLayers(state, syncState)
      }

      state.synced = action.payload.areSynced
      setSynced(action.payload.areSynced)
    },
  },
})

export const {
  viewportChange,
  layerDataChange,
  topDownDataChange,
  syncLayers,
} = viewportDataSlice.actions
export default viewportDataSlice.reducer
