import { Fragment } from 'react'
import { IconButton, Stack } from '@mui/material'
import { IconDefinition } from '@modules/sidebarToolbox/types/sidebar'
import './style.scss'

interface ToolbarProps {
  side: string
  content: IconDefinition
}

export const Toolbar = ({ side, content }: ToolbarProps) => (
  <div className='toolbar' style={side === 'left' ? { left: 0 } : { right: 0 }}>
    <Stack direction='column' alignItems='center' gap={1.5} pt={8}>
      {content.map((item) => {
        return (
          <Fragment key={item.title}>
            <IconButton
              disabled={item.disabled}
              onClick={() => item.callback()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                backgroundColor: (theme) =>
                  item.opened ? theme.palette.secondary.main : 'transparent',
                borderRadius: 0,
                width: '100%',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.secondary.main,
                },
              }}
            >
              {item.icon}
              <div
                style={{
                  color: item.disabled ? 'grey' : 'whitesmoke',
                  fontSize: '0.625rem',
                }}
              >
                {item.title}
              </div>
            </IconButton>
          </Fragment>
        )
      })}
    </Stack>
  </div>
)
