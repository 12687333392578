import { PROJECT_IDS } from '@common/constants/projects'
import { getProjectID } from '@common/utils/storage'
import { RuleGroupType } from 'react-querybuilder'
import {
  INIT_DATA_TABLE_COLUMN_VISIBILITY,
  INIT_KPI_LANES_COLUMN_VISIBILITY,
  INIT_KPI_LIGHTS_COLUMN_VISIBILITY,
  INIT_KPI_SIGNS_COLUMN_VISIBILITY,
} from './components/initColumnVisibility'
import {
  BreadcrumbItem,
  Environment,
  FetchByOrderState,
  General,
  PerserveState,
  SearchType,
} from './types'

export const INIT_BREADCRUMBS: BreadcrumbItem[] = [{ name: 'All' }]

const INIT_PINNING_STATE = {
  left: ['select', '100000', '100001', '100002', '100003'],
  right: [],
}

const INIT_SORTING_STATE = [{ id: '100000', desc: true }]
const INIT_SORTING_SECTION_DETAIL_STATE = [{ id: '100000', desc: false }]

const INIT_FETCH_BY_ORDER_STATE = {
  sortBy: 'DTID',
  sortDirection: 'desc',
} satisfies FetchByOrderState

const INIT_FETCH_BY_ORDER_STATE_SECTION = {
  sortBy: 'SectionID',
  sortDirection: 'asc',
} satisfies FetchByOrderState

const INIT_FETCH_BY_ORDER_STATE_DETAIL = {
  sortBy: 'Sequence',
  sortDirection: 'asc',
} satisfies FetchByOrderState

const INIT_SECTION_STATE = {
  sections: {
    sorting: INIT_SORTING_SECTION_DETAIL_STATE,
    fetchByOrder: INIT_FETCH_BY_ORDER_STATE_SECTION,
  },
}

const INIT_DETAIL_STATE = {
  details: {
    sorting: INIT_SORTING_SECTION_DETAIL_STATE,
    fetchByOrder: INIT_FETCH_BY_ORDER_STATE_DETAIL,
  },
}

export const INIT_KPI_SUMMARY = {
  filters: {},
  dateRange: [null, null],
}

export const INIT_ENVIRONMENT_STATE = {
  weather: '',
  roadType: '',
  timeOfDay: '',
  country: '',
} satisfies Environment

export const INIT_OBJECTS_STATE = {
  car: undefined,
  truck: undefined,
  bus: undefined,
  motorcycle: undefined,
  pedestrian: undefined,
  cycle: undefined,
  camera: undefined,
  movingObjects: undefined,
  staticObjects: undefined,
}

export const INIT_GENERAL_STATE = {
  dateRange: [null, null],
  processedDateRange: [null, null],
} satisfies General

export const INIT_FILTER_STATE = {
  environmentFilters: INIT_ENVIRONMENT_STATE,
  generalFilters: INIT_GENERAL_STATE,
  objectFilters: INIT_OBJECTS_STATE,
}

export const INIT_QUERY_BUILDER_STATE = {
  combinator: 'and',
  rules: [],
} satisfies RuleGroupType

export const INIT_FILTER_KPI_TO_SQL = {
  startDate: '',
  endDate: '',
}

const INIT_TABLE_STATE = {
  columnVisibility: {
    100001: getProjectID() !== PROJECT_IDS.VOLVO.toString(),
  },
  clause: '',
  searchType: 'filter' as SearchType,
  columnPinning: INIT_PINNING_STATE,
  sorting: INIT_SORTING_STATE,
  fetchByOrder: INIT_FETCH_BY_ORDER_STATE,
  filterState: INIT_FILTER_STATE,
  filterToSql: {},
  queryBuilderState: INIT_QUERY_BUILDER_STATE,
}

const INIT_KPI_TABLE_STATE = {
  clause: '',
  searchType: 'filter' as SearchType,
  columnPinning: INIT_PINNING_STATE,
  sorting: INIT_SORTING_STATE,
  fetchByOrder: INIT_FETCH_BY_ORDER_STATE,
  filterKpiState: INIT_KPI_SUMMARY,
  filterKpiToSql: INIT_FILTER_KPI_TO_SQL,
  queryBuilderState: INIT_QUERY_BUILDER_STATE,
}

export const INIT_PERSERVE_STATE = {
  completeness: {
    ...INIT_TABLE_STATE,
    columnVisibility: INIT_DATA_TABLE_COLUMN_VISIBILITY,
  },
  quality: {
    ...INIT_TABLE_STATE,
    ...INIT_SECTION_STATE,
    ...INIT_DETAIL_STATE,
    columnVisibility: INIT_DATA_TABLE_COLUMN_VISIBILITY,
  },
  characterization: {
    ...INIT_TABLE_STATE,
    ...INIT_SECTION_STATE,
    ...INIT_DETAIL_STATE,
    columnVisibility: INIT_DATA_TABLE_COLUMN_VISIBILITY,
  },
  lanes: {
    ...INIT_KPI_TABLE_STATE,
    columnVisibility: INIT_KPI_LANES_COLUMN_VISIBILITY,
  },
  signs: {
    ...INIT_KPI_TABLE_STATE,
    columnVisibility: INIT_KPI_SIGNS_COLUMN_VISIBILITY,
  },
  lights: {
    ...INIT_KPI_TABLE_STATE,
    columnVisibility: INIT_KPI_LIGHTS_COLUMN_VISIBILITY,
  },
} satisfies PerserveState
