import { FilterObject } from 'models/table'

export const transformFilter = (filter: FilterObject) => {
  const newObject: FilterObject = {}

  const toCamelCase = (str: string) =>
    str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase())

  Object.keys(filter).forEach((key) => {
    if (['KPI', 'DTID'].includes(key.toLocaleUpperCase())) {
      newObject[key.toLowerCase()] = filter[key]
    } else if (key.split(' ').length === 1) {
      newObject[key.charAt(0).toLowerCase() + key.slice(1)] = filter[key]
    } else {
      newObject[toCamelCase(key)] = filter[key]
    }
  })

  return newObject
}
