import React from 'react'
import { useNavigate } from 'react-router-dom'
import './style.scss'

export interface Props {
  errorType?: string
}

export const ErrorComponent: React.FC<Props> = ({ errorType }: Props) => {
  const navigate = useNavigate()

  return (
    <div onClick={() => navigate('/')} className='error-background'>
      <h1 className='error-title'>{errorType}</h1>
    </div>
  )
}
