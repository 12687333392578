export const enUS = {
  ACCOUNT_SETTINGS: 'Account Settings',
  CHANGELOG: 'Change log',
  USER_SETTINGS: 'User Settings',
  ADMIN_SETTINGS: 'Admin Settings',
  HELP: 'Help',
  SAVE: 'Save',
  LICENSES: 'Licenses',
  LOGOUT: 'Logout',
  VERSION: 'Version',
  SUPPORT_TOOLTIP: 'Send e-mail to support',
  DESCRIPTION: 'Description: ',
  VIEWPORT_MENU: 'Viewport Menu',
  HIGHLIGHT_MODE: 'Highlights',
  HIGHLIGHT_MODE_EXIT: 'Exit highlights',
  HIDE_GROUP: 'Hide track',
  UPLOAD_SUCCESSFULL: 'Screenshot updated successfully!',
  CHOOSE_WINDOW:
    'Please switch to the window you want to capture. You have 5 seconds.',
  NOTATION_END_TIME_CONSTRAINT:
    'Notation can not be started in one drive trial and finished in another.',
  NOTATION_END_TIME_CONSTRAINT_HIGHLIGHT_MODE:
    'Notations can not span over multiple highlight items.',

  ALL_DATA: 'All Data',
  MISSING_DATA: 'Missing Data',
  NO_DATA: 'No Data',
  NO_START_TIME: 'Please select a start time first!',
  NO_DATA_TO_DISPLAY: 'No data available to display.',

  CANCEL: 'Cancel',
  OK: 'OK',

  SESSION: 'Session',
  LOAD_SESSION: 'Load a session',

  SAVE_SESSION: 'Save current session',
  SESSION_PLACEHOLDER: 'Give this session a name',
  SESSION_LABEL: 'Session Name',
  DEFAULT_SESSION: 'Default Session',
  LAST_SAVED_SESSION: 'Last saved session',

  LANES: 'Lanes',
  SIGNS: 'Signs',
  LIGHTS: 'Lights',
  OBJECTS: 'Objects',

  KPI_SUMMARY: 'KPI Summary',
  DRIVE_TRIAL: 'Data/Drive Trials',
  JIRA_NOT_FOUND: 'Jira issue not found, it might have been already deleted.',

  VALUE: 'Value',
  SPARKLINE: 'Sparkline',
  VALUE_AND_SPARKLINE: 'Value | Sparkline',
  BARS: 'Bars',
  LINES: 'Lines',

  LOCK: 'Lock',
  UNLOCK: 'Unlock',
  COL: 'columns',
  HIDE: 'Hide column',

  DATE_START: 'Date Start',
  DATE_END: 'Date End',
  TO: 'To',

  ERR_LOGIN: 'Please contact your system administrator.',
  ERR_REPORT_DATA:
    'Error fetching report data. Please reload the page or contact your administrator for assistance.',
  ERR_REPORT_TYPE:
    'Unsupported report type selected. Please choose a different option.',
  ERR_MAP: 'An unexpected error occurred while loading GPS data.',
  ERR_API: 'API has returned status code: ',
  ERR_SERVER: 'Server Error',
  ERR_EXTRACTING_DATA: 'Extracting video data failed.',
  DATA_NOT_AVAILABLE: 'Data not available',

  INFO_NO_DATA: 'The query returned no data.',
  INFO_QUERY_EXECUTED: 'Query executed successfully!',
  INFO_NO_SORT_VALUES:
    'The selected column cannot be sorted as it contains no numeric or alphanumeric data.',

  DOWNLOAD_PDF: 'Download PDF file',
  DOWNLOAD_CSV: 'Download CSV file',

  TIMELINE: 'Timeline',
  CREATE_TIMELINE_GROUP: 'Create new timeline group',
  GROUP_DATA: 'Group data',
  GROUP_NAME: 'Group name',
  GROUP_TYPE: 'Group type',
  GROUP_SELECT: 'Select',
  GROUP_UNSELECT: 'Deselect',
  GROUP_ADD: 'Add group',
  GROUP_SHOW_HIDE: 'Show/Hide tags',
  SWITCH_CHART_TYPE: 'Switch chart type',
  TRACK_HEIGHT: 'Track height',
  GROUP_REMOVE: 'Remove group',
  GROUP_ABOVE: 'Add group above',
  GROUP_UNDER: 'Add group under',
  GROUP_POSITION: 'Choose where to add new group',
  EXPORT: 'Export',
  ERR_ITEM_ON_KEY:
    'Tag already present on the timeline at the current timestamp.',
  ERR_NO_ROW_SELECTED: 'Please select a track to add your tag.',
  TAGS_HIDDEN: 'Tags cannot be created in hidden mode!',
  NOT_FOUND: 'Page not found',
  NO_VIDEO: 'Video data not found. It is safe to close this tab.',

  NO_COLUMNS_SHOWN: 'KPI columns will be displayed after selecting filters.',
  NO_FILTERS_SELECTED: 'KPI summary will appear once filters are selected.',
  NO_TABLE_DATA: 'No table data found. Please try selecting different filters.',
  REDIRECTION_ERROR: 'Redirection to the details page has failed',
  USER_ON_IDLE: 'Your session has expired. Please log in again.',
  SELECT_FORMAT: 'Select print format',
  SELECT_LAYOUT: 'Select print layout',
  SAVE_PDF: 'Save PDF',
  SEND_TO_DETAILS: 'Send to details',
  LOADING_TIMELINE: 'Loading timeline',
  NO_DASHBOARD_FOUND: 'Dashboard data not found',

  START_DRIVE_TRAIL_ID: 'Start',
  END_DRIVE_TRAIL_ID: 'End',

  FOCUS_MARKER_TITLE: 'Focus marker',
  COORDINATES_COPIED_TO_CLIPBOARD: 'Coordinates are copied to clipboard!',

  LOGIN_HEADLINE: 'Accelerating ADAS Validation and AV Training',
  LOGIN_SUBTITLE: 'Welcome back! Please login to your account.',
  LOGIN_INVALID_EMAIL_MESSAGE:
    'Email address is not in proper format [email.address@example.com].',
  LOGIN_REQUIRED_EMAIL: 'Email is required, please try again.',
  LOGIN_WRONG_EMAIL: 'Wrong email, please try again.',
  LOGIN_WRONG_PASSWORD: 'Wrong password, please try again.',
  PASSWORD_REQUIRED_MESSAGE: 'Password is required, please try again.',
  USER_PROJECT_NOT_AVAILABLE: 'User is not authorized to use this project.',
}

export const enUS_COLORS = {
  lane: 'Lane',
  vehicle: 'Vehicle',
  trafficSigns: 'Traffic Signs',
  pedestrians: 'Pedestrians',
  egoLane: 'Ego Lane',
  leftLane: 'Left Lane',
  rightLane: 'Right Lane',
}

export const enUS_TOOLTIPS = {
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
}

export const enUS_SIDEBAR_TOOLBOX = {
  reports: 'Reports',
  columns: 'Columns',
  search: 'Search',
  advancedSearch: 'Advanced Search',
}
