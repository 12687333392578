import { getProjectID } from '@common/utils/storage'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'

export type ViewportType =
  | 'FRONT_CAMERA'
  | 'LEFT_CAMERA'
  | 'RIGHT_CAMERA'
  | 'REAR_CAMERA'
  | 'MAP'
  | 'TIMELINE'
  | '3D_VIEW'

export interface ViewportMenuObject {
  id: number
  name: ViewportType
}

export const useClientViewportMenuQuery = () => {
  const projectID = getProjectID()
  return useQuery({
    queryKey: ['clientViewportMenu', projectID],
    staleTime: Infinity,
    enabled: !!projectID,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<ViewportMenuObject[]> =>
      axios
        .get<ViewportMenuObject[]>(urls.clientViewportMenuOptions, {
          params: { projectID },
        })
        .then(({ data }) => data),
  })
}
