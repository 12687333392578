export enum ViewportContent {
  FRONT_CAMERA = 1,
  LEFT_CAMERA,
  RIGHT_CAMERA,
  REAR_CAMERA,
  MAP,
  TIMELINE,
  '3D_VIEW',
  TOP_VIEW_MAP,
}
