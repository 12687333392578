import { useLoginMutation } from '@api/index'
import { enUS } from '@common/constants/messages'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import { closeSnackbar, useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useLoginContext } from './useLoginContext'
import { useAuthContext } from '../context/AuthContext'
import { setAuthStorage } from '../utils/storage'

export interface IDTokenResponse extends GoogleApiOAuth2TokenObject {
  id_token: string
  expires_in: string
  expires_at: string
}

export interface DecodedToken extends JwtPayload {
  email: string
  email_verified: boolean
}

export const gapiConfig = (immediate: boolean) => ({
  client_id: process.env.REACT_APP_GOOGLE_APP_ID || '',
  scope: 'email',
  response_type: 'id_token permission email',
  immediate,
})

export const useInitGoogleApi = () => {
  const onGoogleLoginSuccess = useOnGoogleLoginSuccess()

  return useCallback(() => {
    const gapiLoad = () => {
      gapi.auth.authorize(gapiConfig(false), async (t) => {
        if (t && t.access_token) {
          await onGoogleLoginSuccess(t as IDTokenResponse)
        }
      })
    }

    return gapi.load('client:auth2', gapiLoad)
  }, [onGoogleLoginSuccess])
}

export const useOnGoogleLoginSuccess = () => {
  const { setAuthContext } = useAuthContext()
  const { setIsAuthenticating } = useLoginContext()
  const { enqueueSnackbar } = useSnackbar()
  const { login } = useLoginMutation()

  return useCallback(
    async (t: IDTokenResponse) => {
      try {
        setIsAuthenticating(true)
        closeSnackbar()
        const { data } = await login({ googleToken: t.id_token })

        const { email }: DecodedToken = jwtDecode(t.id_token)
        const token = data.tokens.accessToken
        const source = 'google'

        setAuthStorage(data, email, source)
        setAuthContext({ token, source, email })
      } catch (e) {
        enqueueSnackbar(enUS.ERR_LOGIN, {
          variant: 'info',
        })
      } finally {
        setIsAuthenticating(false)
      }
    },
    [enqueueSnackbar, login, setAuthContext, setIsAuthenticating]
  )
}
