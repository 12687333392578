import { useState } from 'react'

export function ImageWithPlaceholder({
  src,
  alt,
  placeholder = '',
  className,
}: {
  src?: string
  alt?: string
  placeholder?: string
  className?: string
}) {
  const [imgSrc, setImgSrc] = useState(src)

  const handleError = () => {
    setImgSrc(placeholder)
  }

  return (
    <img className={className} src={imgSrc} alt={alt} onError={handleError} />
  )
}
