import { useNLGalleryQuery } from '@api/nlGallery/nl-gallery'
import { useProjectData } from '@api/table/projects'
import { Routes } from '@common/constants/paths'
import { PROJECT_IDS } from '@common/constants/projects'
import { Loader } from '@common/components/Loader/Loader'
import {
  ImageBox,
  useHandleCloseImageModal,
  useHandlePagination,
} from '@modules/gallery'
import { Box, Fade, Modal, Typography } from '@mui/material'
import { Navigate, useSearchParams } from 'react-router-dom'
import { StyledPagination } from '../Report/components/StyledComponentsTable'
import { ImageItem } from './components/ImageItem'
import { InitialSearchView } from './containers/InitialSearchView'
import { SearchField } from './containers/SearchField'
import { useHandleImageClick } from './hooks/useHandleImageClick'
import './styles.scss'

export function NlGallery() {
  const [searchParams] = useSearchParams()
  const { data, isLoading, page, count } = useNLGalleryQuery()
  const { projectID } = useProjectData()

  const nlQuery = searchParams.get('nlQuery') || ''
  const isImageBoxOpen = searchParams.get('selectedIndex') !== null

  const handleClose = useHandleCloseImageModal()
  const handleImageClick = useHandleImageClick()
  const handlePagination = useHandlePagination()

  if (projectID && projectID !== PROJECT_IDS.VOLVO) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }

  if (isLoading) {
    return <Loader text='Natural Language Gallery' center />
  }

  return (
    <div className='gallery-container'>
      {!nlQuery ? (
        <InitialSearchView />
      ) : data?.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              p: 1,
              pb: 0,
              minHeight: '44px',
            }}
          >
            <Box textAlign={'center'}>
              <SearchField isSmall />
            </Box>

            <StyledPagination
              sx={{ ml: 'auto', alignSelf: 'auto' }}
              onChange={handlePagination}
              count={count}
              page={page}
              size='small'
              shape='rounded'
            />
          </Box>
          <div className='gallery-container--right'>
            {data?.map((item) => (
              <ImageItem
                item={item}
                handleClick={handleImageClick}
                key={item.dtid + item.frameId}
              />
            ))}
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box textAlign={'center'}>
            <Typography sx={{ color: 'white', mb: 3 }} variant='h4'>
              No results found
            </Typography>

            <SearchField placeholder='Search for something else...' />
          </Box>
        </Box>
      )}
      <Modal
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        open={isImageBoxOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade style={{ outline: 'none' }} in={isImageBoxOpen}>
          <Box>
            <ImageBox data={data} onClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
