import { ChangeEvent, MouseEvent, useState } from 'react'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'

interface IProps {
  isAuthenticating: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
  showHint: boolean
  passwordValidationError: string
}

const Password = ({
  isAuthenticating,
  onChange,
  passwordValidationError,
}: IProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <FormControl
      fullWidth
      variant='outlined'
      error={passwordValidationError !== ''}
      sx={{ marginBottom: '27px !important' }}
    >
      <InputLabel htmlFor='outlined-adornment-password' required>
        Password
      </InputLabel>
      <OutlinedInput
        id='outlined-adornment-password'
        name='password'
        label='Password'
        required
        type={showPassword ? 'text' : 'password'}
        disabled={isAuthenticating}
        onChange={onChange}
        inputProps={{ 'data-testid': 'password-input-field' }}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              data-testid='show-hide-password'
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              <RemoveRedEyeOutlinedIcon
                sx={{
                  color: showPassword ? '#3C90FF' : '#fff',
                }}
              />
            </IconButton>
          </InputAdornment>
        }
      />
      {passwordValidationError !== '' && (
        <FormHelperText sx={{ color: '#d32f2f' }}>
          {passwordValidationError}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Password
