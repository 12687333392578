import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { setDashboards } from '@common/utils/storage'
import { NestedMenuItem } from 'mui-nested-menu'
import { Dashboard } from '@api/dashboards/dashboards'
import { useProjectData } from '@api/table/projects'
import { MenuItemType } from './ReportNavigation'
import { shouldSkipElement } from 'pages/Report/utils'

interface NestedReportNavigationMenuProps {
  menus: ReadonlyArray<MenuItemType>
  setPage: (page: number) => void
  data: Dashboard[] | undefined
}

const NestedReportNavigationMenu: React.FC<NestedReportNavigationMenuProps> = ({
  menus,
  setPage,
  data,
}) => {
  const [reportNavigationAnchorEl, setReportNavigationAnchorEl] =
    useState<HTMLElement>()

  const navigate = useNavigate()
  const { projectID } = useProjectData()

  const handleNavigationClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setReportNavigationAnchorEl(event.currentTarget)

  const handleCloseNavigation = () => {
    setReportNavigationAnchorEl(undefined)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        data-testid={'report-navigation'}
        sx={{
          minWidth: 0,
          textTransform: 'capitalize',
          p: 0,
          color: '#e5e5e5',
          '&:hover': {
            color: '#fff',
          },
        }}
        onClick={handleNavigationClick}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        {'Report Navigation'}
        <ExpandMoreIcon fontSize='small' />
      </Button>

      <Menu
        anchorEl={reportNavigationAnchorEl}
        open={Boolean(reportNavigationAnchorEl)}
        onClose={handleCloseNavigation}
      >
        {menus?.map((menu) => {
          return menu.projects && !menu.projects.includes(projectID) ? null : (
            <NestedMenuItem
              key={menu.name}
              label={menu.name}
              parentMenuOpen={true}
            >
              {menu.menuItems?.map((item) => {
                if (shouldSkipElement(projectID, item)) {
                  return null
                }

                return (
                  <MenuItem
                    data-testid={`${menu.name}-${item}`}
                    sx={{ textTransform: 'capitalize' }}
                    key={item}
                    onClick={() => {
                      menu.close()
                      setPage(1)

                      if (menu.name === 'SPR Gallery') {
                        navigate(`${menu.routeBase}`)
                      } else if (menu.name === 'NL Query') {
                        navigate(`${menu.routeBase}`)
                      } else {
                        navigate(`${menu.routeBase}${item}`)
                      }

                      if (menu.name === 'Dashboards') {
                        const chosenDashboard = data?.find(
                          (x) => x.name === item
                        )
                        setDashboards(chosenDashboard!)
                      }
                    }}
                  >
                    {item}
                  </MenuItem>
                )
              })}
            </NestedMenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default NestedReportNavigationMenu
