import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { useAuthContext } from '@modules/auth'

export interface HeaderTooltips {
  [key: string]: string
}

export const useHeaderTooltips = () => {
  const { isAuthenticated } = useAuthContext()

  return useQuery({
    queryKey: ['headerTooltips'],
    staleTime: Infinity,
    enabled: isAuthenticated,
    queryFn: (): Promise<HeaderTooltips> =>
      axios
        .get<HeaderTooltips>(urls.reportHeaderTooltips)
        .then(({ data }) => data),
  })
}
