import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import { TableRouteParams } from '@common/constants/paths'
import { TableResponse } from '../../models/table'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { getClientID } from '@common/utils/storage'
import { urls } from '../urls'
import { useProjectData } from './projects'
import { useAuthContext } from '@modules/auth'

export function useTableQuery() {
  const { reportType, project } = useParams<TableRouteParams>()
  const { pathname } = useLocation()
  const {
    page,
    limit,
    preserveState,
    breadcrumbs,
    hilVersion,
    projectID,
    publishCounter,
  } = useReportContext()
  const { adminLevelID } = useAuthContext()
  const offset = (page - 1) * limit
  const currentReportData = preserveState[reportType!]
  const { sectionLength } = useProjectData()

  const filteredPayload = {
    headers: {
      adminLevelID: adminLevelID || 0,
      offset,
      limit,
      clientId: getClientID(),
      projectId: projectID,
      tableType: reportType,
      sortColumn: currentReportData.fetchByOrder.sortBy,
      sortOrder: currentReportData.fetchByOrder.sortDirection,
      ...(hilVersion !== 'all' ? { version: hilVersion } : {}),
    },
    data: currentReportData.filterToSql,
  }

  const clausePayload = {
    offset,
    limit,
    clientId: getClientID(),
    projectId: projectID,
    tableType: reportType,
    clause: currentReportData.clause,
    sortColumn: currentReportData.fetchByOrder.sortBy,
    sortOrder: currentReportData.fetchByOrder.sortDirection,
    ...(hilVersion !== 'all' ? { version: hilVersion } : {}),
    adminLevelID: adminLevelID || 0,
  }

  return useQuery({
    enabled: pathname.includes('drive-trials'),
    queryKey: [
      'dataTable',
      filteredPayload,
      clausePayload,
      reportType,
      project,
      page,
      offset,
      limit,
      breadcrumbs,
      currentReportData.searchType,
      currentReportData.sections?.fetchByOrder.sortBy,
      currentReportData.sections?.fetchByOrder.sortDirection,
      currentReportData.details?.fetchByOrder.sortBy,
      currentReportData.details?.fetchByOrder.sortDirection,
      publishCounter,
    ],
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<AxiosResponse<TableResponse>> => {
      const crumbsLength = breadcrumbs.length
      const isFilter = currentReportData.searchType === 'filter'
      const payload = isFilter ? filteredPayload : clausePayload

      switch (crumbsLength) {
        case 2:
        case 3: {
          const isSection = crumbsLength === 2
          const url = isFilter ? urls.reportsFiltered : urls.reportsClause

          const sectionData = {
            dtid: breadcrumbs[1]?.name,
            parentDTID: breadcrumbs[1].data && breadcrumbs[1].data[100001],
            version: breadcrumbs[1].data && breadcrumbs[1].data[100003],
            sectionId: !isSection ? breadcrumbs[2]?.name : undefined,
            sortColumn: isSection
              ? currentReportData.sections?.fetchByOrder.sortBy
              : currentReportData.details?.fetchByOrder.sortBy,
            sortOrder: isSection
              ? currentReportData.sections?.fetchByOrder.sortDirection
              : currentReportData.details?.fetchByOrder.sortDirection,
            sectionLength: sectionLength,
          }
          const sectionPayload = isFilter
            ? {
                ...payload,
                headers: {
                  ...filteredPayload.headers,
                  ...sectionData,
                },
              }
            : { ...clausePayload, ...sectionData }
          return axios.post(url, sectionPayload).then((resp) => resp)
        }
        case 1:
        default: {
          const reportsUrl = isFilter
            ? urls.reportsSummaryFiltered
            : urls.reportsSummaryClause
          return axios.post(reportsUrl, payload).then((resp) => resp)
        }
      }
    },
  })
}
