import { createToken } from '@pages/Dashboard/utils/tableau'

export function useGetTableauParams() {
  const userid = process.env.REACT_APP_TABLEAU_CREATOR || ''
  const iss = process.env.REACT_APP_TABLEAU_ID || ''
  const kid = process.env.REACT_APP_TABLEAU_SECRET_ID || ''
  const secret = process.env.REACT_APP_TABLEAU_CREATOR_SECRET_VALUE || ''
  const wurl = process.env.REACT_APP_TABLEAU_URL || ''

  if (!userid || !iss || !kid || !secret || !wurl) {
    return {
      wurl: '',
      token: '',
    }
  }

  const token = createToken(userid, kid, secret, iss)

  return {
    wurl,
    token,
  }
}
