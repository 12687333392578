import { GridOption } from '@common/components/GridSelectors/GridSelectors'
import { getClientID } from '@common/utils/storage'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  viewportContentChange,
  viewportNameChange,
} from '../../../store/details/viewport/viewportSlice'
import { viewportChange } from '../../../store/details/viewportData/viewportDataSlice'
import { ViewportContent } from '../types/viewportContent'
import { getDefaultGridOption } from '../utils/getDefaultGridOption'

export const useGrid = () => {
  const dispatch = useDispatch()
  const clientID = getClientID()

  const initialGrid: GridOption = getDefaultGridOption(clientID)
  const [grid, setGrid] = useState<GridOption>(initialGrid)

  const changeGrid = (option: GridOption) => {
    const viewportsLocalStorage = JSON.parse(
      localStorage.getItem('viewports') || '{}'
    )

    let foundSix = false

    const updatedData = { ...viewportsLocalStorage }

    for (const key in updatedData) {
      if (updatedData[key].content === ViewportContent.TIMELINE) {
        if (!foundSix) {
          foundSix = true
        } else {
          updatedData[key].content = ViewportContent.REAR_CAMERA
          dispatch(
            viewportContentChange({
              id: +key,
              content: updatedData[key].content,
            })
          )

          dispatch(
            viewportChange({
              id: +key,
              content: updatedData[key].content,
            })
          )
        }
      }
    }

    localStorage.setItem('viewports', JSON.stringify(updatedData))

    setGrid(option)
    dispatch(viewportNameChange(option.name))
  }

  return { grid, changeGrid }
}
